import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { UTMData } from "../../types/utmData";

const getGtmID = (): string => {
  return process.env.REACT_APP_GOOGLE_TAG_MANAGER ?? "";
};

export default function GTMProvider() {
  const isProduction = process.env.NODE_ENV === "production";
  const trackingID = getGtmID();

  // Store UTM data coming from referrer
  useEffect(() => {
    const utmData: UTMData = {};
    let hasUtmData = false;

    if (document.referrer && document.referrer.includes("?")) {
      const refString = document.referrer.split("?")[1];
      const params = new URLSearchParams(refString);
      const utmSource = params.get("utm_source");
      const utmMedium = params.get("utm_medium");
      const utmCampaignName = params.get("utm_campaign");
      const utmContent = params.get("utm_content");

      if (utmSource) {
        hasUtmData = true;
        utmData.source = utmSource;
      }

      if (utmMedium) {
        hasUtmData = true;
        utmData.medium = utmMedium;
      }

      if (utmCampaignName) {
        hasUtmData = true;
        utmData.campaignName = utmCampaignName;
      }

      if (utmContent) {
        hasUtmData = true;
        utmData.content = utmContent;
      }

      if (hasUtmData) {
        localStorage.setItem("utmData", JSON.stringify(utmData));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isProduction && trackingID) {
    TagManager.initialize({
      gtmId: trackingID,
    });
  }

  return null;
}
