import { useFieldState } from "informed";
import React from "react";
import { useHistory } from "react-router-dom";
import Button from "~/components/Button";
import TextInput from "~/components/TextInput";
import { H4, H5 } from "~/components/Typography";
import cleanStringInput from "~/helpers/formatters/cleanStringInput";
import {
  ADMIN_CUSTOMER_DETAILS_URL,
  ADMIN_DASHBOARD_URL,
  ADMIN_POLICY_DETAILS_URL,
} from "~/routes/index.constant";
import styles from "./index.module.scss";

enum Field {
  CustomerId = "AdminSearch-CustomerId",
  CustomerEmail = "AdminSearch-CustomerEmail",
  CustomerFirstName = "AdminSearch-CustomerFirstName",
  CustomerLastName = "AdminSearch-CustomerLastName",
  PolicyId = "AdminSearch-PolicyId",
}

const AdminSearch = () => {
  const customerIdState = useFieldState(Field.CustomerId);
  const customerEmailState = useFieldState(Field.CustomerEmail);
  const customerFirstNameState = useFieldState(Field.CustomerFirstName);
  const customerLastNameState = useFieldState(Field.CustomerLastName);
  const policyIdState = useFieldState(Field.PolicyId);
  const history = useHistory();

  const search = (searchType: Field) => {
    switch (searchType) {
      case Field.CustomerId:
        history.push(
          `${ADMIN_CUSTOMER_DETAILS_URL}/${cleanStringInput(customerIdState)}`
        );

        break;
      case Field.CustomerEmail:
        const params = {
          ...(cleanStringInput(customerEmailState) && {
            email: cleanStringInput(customerEmailState),
          }),
          ...(cleanStringInput(customerFirstNameState) && {
            firstName: cleanStringInput(customerFirstNameState),
          }),
          ...(cleanStringInput(customerLastNameState) && {
            lastName: cleanStringInput(customerLastNameState),
          }),
        };

        history.push(
          `${ADMIN_DASHBOARD_URL}/customers?${Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join("&")}`
        );
        break;
      case Field.PolicyId:
        history.push(
          `${ADMIN_POLICY_DETAILS_URL}/${cleanStringInput(policyIdState)}`
        );

        break;
    }
  };

  return (
    <div className={styles.Content}>
      <H4>Search for a customer or policy</H4>

      <div className={styles.SearchContainer}>
        <div className={styles.SearchField}>
          <H5>If you already know the customer&apos;s ID, enter it here</H5>

          <TextInput
            className={styles.SearchInput}
            type="text"
            label="Customer ID"
            placeholder="0000-0000"
            field={Field.CustomerId}
          />

          <Button
            className={styles.SearchButton}
            disabled={!cleanStringInput(customerIdState)}
            onClick={() => {
              search(Field.CustomerId);
            }}
          >
            Search
          </Button>

          <hr />

          <H5>Enter any of the below details to search for a customer</H5>

          <TextInput
            className={styles.SearchInput}
            type="text"
            label="Customer Email"
            placeholder="customer@email.com"
            field={Field.CustomerEmail}
          />

          <TextInput
            className={styles.SearchInput}
            type="text"
            label="Customer First Name"
            placeholder="John"
            field={Field.CustomerFirstName}
          />

          <TextInput
            className={styles.SearchInput}
            type="text"
            label="Customer Last Name"
            placeholder="Doe"
            field={Field.CustomerLastName}
          />

          <Button
            className={styles.SearchButton}
            disabled={
              !cleanStringInput(customerEmailState) &&
              !cleanStringInput(customerFirstNameState) &&
              !cleanStringInput(customerLastNameState)
            }
            onClick={() => {
              search(Field.CustomerEmail);
            }}
          >
            Search
          </Button>
        </div>

        <div className={styles.SearchField}>
          <H5>If you already know the policy&apos;s ID, enter it here</H5>

          <TextInput
            className={styles.SearchInput}
            type="text"
            label="Policy ID"
            placeholder="AL-00000-XXXXX"
            field={Field.PolicyId}
          />

          <Button
            className={styles.SearchButton}
            disabled={!cleanStringInput(policyIdState)}
            onClick={() => {
              search(Field.PolicyId);
            }}
          >
            Search
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdminSearch;
