import React, { ReactNode } from "react";
import classnames from "classnames";
import { FormValue, Radio } from "informed";

import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useCustomFieldApi } from "~/helpers/hooks/useCustomFieldApi";
import styles from "./index.module.scss";

type Props = {
  children: ReactNode;
  value: string;
  field: string;
  disabled?: boolean;
  checked?: boolean;
  small?: boolean;
  onChange?: (value: FormValue) => void;
  autoFocus?: boolean;
  labelId?: string;
};

export default function RadioSelector(props: Props) {
  const {
    children,
    disabled,
    field,
    value,
    onChange,
    checked,
    small,
    labelId,
  } = props;
  const fieldState = useCustomFieldState(field);
  const fieldApi = useCustomFieldApi(field);

  const handleOnChange = (formValue: Props["value"]) => {
    fieldApi.setValue(formValue);
    if (typeof onChange === "function") {
      onChange(formValue);
    }
  };

  return (
    <div className={styles.SelectorContainer}>
      <Radio
        value={value}
        name={field}
        field={field}
        disabled={disabled}
        className={styles.RadioInput}
        onClick={() => handleOnChange(value)}
        checked={checked}
        id={value}
        autoFocus={props.autoFocus}
      />

      <label
        className={classnames(styles.RadioSelectorWrapper, {
          [styles.Small]: small,
          [styles.Checked]: fieldState.value === value || checked,
        })}
        htmlFor={value}
        id={labelId}
      >
        <div className={styles.Children}>{children}</div>
      </label>
    </div>
  );
}
