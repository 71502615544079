import { gql } from "apollo-boost";
import React from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { useParams } from "react-router";
import LoadingWrapper from "~/components/LoadingWrapper";
import cx from "classnames";
import styles from "./index.module.scss";
import { ChecklistAdditionalDriverInput } from "~/screens/Portal/Checklist/Motor/AdditionalDriverDetails";

type Props = {
  children: React.ReactNode;
};

export type PortalChecklistMotorInput = {
  additionalDrivers?: ChecklistAdditionalDriverInput[];
  healthConditions?: string[];
  prosecutionsPastFiveYears?: boolean;
  refusedInsurance?: boolean;
  employerName?: string;
  employerAddress?: string;
  employerPhone?: string;
  employerCountryCode?: string;
  previousProvider?: string;
  isFinanced?: boolean;
};

export type PortalChecklistHomeInput = {
  storeys?: string;
  homeBuilt?: string;
  mortgageLender?: string;
  goodState?: boolean;
  surroundingCommercial?: boolean;
  leftWithNoInhabitant?: boolean;
  reclaimedLand?: boolean;
  levelledLand?: boolean;
  otherInsurance?: string;
  insuranceHistory?: string[];
  insuranceHistoryReason?: string;
};

export type PortalChecklistInput = {
  policyId: string;
  nextPath?: string;
  agreedToDeclaration?: boolean;
  motor?: PortalChecklistMotorInput;
  home?: PortalChecklistHomeInput;
};

type ChecklistDocument = {
  documentType: string;
  fileName?: string;
  context?: string;
  isOptional?: boolean;
};

type ChecklistRequiredDocumentPart = {
  contextType: string;
  contextTitle: string;
};

type ChecklistRequiredDocument = {
  documentType: string;
  documentTitle: string;
  documentParts: ChecklistRequiredDocumentPart[];
  isOptional?: boolean;
};

export type PortalChecklistType = PortalChecklistInput & {
  documents: ChecklistDocument[];
  requiredDocuments: ChecklistRequiredDocument[];
  dueDate: string;
  ignoreEmployerInfo: boolean;
  loaded: boolean;
  motor: PortalChecklistMotorInput;
  home: PortalChecklistHomeInput;
};

type ChecklistContextType = {
  saveChecklist: (checklistData: PortalChecklistInput) => void;
  submitChecklist: (policyId: string) => void;
  fetchChecklist: (policyId: string) => void;
} & PortalChecklistType;

const initialState: ChecklistContextType = {
  policyId: "",
  documents: [],
  requiredDocuments: [],
  ignoreEmployerInfo: false,
  dueDate: "",
  loaded: false,
  motor: {},
  home: {},
  saveChecklist: () => {
    throw new Error("Checklist context has not yet been initialized.");
  },
  submitChecklist: () => {
    throw new Error("Checklist context has not yet been initialized.");
  },
  fetchChecklist: () => {
    throw new Error("Checklist context has not yet been initialized.");
  },
};

const FETCH_PORTAL_CHECKLIST = gql`
  query getPortalChecklist($policyId: String!) {
    getPortalChecklist(policyId: $policyId) {
      policyId
      nextPath
      agreedToDeclaration
      dueDate
      ignoreEmployerInfo
      requiredDocuments {
        documentType
        isOptional
        documentParts {
          contextType
          contextTitle
        }
      }
      documents {
        documentType
        fileName
        context
      }

      motor {
        additionalDrivers {
          name
          age
          yearsDrivingExperience
          occupation
          licenseNumber
          licenseDateOfIssue
          licenseType
        }
        healthConditions
        prosecutionsPastFiveYears
        refusedInsurance
        employerName
        employerAddress
        employerCountryCode
        employerPhone
        previousProvider
        isFinanced
      }

      home {
        storeys
        homeBuilt
        mortgageLender
        goodState
        surroundingCommercial
        leftWithNoInhabitant
        reclaimedLand
        levelledLand
        otherInsurance
        insuranceHistory
        insuranceHistoryReason
      }
    }
  }
`;

export const SAVE_PORTAL_CHECKLIST = gql`
  mutation savePortalChecklist($portalChecklistInput: PortalChecklistInput!) {
    savePortalChecklist(input: $portalChecklistInput) {
      policyId
      nextPath
      agreedToDeclaration
      dueDate
      ignoreEmployerInfo
      requiredDocuments {
        documentType
        documentParts {
          contextType
          contextTitle
        }
        isOptional
      }
      documents {
        documentType
        fileName
        context
      }

      motor {
        additionalDrivers {
          name
          age
          yearsDrivingExperience
          occupation
          licenseNumber
          licenseDateOfIssue
          licenseType
        }
        healthConditions
        prosecutionsPastFiveYears
        refusedInsurance
        employerName
        employerAddress
        employerCountryCode
        employerPhone
        previousProvider
        isFinanced
      }

      home {
        storeys
        homeBuilt
        mortgageLender
        goodState
        surroundingCommercial
        leftWithNoInhabitant
        reclaimedLand
        levelledLand
        otherInsurance
        insuranceHistory
        insuranceHistoryReason
      }
    }
  }
`;

const SUBMIT_PORTAL_CHECKLIST = gql`
  mutation submitPortalChecklist($policyId: String!) {
    submitPortalChecklist(policyId: $policyId)
  }
`;

const CHECKLIST_HOME_PROPS = [
  "storeys",
  "homeBuilt",
  "mortgageLender",
  "goodState",
  "surroundingCommercial",
  "leftWithNoInhabitant",
  "reclaimedLand",
  "levelledLand",
  "otherInsurance",
  "insuranceHistory",
  "insuranceHistoryReason",
];

const CHECKLIST_MOTOR_PROPS = [
  "healthConditions",
  "prosecutionsPastFiveYears",
  "refusedInsurance",
  "employerName",
  "employerAddress",
  "previousProvider",
  "isFinanced",
  "additionalDrivers",
];

const cleanChecklistNullValues = (checklist: PortalChecklistType) => {
  if (checklist.home) {
    CHECKLIST_HOME_PROPS.forEach((homeProp) => {
      if (checklist.home?.[homeProp] === null) {
        delete checklist.home[homeProp];
      }
    });
  }

  if (checklist.motor) {
    CHECKLIST_MOTOR_PROPS.forEach((motorProp) => {
      if (checklist.motor?.[motorProp] === null) {
        delete checklist.motor[motorProp];
      }
    });
  }

  return checklist;
};

export const ChecklistContext = React.createContext(initialState);
export const useChecklistContext = () => React.useContext(ChecklistContext);

export default function ChecklistProvider({ children }: Props) {
  const apolloClient = useApolloClient();
  const [checklist, setChecklist] = React.useState<PortalChecklistType>(
    initialState
  );

  const params: {
    policyId: string | undefined;
  } = useParams();

  const saveChecklist = async (checklistInput: PortalChecklistInput) => {
    const response = await apolloClient.mutate({
      mutation: SAVE_PORTAL_CHECKLIST,
      variables: {
        portalChecklistInput: checklistInput,
      },
    });

    if (response.data.savePortalChecklist) {
      setChecklist({
        ...cleanChecklistNullValues(response.data.savePortalChecklist),
        loaded: true,
      });
    }
  };

  const submitChecklist = async (policyId: string) => {
    await apolloClient.mutate({
      mutation: SUBMIT_PORTAL_CHECKLIST,
      variables: {
        policyId,
      },
    });
  };

  const fetchChecklist = async (policyId: string) => {
    const { data } = await apolloClient.query({
      query: FETCH_PORTAL_CHECKLIST,
      variables: {
        policyId,
      },
      fetchPolicy: "no-cache",
    });

    setChecklist({
      ...cleanChecklistNullValues(data.getPortalChecklist),
      loaded: true,
    });
  };

  React.useEffect(() => {
    if (params.policyId) {
      fetchChecklist(params.policyId);
    } else {
      setChecklist(initialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.policyId]);

  return (
    <ChecklistContext.Provider
      value={{
        ...checklist,
        saveChecklist,
        submitChecklist,
        fetchChecklist,
      }}
    >
      <div
        className={cx({
          [styles.LoadingOffset]: !checklist.loaded,
        })}
      >
        <LoadingWrapper loading={!checklist.loaded} />
      </div>

      {checklist.loaded && children}
    </ChecklistContext.Provider>
  );
}
