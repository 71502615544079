import React, { useEffect } from "react";
import classnames from "classnames";
import { Select, Option, FormValue } from "informed";
import TextInput from "~/components/TextInput";
import { LOCAL_PHONE_CODES, CARIB_PHONE_CODES } from "~/helpers/constants";
import { countryCodesData, findCountryByAlpha3 } from "~/helpers/countryCodes";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useCustomFieldApi } from "~/helpers/hooks/useCustomFieldApi";
import { phoneRequired } from "~/helpers/validators";

import "flag-icon-css/css/flag-icon.css";
import styles from "./index.module.scss";

export type Country = {
  name: string;
  alpha2: string;
  alpha3: string;
  diallingCode: string;
};

type Props = {
  countryCodeFieldName: string;
  phoneFieldName: string;
  countryCodeInitialValue?: string;
  phoneInitialValue?: string;
  label: string;
  phoneRequiredError: string;
  validate: (
    diallingCode: string,
    phoneNumber: FormValue
  ) => string | undefined;
};

const PhoneInput = ({
  countryCodeFieldName,
  phoneFieldName,
  countryCodeInitialValue,
  phoneInitialValue,
  label,
  phoneRequiredError,
  validate,
}: Props) => {
  const countryCodeFieldState = useCustomFieldState(countryCodeFieldName);
  const phoneFieldState = useCustomFieldState(phoneFieldName);
  const phoneApi = useCustomFieldApi(phoneFieldName);
  const [selectedCountry, setSelectedCountry] = React.useState<Country>(
    findCountryByAlpha3(
      countryCodeFieldState.value ?? countryCodeInitialValue
    ) ?? {
      name: "",
      alpha2: "",
      alpha3: "",
      diallingCode: "",
    }
  );

  useEffect(() => {
    const code = findCountryByAlpha3(countryCodeFieldState.value);
    if (code !== undefined) {
      setSelectedCountry(code);
    }
  }, [countryCodeFieldState.value]);

  useEffect(() => {
    phoneApi.setError(
      validate(selectedCountry.diallingCode, phoneFieldState.value)
    );
    // validate can cause the effect to be called infinitely
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry.diallingCode, phoneFieldState.value]);

  return (
    <div className={styles.InputWrapper}>
      <div className={styles.dropdownWrapper}>
        <span
          className={classnames(
            styles.flag,
            `flag-icon-${selectedCountry?.alpha2.toLowerCase()}`,
            {
              "flag-icon": selectedCountry,
            }
          )}
        />

        <label
          htmlFor="countryPhoneCode"
          className={styles.dropdownLabel}
          aria-label="Please select your country dialling code"
        >
          <Select
            className={classnames(styles.dropdown)}
            field={countryCodeFieldName}
            initialValue={countryCodeInitialValue}
            data-descr={`+${selectedCountry.diallingCode}`}
          >
            <optgroup label="Local">
              {countryCodesData
                .filter((country) => LOCAL_PHONE_CODES.includes(country.alpha3))
                .map((country) => (
                  <Option key={country.alpha2} value={country.alpha3}>
                    {`${country.alpha3} +${country.diallingCode}`}
                  </Option>
                ))}
            </optgroup>

            <optgroup label="Caribbean">
              {countryCodesData
                .filter((country) => CARIB_PHONE_CODES.includes(country.alpha3))
                .map((country) => (
                  <Option key={country.alpha2} value={country.alpha3}>
                    {`${country.alpha3} +${country.diallingCode}`}
                  </Option>
                ))}
            </optgroup>

            <optgroup label="Rest of the world">
              {countryCodesData
                .filter(
                  (country) =>
                    ![...CARIB_PHONE_CODES, ...LOCAL_PHONE_CODES].includes(
                      country.alpha3
                    )
                )
                .map((country) => (
                  <Option key={country.alpha2} value={country.alpha3}>
                    {`${country.alpha3} +${country.diallingCode}`}
                  </Option>
                ))}
            </optgroup>
          </Select>
        </label>
      </div>

      <TextInput
        label={label}
        hideLabelFromView
        field={phoneFieldName}
        placeholder="000-0000"
        keepState
        validate={phoneRequired(
          phoneRequiredError,
          selectedCountry.diallingCode
        )}
        initialValue={phoneInitialValue}
        validateOnBlur
        validateOnMount
        validateOnChange
        type="phone"
        autoComplete="tel-national"
        autoFocus
        inputMode="numeric"
      />
    </div>
  );
};

export default PhoneInput;
