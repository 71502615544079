import React, { useEffect, useMemo } from "react";
import { useHistory, matchPath } from "react-router-dom";
import { useUserContext } from "~/contexts/UserProvider";
import { useAssistantContext } from "~/contexts/AlmiAssistantProvider";
import { Text, H3 } from "~/components/Typography";
import SubmitButton from "~/components/SubmitButton";
import { CountryCode, InsuranceType } from "../../../types";
import { findCountryByAlpha3 } from "~/helpers/countryCodes";
// eslint-disable-next-line
import styles from "./index.module.scss";
import pushWithParams from "~/helpers/pushWithParams";
import { phoneRequired } from "~/helpers/validators";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { useCustomFieldApi } from "~/helpers/hooks/useCustomFieldApi";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useMotorContext } from "~/contexts/MotorProvider";
import { useHomeContext } from "~/contexts/HomeProvider";
import PhoneInput from "~/components/PhoneInput";

type Props = {
  nextPath?: string;
  insuranceType: InsuranceType;
};

const AccountPhone = ({ insuranceType, nextPath }: Props) => {
  const userCtx = useUserContext();
  const history = useHistory();
  const assistantCtx = useAssistantContext();
  const heapCtx = useHeapContext();
  const motorCtx = useMotorContext();
  const homeCtx = useHomeContext();
  const countryCodeFieldName = "countryCodeField";
  const phoneFieldName = "phoneNumberField";
  const countryCodeFieldState = useCustomFieldState(countryCodeFieldName);
  const phoneFieldState = useCustomFieldState(phoneFieldName);
  const phoneApi = useCustomFieldApi(phoneFieldName);
  const { value, error } = useCustomFieldState(phoneFieldName);
  const phoneRequiredError = "Please enter a valid phone number";

  const pathMatch: {
    params: { countryCode: CountryCode; insuranceType: InsuranceType };
  } | null = matchPath(history.location.pathname, {
    path: "/:countryCode/:insuranceType/:step",
    exact: true,
    strict: false,
  });

  const countryCodeInitialValue = useMemo(() => {
    if (userCtx.countryPhoneCode) {
      const contextCountryCode = findCountryByAlpha3(userCtx.countryPhoneCode);

      if (contextCountryCode) {
        return contextCountryCode.alpha3;
      }
    }

    if (pathMatch) {
      const paramCountryCode = findCountryByAlpha3(
        pathMatch.params.countryCode.toUpperCase()
      );

      if (paramCountryCode) {
        return paramCountryCode.alpha3;
      }
    }
    return "BRB";
  }, [userCtx.countryPhoneCode, pathMatch]);

  useEffect(() => {
    if (assistantCtx.isOpen) {
      assistantCtx.updateAssistant({
        isOpen: false,
      });
    }
  }, [assistantCtx]);

  const handleClick = () => {
    if (phoneFieldState.value && countryCodeFieldState.value) {
      const val = phoneFieldState.value?.toString().replace(/[^\d]/g, "");
      const selectedCountry = findCountryByAlpha3(countryCodeFieldState.value);
      if (selectedCountry === undefined) return;

      const phoneReqFunction = phoneRequired(
        phoneRequiredError,
        selectedCountry.diallingCode
      );

      if (phoneReqFunction(val) !== undefined) {
        phoneApi.setError(phoneRequiredError);
        return;
      }

      userCtx.setState({
        phoneNumber: `${val}`,
        diallingCode: findCountryByAlpha3(countryCodeFieldState.value)
          ?.diallingCode,
        countryPhoneCode: countryCodeFieldState.value as string,
      });

      heapCtx.track(HeapEventName.ONBOARDING_PHONE_NUMBER, {
        "Policy ID":
          insuranceType === "motor"
            ? motorCtx.policyInfo.data.id
            : homeCtx.policyId,
      });

      if (nextPath) {
        pushWithParams(history, nextPath);
      }
    }
  };

  const label = "What's your phone number?";

  return (
    <>
      <Text>Great! Almost done</Text>
      <H3 className={styles.Subtitle} component="h1">
        {label}
      </H3>

      <PhoneInput
        countryCodeFieldName={countryCodeFieldName}
        phoneFieldName={phoneFieldName}
        countryCodeInitialValue={countryCodeInitialValue}
        phoneInitialValue={userCtx.phoneNumber?.toString()}
        label={label}
        phoneRequiredError={phoneRequiredError}
        validate={(diallingCode, phoneNumber) => {
          const phoneReqFunction = phoneRequired(
            phoneRequiredError,
            diallingCode
          );
          if (phoneReqFunction(phoneNumber) === undefined) {
            return undefined;
          }
          return phoneRequiredError;
        }}
      />

      <div className={styles.ButtonWrapper}>
        <SubmitButton
          id="AccountPhone-SubmitButton"
          disabled={!!(error || !value)}
          className={styles.Button}
          onClick={handleClick}
        >
          Next
        </SubmitButton>
      </div>
    </>
  );
};

export default AccountPhone;
