import React from "react";
import { Route, useHistory } from "react-router-dom";
import { useAuth0Context, UserRoleType } from "~/contexts/Auth0Context";
import Loading from "~/components/Loading";
import styles from "./index.module.scss";
import { parse } from "query-string";
import { H4, Text, Bold } from "../Typography";
import AlertImage from "~/assets/images/alert.png";

type PropsType = {
  children: React.ReactElement;
  path?: string | string[];
  redirectAfterLogin?: string;
  passOnProps?: boolean;
  requireAdminRole?: boolean;
  exact?: boolean;
};

const AuthorizedRoute = ({
  children,
  redirectAfterLogin,
  passOnProps,
  requireAdminRole,
  ...rest
}: PropsType) => {
  const history = useHistory();
  const auth0 = useAuth0Context();
  const childWithProps = React.cloneElement(children, rest);

  const initiateLogin = (pathname: string) => {
    const searchParams = parse(history.location.search);
    let redirect = redirectAfterLogin;

    if (
      searchParams.redirectAfterLogin &&
      !Array.isArray(searchParams.redirectAfterLogin)
    ) {
      redirect = searchParams.redirectAfterLogin;
    }

    return auth0.login(redirect ? redirect : pathname)();
  };

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (auth0.isLoading()) {
          return (
            <div className={styles.loadingWrapper}>
              <Loading />
            </div>
          );
        } else {
          if (!auth0.isAuthenticated()) {
            initiateLogin(location.pathname);

            return (
              <div className={styles.loadingWrapper}>
                <Loading />
              </div>
            );
          } else {
            if (requireAdminRole) {
              //Check if user is considered an admin
              if (
                !auth0.getRolesFromToken().some((role) => {
                  return [
                    UserRoleType.admin,
                    UserRoleType.cxo,
                    UserRoleType.readonly,
                  ].includes(role);
                })
              ) {
                return (
                  <div className={styles.NoAccessWrapper}>
                    <img
                      className={styles.NoAccessAlert}
                      src={AlertImage}
                      alt="Alert"
                    />
                    <H4 className={styles.NoAccessHeading}>Access Denied</H4>

                    <Text className={styles.NoAccessText}>
                      Unfortunately you do not have access to this link. Please
                      contact us at <Bold>almi.bb/support</Bold> for access.
                    </Text>
                  </div>
                );
              }
            }

            return passOnProps ? childWithProps : children;
          }
        }
      }}
    />
  );
};

export default AuthorizedRoute;
