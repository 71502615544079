import React from "react";
import classNames from "classnames";

import PlanDetail from "../index";

import { TextSmall, Bold } from "~/components/Typography";

import { formatCurrency } from "~/helpers/currency";
import {
  HOME_PROPERTY_EXCESS,
  HOME_CONTENTS_EXCESS,
  HOME_ALL_RISK_EXCESS,
  HOME_PUBLIC_LIABILITY,
  HOME_PERSONAL_LIABILITY,
  HOME_EMPLOYER_LIABILITY,
} from "~/helpers/constants";

import type { SummaryValue, Props as PlanDetailProps } from "../index";
import type { Quote } from "../../../types";

import styles from "./index.module.scss";
import textStyles from "~/components/Typography/index.module.scss";

type Props = {
  quote: Quote;
  allRisk?: boolean;
  allRiskType: "local" | "worldwide" | undefined;
  allRiskValue: number;
  installments: number;
  additionalContents: boolean;
  countryName: string;
  isRecurring?: boolean;
  replacementValue?: number;
  contentsValue?: number;
  isOpen: PlanDetailProps["isOpen"];
  onCloseModal: PlanDetailProps["onCloseModal"];
  onSelectPlan?: PlanDetailProps["onSelectPlan"];
  showContinue?: PlanDetailProps["showContinue"];
};

const HomePlanDetail = ({
  isRecurring,
  installments,
  allRisk,
  allRiskType,
  additionalContents,
  replacementValue,
  contentsValue,
  allRiskValue,
  countryName,
  quote,
  isOpen,
  onCloseModal,
  onSelectPlan,
  showContinue,
}: Props) => {
  const subTitle = `Property ${
    additionalContents ? "+ Contents " : ""
  }insurance`;

  // This is a list of entries. The spread syntax is used to choose whether or
  // not to include each item in the list.
  // For example, [...[5], ...[], ...[6]] === [5, 6].
  const summaryValues: SummaryValue[] = [
    ...(replacementValue
      ? [
          {
            title: "Property Coverage",
            value: formatCurrency(replacementValue, true, true),
            excess: `The excess will be 2% of the sum insured with a minimum of 
              ${formatCurrency(HOME_PROPERTY_EXCESS, true, true)}`,
          },
        ]
      : []),
    ...(allRisk && allRiskValue
      ? [
          {
            title: `All Risks Coverage (${
              allRiskType === "worldwide" ? "Worldwide" : countryName
            })`,
            value: formatCurrency(allRiskValue, true, true),
            excess: formatCurrency(HOME_ALL_RISK_EXCESS, true, true),
          },
        ]
      : []),
    ...(additionalContents && contentsValue
      ? [
          {
            title: "Contents Coverage",
            value: formatCurrency(contentsValue, true, true),
            excess: `The excess will be 2% of the sum insured with a minimum of ${formatCurrency(
              HOME_CONTENTS_EXCESS,
              true,
              true
            )}`,
          },
          {
            title: "Employer Liability",
            value: formatCurrency(HOME_EMPLOYER_LIABILITY, true, true),
          },
        ]
      : []),

    {
      title: "Public Liability",
      value: formatCurrency(HOME_PUBLIC_LIABILITY, true, true),
    },
    {
      title: "Personal Liability",
      value: formatCurrency(HOME_PERSONAL_LIABILITY, true, true),
    },
  ];

  const coveredContent = [
    {
      text: (
        <TextSmall>
          <Bold>Natural Disasters</Bold>: In the event of a natural
          disaster—fire, explosion, flood, earthquake—you will be covered.
        </TextSmall>
      ),
    },
    ...(additionalContents
      ? [
          {
            text: (
              <TextSmall>
                <Bold>Contents Coverage</Bold>: If your possessions get stolen
                or damaged, you will be covered up to the insured amount.
              </TextSmall>
            ),
          },
        ]
      : []),
    ...(allRisk
      ? [
          {
            text: (
              <TextSmall>
                <Bold>All Risks Coverage</Bold>: If your possessions get broken
                from accidental damage or lost outside your home, you will be
                covered up to the insured amount.
              </TextSmall>
            ),
          },
        ]
      : []),
    {
      text: (
        <TextSmall>
          <Bold>Theft and Burglary</Bold>: If anyone attempts or breaks into
          your private property and damages or steals items, you’ll be covered.
        </TextSmall>
      ),
    },
    {
      text: (
        <>
          <TextSmall>
            <Bold>Accidents</Bold>: If an accident occurs and your property gets
            damaged, you’ll be covered. This includes:
          </TextSmall>
          <ul
            className={classNames(
              textStyles.TextSmall,
              styles.AccidentExamplesList
            )}
          >
            <li>Accidental breakage of glass</li>
            <li>Falling branches from trees</li>
            <li>Breakage or collapse of radio/television aerials</li>
          </ul>
        </>
      ),
    },
  ];

  const notCoveredContent = [
    {
      text:
        "If there is loss or damage to a sea wall leading to property damage, you will not be covered and you will have to pay the full amount.",
    },
  ];

  const installmentValue = isRecurring
    ? quote.valueByNumberOfInstallments.twelve
    : quote.totalValue;

  return (
    <PlanDetail
      title="Home Coverage Plan"
      subTitle={subTitle}
      summary="Our home insurance offers complete coverage of your home. Rest assured that you’re protected in the event of any incidents."
      summaryValues={summaryValues}
      coveredContent={coveredContent}
      notCoveredContent={notCoveredContent}
      installmentCount={installments}
      installmentValue={installmentValue}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      onSelectPlan={onSelectPlan}
      showContinue={showContinue}
    />
  );
};

export default HomePlanDetail;
