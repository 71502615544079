import React from "react";
import { useHistory } from "react-router-dom";
import SubmitButton from "~/components/SubmitButton";
import Checkbox from "~/components/Checkbox";
import LoadingWrapper from "~/components/LoadingWrapper";
import { H3, TextSmall, Text, Bold } from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import styles from "./style.module.scss";
import { InsuranceType } from "../../../../../types";

type Props = {
  insuranceType: InsuranceType;
  nextPath?: string;
};

export default function Declaration({ insuranceType, nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const heapCtx = useHeapContext();
  const history = useHistory();
  const agreeState = useCustomFieldState("declarationAgree");
  const checklistCtx = useChecklistContext();
  const [saving, setSaving] = React.useState<boolean>(false);

  useMountEffect(() => {
    insuranceType === "motor"
      ? heapCtx.track(HeapEventName.MOTOR_DECLARATION_SCREEN, {})
      : heapCtx.track(HeapEventName.HOME_DECLARATION_SCREEN, {});
    headerCtx.setState({
      currentStep: -1,
      totalOfSteps: 0,
      title: "Declaration",
    });
  });

  const onContinueClick = async () => {
    try {
      if (nextPath) {
        setSaving(true);

        await checklistCtx.saveChecklist({
          policyId: String(checklistCtx.policyId),
          agreedToDeclaration: true,
          nextPath,
        });

        await checklistCtx.submitChecklist(String(checklistCtx.policyId));
        heapCtx.track(HeapEventName.CHECKLIST_DECLARATION, {});
        history.push(nextPath);
      }
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <H3>Please agree to the declaration to finish.</H3>

          <Text className={styles.SubTitle}>
            Before agreeing, make sure to double check your answers if
            necessary.
          </Text>

          <Checkbox
            className={styles.DeclarationCheckbox}
            field="declarationAgree"
            label={<Bold>I agree to the following information:</Bold>}
          />

          <div className={styles.Paragraphs}>
            {insuranceType === "motor" ? (
              <>
                <TextSmall>
                  I declare to the best of my knowledge and belief I have
                  disclosed all information relevant to the acceptance of this
                  Proposal and that the vehicle(s) is (are) in a sound
                  roadworthy condition.
                </TextSmall>

                <TextSmall>
                  I understand that failure to do so may invalidate my
                  insurance.
                </TextSmall>

                <TextSmall>
                  I consent to the seeking of information from other insurers,
                  finance companies, databases or any other relevant body to
                  check the answers I have provided and authorise the giving of
                  such information.
                </TextSmall>

                <TextSmall>
                  I agree that this Proposal and declaration shall be the basis
                  of the contract between me and the insurer and shall be deemed
                  to be incorporated in the contract.
                </TextSmall>

                <TextSmall>
                  I agree to accept the insurance contract subject to its terms
                  and conditions.
                </TextSmall>
              </>
            ) : (
              <>
                <TextSmall>
                  I declare that the above answers are true and that I have not
                  withheld any material information regarding this Proposal I
                  agree that this Declaration and the answers given above as
                  well as any further Proposal or Declaration or Statement made
                  in writing by me or anyone acting on my behalf shall form the
                  basis of the Contract between me and GUARDIAN GENERAL
                  INSURANCE LIMITED and I further agree to accept indemnity
                  subject to the conditions in and endorsed on the Company’s
                  Policy.
                </TextSmall>

                <TextSmall>
                  I consent to the seeking of information from other insurers,
                  finance companies, databases or any other relevant body to
                  check the answers I have provided and I authorize the giving
                  of such information.
                </TextSmall>

                <TextSmall>
                  I also declare that the SUMS TO BE INSURED ON BUILDINGS,
                  SWIMMING POOL AND/OR CONTENTS REPRESENT NOT LESS THAN THE FULL
                  VALUE OF THE PROPERTY mentioned above.
                </TextSmall>
              </>
            )}
          </div>

          <SubmitButton
            id="ChecklistDeclaration-SubmitButton"
            className={styles.AgreeButton}
            onClick={onContinueClick}
            disabled={!agreeState.value}
          >
            Continue
          </SubmitButton>
        </>
      )}
    </>
  );
}
