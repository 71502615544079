import React from "react";
import classnames from "classnames";
import { FormValue, Text } from "informed";

import styles from "./index.module.scss";
import { InputProps } from "./index.types";
import { formatFn, maskedCurrency, maskedPhone } from "./index.utils";
import { TextSmall } from "~/components/Typography";
import TextDateWithPicker from "./TextDateWithPicker";
import { useAssistantContext } from "~/contexts/AlmiAssistantProvider";

import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";

// Actual Text Inputs
const TextInput = (props: InputProps) => {
  const {
    maintainCursor,
    field,
    initialValue,
    placeholder,
    type,
    validate,
    validateOnChange,
    validateOnMount,
    validateOnBlur,
    validationSuccess,
    notify,
    size,
    keepState,
    currencyPrefix = "$ ",
    autoComplete,
    label,
    hideLabelFromView,
    ariaDescribedBy,
    className,
    inputMode,
    ...rest
  } = props;

  delete rest.autoFocus;

  const { touched, error, value } = useCustomFieldState(field);
  const { updateAssistant } = useAssistantContext();

  const commonProps = {
    id: `id-${field}`,
    field,
    name: field,
    /**
     * We are using @ts-ignore here and further below because the informed lib (for the current version 3.14.0)
     * doesn't have the type definition for the formatter  property which generates
     * warnings and then block the build of the app
     */
    // @ts-ignore
    formatter: formatFn({
      type,
      size,
      inputId: `id-${field}`,
    }),
    maintainCursor,
    validateOnMount,
    validateOnChange,
    validateOnBlur,
    notify,
    initialValue,
    placeholder,
    keepState,
    autoComplete,
    className: styles.TextInput,
    autoFocus: false,
    "aria-describedby": error && `${field}-msg ${ariaDescribedBy || ""}`,
    onFocus: () => updateAssistant({ showTextOnMobile: false }),
    onBlur: () => updateAssistant({ showTextOnMobile: true }),
    ...rest,
  };

  const currencyMaskOffset = (value: FormValue) => {
    let offset = 0;
    if (typeof value === "string" && value) {
      const elem = document.getElementById(`id-${field}`) as HTMLInputElement;
      const stripped = value.replace(/[^\d]/g, "");
      const masked = maskedCurrency(stripped);
      const valueCommas = value.split(",").length - 1;
      const maskedCommas = masked.split(",").length - 1;
      if (value.length === 1) {
        offset++;
        offset++;
      } else if (
        maskedCommas > valueCommas &&
        elem?.selectionStart === value.length
      ) {
        offset++;
      } else if (maskedCommas > valueCommas) {
        const pointFive = 0.5;
        offset = pointFive;
      } else if (maskedCommas < valueCommas) {
        offset--;
        offset--;
      }
      const updated = currencyPrefix.concat(masked);
      if (masked.length === 0) {
        return { value: "", offset };
      }
      return { value: updated, offset };
    }
    return { value, offset };
  };

  let backspace = false;
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace") {
      backspace = true;
    }
  };

  const handleNumberKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  const phoneMaskOffset = (value: FormValue) => {
    let offset = 0;
    const two = 2;
    if (typeof value === "string" && value) {
      const elem = document.getElementById(`id-${field}`) as HTMLInputElement;
      const stripped = value.replace(/[^\d]/g, "");
      const masked = maskedPhone(stripped);
      if (elem?.selectionStart === 0) {
        offset = offset - value.length - two;
      } else if (
        !value.includes("-") &&
        masked.includes("-") &&
        elem?.selectionStart === value.length
      ) {
        offset++;
      } else if (value.includes("-") && !masked.includes("-")) {
        offset--;
      } else if (
        value.includes("-") &&
        masked.includes("-") &&
        elem?.selectionStart === two * two &&
        !backspace
      ) {
        const pointFive = 0.5;
        offset = pointFive;
      }
      return { value: masked, offset };
    }
    return { value, offset };
  };

  const init = () => {
    if (initialValue) {
      if (type === "currency") {
        const masked = maskedCurrency(initialValue);
        return "$ ".concat(masked);
      }
      if (type === "phone") {
        return maskedPhone(initialValue);
      }
      return "";
    }
    return "";
  };

  return (
    <div className={classnames(styles.TextInputWrapper, className)}>
      <label
        htmlFor={`id-${field}`}
        aria-label={label && hideLabelFromView ? label : undefined}
      >
        {label && !hideLabelFromView && (
          <TextSmall className={styles.InputLabel}>{label}</TextSmall>
        )}
        {type === "date" ? (
          <TextDateWithPicker
            {...commonProps}
            validate={(value) => (validate ? validate(`${value}`) : undefined)}
            {...rest}
          />
        ) : type === "currency" ? (
          <Text
            {...commonProps}
            //@ts-ignore
            formatter={undefined}
            inputMode={inputMode}
            type="text"
            validate={(value) => (validate ? validate(`${value}`) : undefined)}
            initialValue={init}
            maskWithCursorOffset={currencyMaskOffset}
            maintainCursor
            {...rest}
          />
        ) : type === "phone" ? (
          <Text
            {...commonProps}
            //@ts-ignore
            formatter={undefined}
            inputMode={inputMode}
            type="text"
            validate={(value) => (validate ? validate(`${value}`) : undefined)}
            initialValue={init}
            maskWithCursorOffset={phoneMaskOffset}
            maintainCursor
            onKeyDownCapture={handleKeyDown}
            {...rest}
          />
        ) : type === "number" ? (
          <Text
            {...commonProps}
            type={"text"}
            readOnly={false}
            inputMode={inputMode}
            validate={validate}
            onKeyDownCapture={handleNumberKeyDown}
            {...rest}
          />
        ) : (
          <Text
            {...commonProps}
            type={type !== "password" && type !== "email" ? "text" : type}
            readOnly={type === "readonly"}
            inputMode={inputMode}
            validate={validate}
            {...rest}
          />
        )}
      </label>

      {type === "password" ? (
        <>
          {value && error && (
            <span
              className={classnames(styles.Message, styles.ErrorMessage)}
              id={`${field}-msg`}
              aria-live="polite"
            >
              {error}
            </span>
          )}
        </>
      ) : (
        <>
          {touched && error && (
            <span
              className={classnames(styles.Message, styles.ErrorMessage)}
              id={`${field}-msg`}
              aria-live="polite"
            >
              {error}
            </span>
          )}
        </>
      )}

      {value && !error && validationSuccess && (
        <span
          className={classnames(styles.Message, styles.SuccessMessage)}
          id={`${field}-msg`}
          aria-live="polite"
        >
          <span className={styles.MessageSmile}>☺︎</span>
          {validationSuccess}
        </span>
      )}
    </div>
  );
};

export default TextInput;
