export const BARBADOS_KEY_NAME = "Barbados";
export const GUYANA_KEY_NAME = "Guyana";

export const INSURANCE_KEY_MOTOR = "Motor";
export const INSURANCE_KEY_HOME = "Home";

export const INSURANCE_TYPE_KEY_NAMES = [
  INSURANCE_KEY_MOTOR,
  INSURANCE_KEY_HOME,
];
export const COUNTRY_KEY_NAMES = [BARBADOS_KEY_NAME, GUYANA_KEY_NAME];
export const NO_COVERAGE_URL = "/contact-guardian";
export const GONE_TOO_FAR = "/gone-too-far";
export const PAYMENT_MOTOR_RESULT_URL = "/BRB/motor/payment-result";
export const PAYMENT_HOME_RESULT_URL = "/BRB/home/payment-result";
export const PAYMENT_LANDING_URL = "/payment-landing";
export const PAYMENT_UPDATE_RESULT_URL = "/portal/update-payment-result";
export const ADMIN_DASHBOARD_URL = "/portal/admin";
export const ADMIN_CUSTOMER_DETAILS_URL = `${ADMIN_DASHBOARD_URL}/customer`;
export const ADMIN_POLICY_DETAILS_URL = `${ADMIN_DASHBOARD_URL}/policy`;
