import { useFormState } from "informed";
import React from "react";
import { IContentsType } from "~/contexts/HomeProvider";
import Button from "~/components/Button";
import styles from "./index.module.scss";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { Text } from "~/components/Typography";
import TextInput from "~/components/TextInput";
import { required, validatePastOrCurrentDate } from "~/helpers/validators";
import GridWrapper from "../GridWrapper";
import cleanNumberInput from "~/helpers/formatters/cleanNumberInput";

type Props = {
  originalContentItem?: IContentsType;
  handleSave: (savedItem: IContentsType) => void;
  handleDelete: () => void;
  isAllRisk: boolean;
};

enum FieldNames {
  Name = "ContentItemName",
  Value = "ContentItemValue",
  PurchaseDate = "ContentItemPurchaseDate",
  IdentificationNumber = "ContentItemIdentificationNumber",
}

export default function ContentItemModal({
  originalContentItem,
  handleSave,
  handleDelete,
  isAllRisk,
}: Props) {
  const formState = useFormState();
  const [currentStep, setCurrentStep] = React.useState<FieldNames>(
    FieldNames.Name
  );
  const [showValueInput, setShowValueInput] = React.useState<boolean>(false);
  const [
    showPurcahseDateInput,
    setShowPurcahseDateInput,
  ] = React.useState<boolean>(false);
  const [
    showIdentificationNumberInput,
    setShowIdentificationNumberInput,
  ] = React.useState<boolean>(false);
  const [showNext, setShowNext] = React.useState<boolean>(false);
  const [showSave, setShowSave] = React.useState<boolean>(false);
  const [showDelete, setShowDelete] = React.useState<boolean>(false);

  useMountEffect(() => {
    if (originalContentItem) {
      setCurrentStep(FieldNames.IdentificationNumber);
      setShowValueInput(true);
      setShowPurcahseDateInput(true);
      setShowIdentificationNumberInput(true);
      setShowSave(true);
      setShowDelete(true);
    } else {
      setShowNext(true);
    }
  });

  const handleNextClick = () => {
    switch (currentStep) {
      case FieldNames.Name:
        setCurrentStep(FieldNames.PurchaseDate);
        setShowPurcahseDateInput(true);
        break;
      case FieldNames.PurchaseDate:
        setCurrentStep(FieldNames.Value);
        setShowValueInput(true);
        break;
      case FieldNames.Value:
        setShowNext(false);
        setShowSave(true);
        setShowIdentificationNumberInput(true);
        setCurrentStep(FieldNames.IdentificationNumber);
        break;
    }
  };

  const handleSaveClick = () => {
    const saveItem: IContentsType = {
      name: String(formState.values[FieldNames.Name]) ?? "",
      value: cleanNumberInput(formState.values[FieldNames.Value]),
      purchaseDate: String(formState.values[FieldNames.PurchaseDate]) ?? "",
      identificationNumber: formState.values[FieldNames.IdentificationNumber]
        ? String(formState.values[FieldNames.IdentificationNumber])
        : undefined,
      isAllRisk: originalContentItem
        ? originalContentItem.isAllRisk
        : isAllRisk,
      createdAsAllRisk: originalContentItem
        ? originalContentItem.createdAsAllRisk
        : isAllRisk,
    };

    handleSave(saveItem);
  };

  const handleDeleteClick = () => {
    handleDelete();
  };

  return (
    <GridWrapper>
      <div className={styles.ModalContent}>
        <div className={styles.InputWrapper}>
          <Text>Item name</Text>

          <TextInput
            field={FieldNames.Name}
            placeholder={"Samsung 32” TV"}
            type="text"
            validate={required("Name is required")}
            validateOnBlur
            validateOnChange
            validateOnMount
            initialValue={
              originalContentItem ? originalContentItem.name : undefined
            }
          />
        </div>

        {showPurcahseDateInput && (
          <div className={styles.InputWrapper}>
            <Text>Date of purchase</Text>

            <TextInput
              field={FieldNames.PurchaseDate}
              placeholder={"DD / MM / YYYY"}
              type="date"
              maintainCursor
              validate={validatePastOrCurrentDate}
              validateOnBlur
              validateOnChange
              validateOnMount
              initialValue={
                originalContentItem
                  ? originalContentItem.purchaseDate
                  : undefined
              }
            />
          </div>
        )}

        {showValueInput && (
          <div className={styles.InputWrapper}>
            <Text>Estimated item value</Text>

            <TextInput
              field={FieldNames.Value}
              placeholder={"$ 0"}
              type="currency"
              initialValue={
                originalContentItem
                  ? String(originalContentItem.value)
                  : undefined
              }
              validate={(value) => {
                const val = Number((value as string).replace(/[^\d]/g, ""));
                if (!value || value === "undefined") {
                  return "Estimated item value is required";
                } else if (val < 1) {
                  return "Estimated item value must be at least $1";
                } else {
                  return undefined;
                }
              }}
              validateOnBlur
              validateOnChange
              validateOnMount
              inputMode="numeric"
            />
          </div>
        )}

        {showIdentificationNumberInput && (
          <div className={styles.InputWrapper}>
            <Text>Serial number (optional)</Text>

            <TextInput
              field={FieldNames.IdentificationNumber}
              placeholder={"Serial number"}
              type="text"
              initialValue={
                originalContentItem
                  ? originalContentItem.identificationNumber
                  : undefined
              }
            />
          </div>
        )}
      </div>

      <div className={styles.ButtonsRow}>
        <div className={styles.ButtonWrapper}>
          {showNext && (
            <Button
              id="ContentItemModal-NextButton"
              disabled={formState.invalid}
              onClick={handleNextClick}
            >
              Next
            </Button>
          )}
        </div>

        <div className={styles.ButtonWrapper}>
          {showSave && (
            <Button
              id="ContentItemModal-SaveButton"
              disabled={formState.invalid}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          )}
        </div>

        <div className={styles.ButtonWrapper}>
          {showDelete && (
            <Button
              id="ContentItemModal-DeleteButton"
              className={styles.RemoveButton}
              onClick={handleDeleteClick}
              type="button"
              text
              variant="textSmall"
            >
              Delete Item
            </Button>
          )}
        </div>
      </div>
    </GridWrapper>
  );
}
