import { useFormState } from "informed";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import SubmitButton from "~/components/SubmitButton";
import LoadingWrapper from "~/components/LoadingWrapper";
import TextInput from "~/components/TextInput";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { H3, Text } from "~/components/Typography";
import { useMotorContext } from "~/contexts/MotorProvider";
import styles from "./style.module.scss";
import { required } from "~/helpers/validators";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import MobileUploadRequired from "~/components/MobileUploadRequired";
import { isMobile } from "react-device-detect";
import { UserContext } from "~/contexts/UserProvider";

type Props = {
  nextPath?: string;
};

const UPDATE_VEHICLE = gql`
  mutation updateVehicle($policyId: String!, $input: MotorVehicleUpdateInput!) {
    updateVehicle(policyId: $policyId, input: $input)
  }
`;

const canShowField = (
  fieldName: string,
  formStage: number,
  formStages: string[]
) => {
  const idx = formStages.indexOf(fieldName);
  if (idx === -1) return false;
  return formStage >= idx;
};

export default function EngineAndChassisNumber({ nextPath }: Props) {
  const history = useHistory();
  const motorCtx = useMotorContext();
  const userCtx = useContext(UserContext);
  const formState = useFormState();
  const engineNumberField = "engineNumber";
  const chassisNumberField = "chassisNumber";
  const preciseEngineSizeField = "preciseEngineSize";
  const formStages = [
    engineNumberField,
    chassisNumberField,
    // Do not ask for engine size if the car is electric
    ...(motorCtx.vehicleInfo.data.isElectric ? [] : [preciseEngineSizeField]),
  ];
  // If any fields are filled out, set to last stage
  // Otherwise, set to first stage
  const [formStage, setFormStage] = React.useState(
    motorCtx.vehicleInfo.data.engineNumber ||
      motorCtx.vehicleInfo.data.chassisNumber ||
      motorCtx.vehicleInfo.data.preciseEngineSize
      ? formStages.length - 1
      : 0
  );
  const [saving, setSaving] = React.useState<boolean>(false);
  const [updateVehicle] = useMutation(UPDATE_VEHICLE);

  useUpdateAssistant({
    text: (
      <Text>
        You can find this information on your Pro forma invoice, sale letter,
        previous insurance certificate or renewal notice.
      </Text>
    ),
  });

  const handleOnContinue = async () => {
    if (formStage < formStages.length - 1) {
      setFormStage(formStage + 1);
      return;
    }
    // else formStage == formStages.length - 1, i.e. last stage
    try {
      if (nextPath) {
        setSaving(true);
        await Promise.all([
          // waits for all promises in the array
          motorCtx.vehicleInfo.setItem(
            "engineNumber",
            formState.values[engineNumberField]
          ),
          motorCtx.vehicleInfo.setItem(
            "chassisNumber",
            formState.values[chassisNumberField]
          ),
          updateVehicle({
            variables: {
              policyId: motorCtx.policyInfo.data.id,
              // Turns formStages into key-value pairs (stage, formState.values[stage])
              input: formStages.reduce(
                (input, stage) => ({
                  ...input,
                  [stage]: formState.values[stage],
                }),
                {}
              ),
            },
          }),
        ]);

        if (motorCtx.savingsInfo.data.occupation === "Student") {
          history.push("/BRB/motor/documents");
        } else {
          history.push(nextPath);
        }
      }
    } catch (error) {
      setSaving(false);
      console.log(error);
    }
  };

  if (!isMobile && userCtx.email) {
    return (
      <MobileUploadRequired
        title="Great! Your account has been created.
  To complete the process and get your coverage plan, you will need a smartphone."
        email={userCtx.email}
        policyId={motorCtx.policyInfo.data.id || ""}
      />
    );
  }
  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <Text>
            We need this to issue your Motor Insurance cover note digitally.
          </Text>

          <H3 className={styles.Title}>Engine Number</H3>

          {canShowField(engineNumberField, formStage, formStages) && (
            <div className={styles.TextWrapper}>
              <TextInput
                field={engineNumberField}
                placeholder="G16AID318681"
                type={"text"}
                validate={required("Please enter engine number")}
                validateOnBlur
                validateOnChange
                validateOnMount
                initialValue={
                  motorCtx.vehicleInfo.data.engineNumber
                    ? motorCtx.vehicleInfo.data.engineNumber
                    : ""
                }
              />
            </div>
          )}

          {canShowField(chassisNumberField, formStage, formStages) && (
            <>
              <H3 className={styles.Title}>Chassis Number (VIN)</H3>

              <div className={styles.TextWrapper}>
                <TextInput
                  field={chassisNumberField}
                  placeholder="MHYDN71V4LJ403091"
                  type="text"
                  validate={required("Please enter chassis number")}
                  validateOnBlur
                  validateOnChange
                  validateOnMount
                  initialValue={
                    motorCtx.vehicleInfo.data.chassisNumber
                      ? motorCtx.vehicleInfo.data.chassisNumber
                      : ""
                  }
                />
              </div>
            </>
          )}

          {canShowField(preciseEngineSizeField, formStage, formStages) && (
            <>
              <H3 className={styles.Title}>Engine Size (cc)</H3>

              <div className={styles.TextWrapper}>
                <TextInput
                  field={preciseEngineSizeField}
                  placeholder="1800"
                  type="number"
                  size={9}
                  initialValue={
                    motorCtx.vehicleInfo.data.preciseEngineSize ?? ""
                  }
                  inputMode="numeric"
                />
              </div>
            </>
          )}

          <SubmitButton
            id="VehicleInfo-SubmitButton"
            className={styles.ContinueButton}
            onClick={handleOnContinue}
            // The button is disabled when any current or previous stages are falsy
            disabled={formStages
              .slice(0, formStage + 1)
              .some(
                (stage) =>
                  !formState.values[stage] ||
                  (formState.values[stage] as string)?.replace(/\s/g, "")
                    .length === 0
              )}
          >
            {formStages[formStage] === formStages[formStages.length - 1]
              ? "Continue"
              : "Next"}
          </SubmitButton>
        </>
      )}
    </>
  );
}
