import Button from "~/components/Button";
import TextInput from "~/components/TextInput";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useFieldState } from "informed";
import styles from "./index.module.scss";
import hasErrorCode from "~/helpers/hasErrorCode";
import { ToolProps } from "../..";

enum Field {
  CoverageDate = "adminRegenerateContract-CoverageDateField",
  Passcode = "adminRegenerateContract-Passcode",
}

const REGENRATE_CONTRACT = gql`
  mutation adminRegenerateContract(
    $policyId: String!
    $coverageDate: String!
    $passcode: String!
    $callbackUrl: String!
  ) {
    adminRegenerateContract(
      policyId: $policyId
      coverageDate: $coverageDate
      passcode: $passcode
      callbackUrl: $callbackUrl
    )
  }
`;

const AdminRegenerateContract = ({ identifier, onClose }: ToolProps) => {
  const coverageDateState = useFieldState(Field.CoverageDate);
  const passcodeState = useFieldState(Field.Passcode);
  const callbackUrl = useState(`${location.origin}/portal`);

  const [processing, setProcessing] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  const [regenerateContract] = useMutation(REGENRATE_CONTRACT);

  const handleSubmit = async () => {
    setProcessing(true);

    try {
      const response = await regenerateContract({
        variables: {
          policyId: identifier,
          coverageDate: String(coverageDateState.value),
          passcode: String(passcodeState.value),
          callbackUrl: String(callbackUrl),
        },
      });

      setProcessing(false);

      if (response?.data?.adminRegenerateContract) {
        onClose({
          manuallyClosed: false,
          success: true,
          shouldReload: false,
          message: "Contract regenerated.",
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        if (hasErrorCode(error, "NOT_ADMIN")) {
          onClose({
            manuallyClosed: false,
            success: false,
            errorCode: "NOT_ADMIN",
          });
        }

        setErrorMessage(error.message);
      }

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      {errorMessage && <div className={styles.Error}>{errorMessage}</div>}

      <TextInput
        type="date"
        label={"Coverage Date"}
        placeholder="DD/MM/YYYY"
        field={Field.CoverageDate}
      />

      <TextInput
        type="text"
        label="Passcode"
        placeholder="Passcode"
        field={Field.Passcode}
      />

      <Button
        disabled={!passcodeState.value || processing}
        onClick={handleSubmit}
      >
        {processing ? "Processing..." : "Process"}
      </Button>
    </div>
  );
};

export default AdminRegenerateContract;
