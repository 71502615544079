import React, { useMemo } from "react";
import { useFormState } from "informed";
import { useHistory } from "react-router-dom";
import SubmitButton from "~/components/SubmitButton";
import LoadingWrapper from "~/components/LoadingWrapper";
import TextInput from "~/components/TextInput";
import { H3, Text } from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import PhoneInput from "~/components/PhoneInput";
import { findCountryByAlpha3 } from "~/helpers/countryCodes";
import { phoneRequired } from "~/helpers/validators";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";

import styles from "./index.module.scss";

type Props = {
  nextPath?: string;
};

export default function EmployerName({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const formState = useFormState();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const employerNameField = "employerName";
  const employerAddressField = "employerAddress";
  const employerPhoneField = "employerPhone";
  const employerCountryCodeField = "employerCountryCode";
  const phoneRequiredError = "Please enter a valid phone number";
  const phoneFieldState = useCustomFieldState(employerPhoneField);
  const formStages = [
    employerNameField,
    employerAddressField,
    employerPhoneField,
  ];
  // If any stages were filled out previously, show all stages
  // Otherwise, start from the first stage
  const [formStage, setFormStage] = React.useState(
    checklistCtx.motor.employerName ||
      checklistCtx.motor.employerAddress ||
      checklistCtx.motor.employerCountryCode ||
      checklistCtx.motor.employerPhone
      ? formStages.length - 1
      : 0
  );
  const [saving, setSaving] = React.useState<boolean>(false);

  useMountEffect(() => {
    headerCtx.setState({
      totalOfSteps: 6,
      currentStep: 4,
      title: "Occupation",
    });
  });

  const countryCodeInitialValue = useMemo(() => {
    if (checklistCtx.motor.employerCountryCode) {
      const contextCountryCode = findCountryByAlpha3(
        checklistCtx.motor.employerCountryCode
      );

      if (contextCountryCode) {
        return contextCountryCode.alpha3;
      }
    }
    return "BRB";
  }, [checklistCtx.motor.employerCountryCode]);

  const invalidName =
    formState.values[employerNameField] === undefined ||
    !(String(formState.values[employerNameField]).match(/^ *$/) === null);

  const invalidAddress =
    formState.values[employerAddressField] === undefined ||
    !(String(formState.values[employerAddressField]).match(/^ *$/) === null);

  const handleOnContinue = async () => {
    if (formStage < formStages.length - 1) {
      setFormStage(formStage + 1);
      return;
    }
    // else formStage == formStages.length - 1, i.e. last stage
    try {
      if (nextPath) {
        setSaving(true);

        await checklistCtx.saveChecklist({
          policyId: String(checklistCtx.policyId),
          motor: {
            ...checklistCtx.motor,
            employerName: String(formState.values[employerNameField]) ?? null,
            employerAddress:
              String(formState.values[employerAddressField]) ?? null,
            employerCountryCode:
              String(formState.values[employerCountryCodeField]) ?? null,
            employerPhone: String(formState.values[employerPhoneField]) ?? null,
          },
          nextPath,
        });

        history.push(nextPath);
      }
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <Text>We need a few more details about your occupation.</Text>

          {formStage >= formStages.indexOf(employerNameField) && (
            <>
              <H3 className={styles.Title}>
                What is your company/employer’s name?
              </H3>

              <div className={styles.CompanyTextWrapper}>
                <TextInput
                  field={employerNameField}
                  placeholder="Company Name"
                  type={"text"}
                  initialValue={
                    checklistCtx.motor.employerName
                      ? String(checklistCtx.motor.employerName)
                      : ""
                  }
                />
              </div>
            </>
          )}

          {formStage >= formStages.indexOf(employerAddressField) && (
            <>
              <H3 className={styles.Title}>
                What is your company/employer’s address?
              </H3>

              <div className={styles.CompanyTextWrapper}>
                <TextInput
                  field={employerAddressField}
                  placeholder="Address"
                  type="text"
                  initialValue={
                    checklistCtx.motor.employerAddress
                      ? String(checklistCtx.motor.employerAddress)
                      : ""
                  }
                />
              </div>
            </>
          )}

          {formStage >= formStages.indexOf(employerPhoneField) && (
            <>
              <H3 className={styles.Title}>
                What is your company/employer’s phone number?
              </H3>

              <div className={styles.CompanyTextWrapper}>
                <PhoneInput
                  countryCodeFieldName={employerCountryCodeField}
                  phoneFieldName={employerPhoneField}
                  countryCodeInitialValue={countryCodeInitialValue}
                  phoneInitialValue={checklistCtx.motor.employerPhone}
                  label="What is your company/employer’s phone number?"
                  phoneRequiredError={phoneRequiredError}
                  validate={(diallingCode, phoneNumber) => {
                    const phoneReqFunction = phoneRequired(
                      phoneRequiredError,
                      diallingCode
                    );
                    if (phoneReqFunction(phoneNumber) === undefined) {
                      return undefined;
                    }
                    return phoneRequiredError;
                  }}
                />
              </div>
            </>
          )}

          <SubmitButton
            id="EmployerInfo-SubmitButton"
            className={styles.ContinueButton}
            onClick={handleOnContinue}
            disabled={formStages.slice(0, formStage + 1).some((stage) => {
              if (stage === employerNameField) return invalidName;
              if (stage === employerAddressField) return invalidAddress;
              if (stage === employerPhoneField) {
                return phoneFieldState.error || !phoneFieldState.value;
              }
              return false;
            })}
          >
            Next
          </SubmitButton>
        </>
      )}
    </>
  );
}
