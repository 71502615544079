import React from "react";
import { useParams } from "react-router-dom";
import { Form } from "informed";

import { StepType } from "~/helpers/flow";
import { InsuranceType } from "../../types";
import { PAYMENT_HOME_RESULT_URL } from "~/routes/index.constant";

import MultiStepForm from "~/components/MultiStepForm";

import CommercialActivity from "~/screens/Home/CommercialActivity";
import ConstructionType from "~/screens/Home/ConstructionType";
import FloodRisk from "~/screens/Home/FloodRisk";
import PropertyDensity from "~/screens/Home/PropertyDensity";
import CoastalProperty from "~/screens/Home/CoastalProperty";
import AdditionalProtection from "~/screens/Home/AdditionalProtection";
import AccountNote from "~/screens/Account/Note";
import AccountEmail from "~/screens/Account/Email";
import AccountName from "~/screens/Account/Name";
import AccountPhone from "~/screens/Account/PhoneNumber";
import AccountPassword from "~/screens/Account/Password";
import AuthorizedRoute from "~/components/AuthorizedRoute";
import AccountCreated from "~/screens/Account/Created";
import ReplacementValue from "~/screens/Home/ReplacementValue";
import InsuranceReview from "~/screens/Home/InsuranceReview";
import AllRiskType from "~/screens/Home/AllRiskType";
import Occupancy from "~/screens/Home/Occupancy";
import QuickNote from "~/screens/QuickNote";
import HomeAddress from "~/screens/Home/HomeAddress";
import BuildingType from "~/screens/Home/BuildingType";
import PastClaims from "~/screens/Home/PastClaims";
import ContentsCoverage from "~/screens/Home/ContentsCoverage";
import AllRiskItems from "~/screens/Home/AllRiskItems";
import ContentsItems from "~/screens/Home/ContentsItems";
import RequiredDocuments from "~/screens/Home/RequiredDocuments";
import RentOrOwn from "~/screens/Home/RentOrOwn";
import ContentsValue from "~/screens/Home/ContentsValue";
import CoverageConfirmation from "~/screens/Home/CoverageConfirmation";
import DocumentsReview from "~/screens/Identification/DocumentsReview";
import CoverageStart from "~/screens/CoverageStart";
import Payment from "~/screens/Payment/Payment";
import PaymentCheckout from "~/screens/Payment/PaymentCheckout";
import PaymentResult from "~/screens/Payment/PaymentResult";
import ReviewPolicy from "~/screens/ReviewPolicy";
import CoveragePlan from "~/screens/Home/CoveragePlan";
import Occupation from "~/screens/Occupation";
import VerificationCode from "~/screens/Account/VerificationCode";
import ExistingMotorPolicy from "~/screens/Home/ExistingMotorPolicy";
import GoodState from "~/screens/Home/GoodState";

const COUNTRY_NAME = "Barbados";
const COUNTRY_CODE = "BRB";

const BARBADOS_HOME_STEPS: StepType[] = [
  {
    path: "/BRB/home/quick-note",
    headerTitle: "Introduction",
  },
  {
    path: "/BRB/home/rent-or-own",
    headerTitle: "Property Information",
  },
  {
    path: "/BRB/home/address",
    headerTitle: "Property Information",
  },
  {
    path: "/BRB/home/building-type",
    headerTitle: "Property Information",
  },
  {
    path: "/BRB/home/occupancy-type",
    headerTitle: "Property Information",
  },
  {
    path: "/BRB/home/commercial-activity",
    headerTitle: "Property Information",
  },
  {
    path: "/BRB/home/construction-type",
    headerTitle: "Personal Information",
  },
  {
    path: "/BRB/home/flood-risk",
    headerTitle: "Property Information",
  },
  {
    path: "/BRB/home/coastal-property",
    headerTitle: "Property Information",
  },
  {
    headerTitle: "Property Information",
    path: "/BRB/home/property-density",
  },
  {
    path: "/BRB/home/good-state",
  },
  {
    path: "/BRB/home/replacement-value",
    headerTitle: "Property Information",
  },
  {
    path: "/BRB/home/past-claims",
    headerTitle: "Property Information",
  },
  {
    path: "/BRB/home/contents-coverage",
    headerTitle: "Content Details",
  },
  {
    path: "/BRB/home/contents-items",
    headerTitle: "Content Details",
  },
  {
    path: "/BRB/home/additional-protection",
    headerTitle: "Content Details",
  },
  {
    path: "/BRB/home/all-risk-items",
    headerTitle: "Content Details",
  },
  {
    path: "/BRB/home/all-risk-type",
    headerTitle: "Content Details",
  },
  {
    path: "/BRB/home/contents-value",
    headerTitle: "Content Details",
  },
  {
    path: "/BRB/home/insurance-review",
    headerTitle: "Content Details",
  },
  {
    path: "/BRB/home/coverage-start",
    title: "Coverage Start",
    headerTitle: "Coverage Details",
  },
  {
    path: "/BRB/home/existing-motor-policy",
    title: "Existing Motor Policy",
    headerTitle: "Almost there!",
  },
  {
    path: "/BRB/home/email",
    title: "What's your email",
    headerTitle: "Almost there!",
  },
  {
    path: "/BRB/home/coverage-plan",
    title: "Coverage Plans",
  },
  {
    path: "/BRB/home/account",
    title: "Create your account",
    headerTitle: "Create an account",
  },
  {
    path: "/BRB/home/your-name",
    title: "What shall we call you",
    headerTitle: "Create an account",
  },
  {
    path: "/BRB/home/phone",
    title: "What's your phone number",
    headerTitle: "Create an account",
  },
  {
    path: "/BRB/home/password",
    title: "Please create a password",
    headerTitle: "Create an account",
  },
  {
    path: "/BRB/home/verification",
    title: "Please enter verification code",
    headerTitle: "Verify account",
  },
  {
    path: "/BRB/home/account-complete",
    title: "Thank you! Your account has been created.",
    headerTitle: "Create an account",
    hideBackButton: true,
  },
  {
    path: "/BRB/home/occupation",
    headerTitle: "Occupation",
    hideBackButton: true,
  },
  {
    path: "/BRB/home/documents",
    title: "Identity Documents",
    headerTitle: "Identity Documents",
    hideBackButton: true,
  },
  {
    path: "/BRB/home/documents-review",
    title: "Review Documents",
    headerTitle: "Review Documents",
    hideBackButton: true,
  },
  {
    path: "/BRB/home/review-policy",
    title: "Review Policy and Signature",
    headerTitle: "Review Proposal and Signature",
    hideBackButton: true,
  },
  {
    path: "/BRB/home/payment",
    title: "Payment",
    headerTitle: "Payment",
    hideBackButton: true,
  },
  {
    path: "/BRB/home/checkout",
    headerTitle: "Payment",
    hideBackButton: true,
  },
  {
    path: PAYMENT_HOME_RESULT_URL,
    headerTitle: "Payment",
    hideBackButton: true,
  },
  {
    path: "/BRB/home/coverage-confirmation",
    title: "Coverage Confirmation",
    headerTitle: "Coverage Confirmation",
  },
];

const BarbadosHome = () => {
  const {
    insuranceType,
  }: {
    insuranceType: InsuranceType;
  } = useParams();
  return (
    <Form>
      <MultiStepForm steps={BARBADOS_HOME_STEPS}>
        <QuickNote insuranceType={insuranceType} />
        <RentOrOwn />
        <HomeAddress />
        <BuildingType />
        <Occupancy />
        <CommercialActivity />
        <ConstructionType />
        <FloodRisk />
        <CoastalProperty />
        <PropertyDensity />
        <GoodState />
        <ReplacementValue />
        <PastClaims />
        <ContentsCoverage />
        <ContentsItems />
        <AdditionalProtection />
        <AllRiskItems />
        <AllRiskType countryName={COUNTRY_NAME} />
        <ContentsValue />
        <InsuranceReview countryName={COUNTRY_NAME} />
        <CoverageStart insuranceType={insuranceType} />
        <ExistingMotorPolicy />
        <AccountEmail
          insuranceType={insuranceType}
          countryCode={COUNTRY_CODE}
        />
        <CoveragePlan countryName={COUNTRY_NAME} />
        <AccountNote insuranceType={insuranceType} />
        <AccountName insuranceType={insuranceType} />
        <AccountPhone insuranceType={insuranceType} />
        <AccountPassword insuranceType={insuranceType} />
        <VerificationCode />
        <AuthorizedRoute passOnProps>
          <AccountCreated insuranceType={insuranceType} />
        </AuthorizedRoute>
        <AuthorizedRoute passOnProps>
          <Occupation insuranceType={insuranceType} />
        </AuthorizedRoute>
        <AuthorizedRoute passOnProps>
          <RequiredDocuments insuranceType={insuranceType} />
        </AuthorizedRoute>
        <AuthorizedRoute passOnProps>
          <DocumentsReview insuranceType={insuranceType} />
        </AuthorizedRoute>
        <AuthorizedRoute passOnProps>
          <ReviewPolicy insuranceType={insuranceType} />
        </AuthorizedRoute>
        <AuthorizedRoute passOnProps>
          <Payment insuranceType={insuranceType} countryName={COUNTRY_NAME} />
        </AuthorizedRoute>
        <AuthorizedRoute passOnProps>
          <PaymentCheckout
            paymentResultUrl={PAYMENT_HOME_RESULT_URL}
            insuranceType={insuranceType}
          />
        </AuthorizedRoute>
        <AuthorizedRoute passOnProps>
          <PaymentResult didNotAgreeRedirect={PAYMENT_HOME_RESULT_URL} />
        </AuthorizedRoute>
        <CoverageConfirmation />
      </MultiStepForm>
    </Form>
  );
};

export default BarbadosHome;
