import { RadioGroup } from "informed";
import React from "react";
import RadioSelector from "../RadioSelector";
import styles from "./style.module.scss";

type Props = {
  field: string;
  handleClick: (yes: boolean) => void;
};

export default function RadioBinary({ field, handleClick }: Props) {
  const [selected, setSelected] = React.useState<string>("");

  const handleSelect = (yes: boolean) => {
    setSelected(yes ? "yes" : "no");
    handleClick(yes);
  };

  return (
    <div className={styles.BinaryContent}>
      <RadioGroup field={field}>
        <RadioSelector
          key="yes"
          field={field}
          value="yes"
          checked={selected === "yes"}
          onChange={() => handleSelect(true)}
        >
          Yes
        </RadioSelector>

        <RadioSelector
          key="no"
          field={field}
          value="no"
          checked={selected === "no"}
          onChange={() => handleSelect(false)}
        >
          No
        </RadioSelector>
      </RadioGroup>
    </div>
  );
}
