import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FormValues } from "informed";

import styles from "./index.module.scss";
import { useMotorContext } from "~/contexts/MotorProvider";

import { Bold, H3, TextSmall } from "~/components/Typography";
import VehicleSearchDisplaySmall from "~/components/VehicleSearchDisplaySmall";
import SelectableCard from "~/components/SelectableCard";

import SubmitButton from "~/components/SubmitButton";
import DriverModal from "./DriverModal";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import pushWithParams from "~/helpers/pushWithParams";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";

export type IDriver = { name: string; age: string; yearsDriving: string };

function DriversDetails({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const motorCtx = useMotorContext();
  const [toggleModal, setToggleModal] = useState(false);
  const [currentIndexEdit, setCurrentIndexEdit] = useState<number>();
  const heapCtx = useHeapContext();

  const { drivers } = motorCtx.policyInfo.data;

  useUpdateAssistant({
    text: (
      <TextSmall>
        Drivers must be between <Bold>20 and 70 years old</Bold> to get Almi
        motor insurance.
      </TextSmall>
    ),
  });

  const handleOnChange = () => {
    try {
      if (nextPath) {
        heapCtx.track(HeapEventName.MOTOR_ADDITIONAL_DRIVERS, {
          "Number of Additional Drivers": Array.isArray(drivers)
            ? drivers.length
            : 0,
        });

        pushWithParams(history, nextPath);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addDriver = (values: FormValues) => {
    if (values?.name && values?.age && values?.yearsDriving) {
      const newData =
        typeof currentIndexEdit !== "number"
          ? [...drivers, values as IDriver]
          : drivers.map((item, index) => {
              if (index === currentIndexEdit) return { ...item, ...values };
              else return item;
            });

      if (motorCtx?.setState) {
        motorCtx.setState("policyInfo", {
          drivers: newData,
        });
      }

      heapCtx.track(HeapEventName.MOTOR_DRIVER_DETAILS, {
        Age: values.age as number,
        Experience: values.yearsDriving as number,
      });

      setToggleModal(false);
      setCurrentIndexEdit(undefined);
    }
  };

  const editDriver = (index: number) => {
    setCurrentIndexEdit(index);
    setToggleModal(true);
  };

  const removeDriver = (index: number) => {
    const newData = [...drivers.filter((_, i) => i !== index)];

    if (motorCtx?.setState) {
      motorCtx.setState("policyInfo", { drivers: newData });
    }

    setToggleModal(false);
  };

  return (
    <>
      <VehicleSearchDisplaySmall
        prefix={`Your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()} `}
      />

      <H3 className={styles.Heading} component="h1" id="add-driver-title">
        Besides you, will anyone else drive your vehicle?
      </H3>

      <TextSmall>
        <span id="add-driver-desc">If not, please skip this section.</span>
      </TextSmall>

      <div className={styles.Content}>
        <div className={styles.DriversDetailsContent}>
          {Array.isArray(drivers)
            ? drivers.map((driver, index) => (
                <SelectableCard
                  key={`${driver.name}+${index}`}
                  title={driver.name}
                  headingComponent="h2"
                  description="Details added"
                  selected
                  onClick={() => editDriver(index)}
                  ctaText="Edit"
                />
              ))
            : null}
          <SelectableCard
            title="Add driver"
            headingComponent="h2"
            description="Tap to add driver details"
            selected={false}
            describedby="add-driver-title add-driver-desc"
            onClick={() => {
              setCurrentIndexEdit(undefined);
              setToggleModal(true);
            }}
          />
        </div>

        <SubmitButton
          id={"DriversDetails-SubmitButton"}
          className={styles.BtnContinue}
          onClick={handleOnChange}
        >
          {drivers.length ? "Next" : "Skip"}
        </SubmitButton>
      </div>

      <DriverModal
        isOpen={toggleModal}
        closeModal={() => setToggleModal(false)}
        onSave={addDriver}
        onRemove={
          typeof currentIndexEdit === "number"
            ? () => removeDriver(currentIndexEdit)
            : undefined
        }
        initialValues={
          typeof currentIndexEdit === "number"
            ? drivers[currentIndexEdit]
            : undefined
        }
      />
    </>
  );
}

export default DriversDetails;
