import React, { useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { validatePassword, requiredPassword } from "~/helpers/validators";
import { H5, Text, TextSmall } from "~/components/Typography";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import { Eye, EyeClose } from "~/components/Icon/icons";
import styles from "./index.module.scss";
import { Form, FormValue, FormValues } from "informed";
import classNames from "classnames";

const CHANGE_PASSWORD = gql`
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input)
  }
`;

type gqlError = {
  message: string;
};

const PortalUpdatePassword = () => {
  const apolloClient = useApolloClient();
  const [status, setStatus] = useState<
    "ready" | "submitting" | "success" | "error"
  >("ready");
  const [errors, setErrors] = useState<string[]>([]);
  const [instanceKey, setInstanceKey] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (values: FormValues) => {
    setStatus("submitting");
    setErrors([]);

    try {
      const passwordMutation = await apolloClient.mutate({
        mutation: CHANGE_PASSWORD,
        variables: {
          input: {
            current: values.currentPassword,
            new: values.newPassword,
          },
        },
      });

      if (passwordMutation.data) {
        setStatus("success");
        // Re-mount the form to destroy local state
        setInstanceKey(Date.now());
      } else {
        throw new Error("Unusable API response");
      }
    } catch (error) {
      if (error.graphQLErrors && error.graphQLErrors.length) {
        setErrors(error.graphQLErrors.map(({ message }: gqlError) => message));
      } else {
        setErrors(["Something went wrong. Please try again."]);
      }
      setStatus("error");
      console.warn(error);
    }
  };

  const fields = [
    {
      title: "Current password",
      name: "currentPassword",
      validate: requiredPassword("Enter your current password"),
      notify: undefined,
      withMaskToggle: true,
      autocomplete: "current-password",
    },
    {
      title: "Enter new password",
      name: "newPassword",
      validate: validatePassword,
      notify: ["confirmPassword"],
      withMaskToggle: false,
      autocomplete: "new-password",
    },
    {
      title: "Confirm new password",
      name: "confirmPassword",
      validate: (value?: FormValue, values?: FormValues) =>
        values && values.newPassword === value
          ? undefined
          : "Password does not match",
      notify: ["newPassword"],
      withMaskToggle: false,
      autocomplete: "new-password",
    },
  ];

  return (
    <>
      <Text className={styles.Intro}>
        Password must be at least 8 characters and contain at least 3 of: <br />
        1 uppercase, 1 lowercase, 1 number, or 1 special character
      </Text>

      <Form onSubmit={handleSubmit} key={instanceKey}>
        {({ formState }) => (
          <>
            <div className={styles.FormFields}>
              {fields.map(
                ({
                  title,
                  name,
                  validate,
                  notify,
                  withMaskToggle,
                  autocomplete,
                }) => (
                  <div key={name}>
                    <H5>{title}</H5>

                    <div className={styles.InputContainer}>
                      <div
                        className={classNames(styles.InputWrapper, {
                          [styles.WithMaskToggle]: withMaskToggle,
                        })}
                      >
                        <TextInput
                          field={name}
                          placeholder={
                            showPassword ? "password" : "•••••••••••"
                          }
                          keepState
                          type={showPassword ? "text" : "password"}
                          validate={validate}
                          validateOnBlur
                          validateOnChange
                          validateOnMount
                          notify={notify}
                          autoComplete={autocomplete}
                        />
                        {withMaskToggle && (
                          <button
                            className={styles.IconButton}
                            type="button"
                            aria-label={
                              showPassword ? "Hide password" : "Show password"
                            }
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Eye /> : <EyeClose />}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            <div className={styles.Actions}>
              <Button
                type="submit"
                disabled={!!Object.values(formState.errors).length}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </Form>

      {status === "submitting" && (
        <TextSmall className={styles.StatusInfo}>Saving...</TextSmall>
      )}
      {status === "error" && (
        <TextSmall className={styles.StatusError}>
          {errors.join(", ")}
        </TextSmall>
      )}
      {status === "success" && (
        <TextSmall className={styles.StatusSuccess}>
          Password changed!
        </TextSmall>
      )}
    </>
  );
};

export default PortalUpdatePassword;
