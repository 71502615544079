import React from "react";
import gql from "graphql-tag";
import { useFieldState } from "informed";
import { useMutation } from "@apollo/react-hooks";
import hasErrorCode from "~/helpers/hasErrorCode";
import styles from "./index.module.scss";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import { ToolProps } from "../..";

enum Field {
  Password = "AdminChangePassword-Password",
  Passcode = "AdminChangePassword-Passcode",
}

const CHANGE_PASSWORD = gql`
  mutation adminChangePassword(
    $email: String!
    $password: String!
    $passcode: String!
  ) {
    adminChangePassword(email: $email, password: $password, passcode: $passcode)
  }
`;

const AdminChangePassword = ({ identifier, onClose }: ToolProps) => {
  const passwordState = useFieldState(Field.Password);
  const passcodeState = useFieldState(Field.Passcode);

  const [processing, setProcessing] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  const [changePassword] = useMutation(CHANGE_PASSWORD);

  const handleSubmit = async () => {
    setProcessing(true);

    try {
      const response = await changePassword({
        variables: {
          email: identifier,
          password: String(passwordState.value),
          passcode: String(passcodeState.value),
        },
      });

      const data = response?.data?.adminChangePassword;

      if (!data) {
        throw new Error("Failed to change password.");
      }

      setProcessing(false);

      onClose({
        manuallyClosed: false,
        success: true,
        shouldReload: false,
        message: "Password successfully changed.",
      });
    } catch (error) {
      if (error instanceof Error) {
        if (hasErrorCode(error, "NOT_ADMIN")) {
          onClose({
            manuallyClosed: false,
            success: false,
            errorCode: "NOT_ADMIN",
          });
        }

        setErrorMessage(error.message);
      }

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      {errorMessage && <div className={styles.Error}>{errorMessage}</div>}

      <>
        <TextInput
          type="text"
          label="User's New Password"
          placeholder="Password"
          field={Field.Password}
        />

        <TextInput
          type="text"
          label="Passcode"
          placeholder="Passcode"
          field={Field.Passcode}
        />

        <Button
          disabled={!passwordState.value || !passcodeState.value || processing}
          onClick={handleSubmit}
        >
          {processing ? "Processing..." : "Process"}
        </Button>
      </>
    </div>
  );
};

export default AdminChangePassword;
