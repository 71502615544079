/* eslint-disable no-magic-numbers */
import React from "react";
import classnames from "classnames";
import styles from "./index.module.scss";
import typographyStyles from "~/components/Typography/index.module.scss";

import Icon from "../Icon";

import { H5, InjectableText, TextTagType } from "../Typography";

export type Props = {
  title: string;
  description?: string;
  className?: string;
  selected: boolean;
  onClick: () => void;
  error?: string;
  headingComponent?: TextTagType;
  ctaText?: string;
  autoFocus?: boolean;
  describedby?: string;
  id?: string;
};

function SelectableCard(props: Props) {
  const {
    title,
    description,
    className,
    selected,
    error,
    onClick,
    headingComponent,
    ctaText,
    autoFocus,
    describedby,
    id,
  } = props;

  const classes = classnames(className, styles.CardWrapper, {
    [styles.Selected]: selected,
    className,
  });

  const classesSVG = classnames(styles.SVGWrapper, styles.Horizontal, {
    [styles.HasCardDescription]: title || description, // tile card doesn't have title in horizontal
  });

  return (
    <button
      id={id}
      className={classes}
      onClick={onClick}
      type="button"
      autoFocus={autoFocus}
      aria-describedby={describedby}
    >
      <div className={styles.BackgroundOverlayer} />

      <div className={styles.Elements}>
        <div className={classesSVG}>
          <div className={styles.SVGContent}>
            <Icon
              width="65%"
              height="65%"
              name="CheckRound"
              className={classnames(styles.SelectedIcon, {
                [styles.HideIconAnim]: !selected,
                [styles.ShowIconAnim]: selected,
              })}
            />
          </div>

          <div className={styles.SVGContent}>
            <Icon
              width="65%"
              height="65%"
              name="Plus"
              className={classnames(styles.UnselectedIcon, {
                [styles.HideIconAnim]: selected,
                [styles.ShowIconAnim]: !selected,
              })}
            />
          </div>
        </div>

        {title || description ? (
          <div className={styles.Content}>
            {title && (
              <H5 className={styles.CardTitle} component={headingComponent}>
                {title}
              </H5>
            )}

            {description && (
              <InjectableText
                className={classnames(styles.CardDescription, {
                  [styles.Error]: !!error,
                })}
                styleType={typographyStyles.TextExtraSmall}
                inject={error || description}
              />
            )}
          </div>
        ) : null}

        {ctaText && selected && (
          <div className={styles.RightElemWrapper}>{ctaText}</div>
        )}
      </div>
    </button>
  );
}

export default SelectableCard;
