import React from "react";
import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";

import { useHomeContext } from "~/contexts/HomeProvider";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import { required } from "~/helpers/validators";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import {
  MINIMUM_HOME_REPLACEMENT_VALUE,
  MAXIMUM_HOME_REPLACEMENT_VALUE,
} from "~/helpers/constants";
import { NO_COVERAGE_URL } from "~/routes/index.constant";

import { H3, TextSmall } from "~/components/Typography";
import TextInput from "~/components/TextInput";
import SubmitButton from "~/components/SubmitButton";

export default function ReplacementValue({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const homeCtx = useHomeContext();
  const heapCtx = useHeapContext();
  const fieldName = "replacementValue";
  const { value, error } = useCustomFieldState(fieldName);
  useUpdateAssistant({
    text: (
      <TextSmall>
        This is the cost to rebuild your house if it is destroyed. You can find
        this on a recent property valuation or from an existing policy.
      </TextSmall>
    ),
  });

  const handleOnNext = () => {
    const val = parseFloat((value as string).replace(/[^\d]/g, ""));
    heapCtx.track(HeapEventName.HOME_REPLACEMENT_VALUE, {
      "Replacement Value": val,
    });
    if (
      val < MINIMUM_HOME_REPLACEMENT_VALUE ||
      val > MAXIMUM_HOME_REPLACEMENT_VALUE
    ) {
      history.push(`${NO_COVERAGE_URL}?message=low-replacement-value`);
    } else if (nextPath) {
      homeCtx.setState({ replacementValue: val });
      history.push(nextPath);
    }
  };

  return (
    <fieldset>
      <legend>
        <H3 component="h1">What is the replacement value of your home?</H3>
      </legend>
      <div className={styles.TextInputWrapper}>
        <TextInput
          hideLabelFromView
          field={fieldName}
          placeholder="$ 0"
          type="currency"
          keepState
          validate={required("Please enter the replacement value")}
          validateOnBlur
          validateOnChange
          autoFocus
          initialValue={homeCtx?.replacementValue?.toString()}
          inputMode="numeric"
        />
      </div>
      <div className={styles.ButtonWrapper}>
        <SubmitButton
          id="ReplacementValue-SubmitButton"
          className={styles.ButtonNext}
          disabled={!!(error || !value || !String(value)?.length)}
          onClick={handleOnNext}
        >
          Next
        </SubmitButton>
      </div>
    </fieldset>
  );
}
