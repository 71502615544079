import React from "react";
import { useHistory } from "react-router-dom";
import { FormValue, RadioGroup } from "informed";

import styles from "./index.module.scss";

import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import { NO_COVERAGE_URL } from "~/routes/index.constant";
import { useHomeContext } from "~/contexts/HomeProvider";

import { H3, TextSmall } from "~/components/Typography";
import RadioSelector from "~/components/RadioSelector";

export default function PastClaims({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const homeCtx = useHomeContext();
  const fieldName = "pastClaims";

  const { value } = useCustomFieldState(fieldName);

  useUpdateAssistant({
    text: (
      <TextSmall>
        This includes claims made for your home or its contents.
      </TextSmall>
    ),
  });

  const handleOnNext = (value?: FormValue) => {
    homeCtx.setState({ claimHistory: value as "yes" | "no" });

    if (value === "yes") {
      history.push(`${NO_COVERAGE_URL}?message=home-past-claims`);

      return;
    }
    if (nextPath) history.push(nextPath);
  };

  return (
    <>
      <fieldset>
        <legend>
          <H3 component="h1">
            Do you have any previous claims for this property?
          </H3>
        </legend>

        <div className={styles.PastClaimsContent}>
          <RadioGroup field={fieldName} keepState>
            <RadioSelector
              key="yes"
              field={fieldName}
              value="yes"
              checked={homeCtx?.claimHistory === "yes" || value === "yes"}
              onChange={handleOnNext}
            >
              Yes
            </RadioSelector>

            <RadioSelector
              key="No"
              field={fieldName}
              value="no"
              checked={homeCtx?.claimHistory === "no" || value === "no"}
              onChange={handleOnNext}
            >
              No
            </RadioSelector>
          </RadioGroup>
        </div>
      </fieldset>
    </>
  );
}
