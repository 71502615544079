import React from "react";
import { Switch } from "react-router-dom";
import Layout from "~/components/Layouts/PortalLayout";
import Dashboard from "~/screens/Portal/Dashboard";
import AccountDetails from "~/screens/Portal/AccountDetails";
import PortalHeaderProvider from "~/contexts/PortalHeaderProvider";
import AuthorizedRoute from "~/components/AuthorizedRoute";
import {
  ADMIN_DASHBOARD_URL,
  PAYMENT_UPDATE_RESULT_URL,
} from "~/routes/index.constant";
import AdminDashboard from "./AdminDashboard";
import PaymentResult from "../Payment/PaymentResult";

const Portal = () => (
  <PortalHeaderProvider>
    <Layout>
      <Switch>
        <AuthorizedRoute
          exact
          path={[
            "/portal",
            "/portal/policy/:policyId",
            "/portal/policy/:policyId/update-payment",
          ]}
        >
          <Dashboard />
        </AuthorizedRoute>

        <AuthorizedRoute exact path={"/portal/account"}>
          <AccountDetails />
        </AuthorizedRoute>

        <AuthorizedRoute exact path={PAYMENT_UPDATE_RESULT_URL} passOnProps>
          <PaymentResult
            didNotAgreeRedirect={PAYMENT_UPDATE_RESULT_URL}
            isPaymentUpdate
          />
        </AuthorizedRoute>

        <AuthorizedRoute requireAdminRole path={ADMIN_DASHBOARD_URL}>
          <AdminDashboard />
        </AuthorizedRoute>
      </Switch>
    </Layout>
  </PortalHeaderProvider>
);

export default Portal;
