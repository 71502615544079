import { RadioGroup } from "informed";
import React from "react";
import { useHistory } from "react-router-dom";
import LoadingWrapper from "~/components/LoadingWrapper";
import RadioSelector from "~/components/RadioSelector";
import { H3 } from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { OptionPair } from "../../../../../types";
import styles from "./index.module.scss";

type Props = {
  nextPath?: string;
};

const BUILT_OPTIONS: OptionPair[] = [
  {
    title: "Less than 5 years ago",
    value: "<5",
  },
  {
    title: "5 to 10 years ago",
    value: "5-10",
  },
  {
    title: "11 to 15 years ago",
    value: "11-15",
  },
  {
    title: "16 to 20 years ago",
    value: "16-20",
  },
  {
    title: "Over 20 years ago",
    value: ">20",
  },
];

export default function HomeBuilt({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const heapCtx = useHeapContext();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const [saving, setSaving] = React.useState<boolean>(false);

  useMountEffect(() => {
    heapCtx.track(HeapEventName.HOME_BUILT_SCREEN, {});
    headerCtx.setState({
      totalOfSteps: 6,
      currentStep: 3,
      title: "Property Information",
    });
  });

  const handleSelect = async (homeBuilt: string) => {
    try {
      if (nextPath) {
        setSaving(true);

        await checklistCtx.saveChecklist({
          policyId: checklistCtx.policyId,
          home: {
            ...checklistCtx.home,
            homeBuilt,
          },
          nextPath,
        });

        heapCtx.track(HeapEventName.HOME_BUILT, {
          "Policy ID": checklistCtx.policyId,
          Built: homeBuilt,
        });

        history.push(nextPath);
      }
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <H3>When was your home built?</H3>

          <div className={styles.Content}>
            <RadioGroup field="built">
              {BUILT_OPTIONS.map((item, i) => {
                return (
                  <RadioSelector
                    key={`Built-RadioSelector-${i}`}
                    field="built"
                    value={item.value}
                    checked={checklistCtx.home?.homeBuilt === item.value}
                    onChange={() => handleSelect(item.value)}
                    labelId={`Built-RadioSelector-${i}`}
                  >
                    {item.title}
                  </RadioSelector>
                );
              })}
            </RadioGroup>
          </div>
        </>
      )}
    </>
  );
}
