import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import auth0 from "auth0-js";
import jwtDecode from "jwt-decode";
import { getItem, removeItem, saveItem } from "~/helpers/storage";
import AppError from "~/helpers/AppError";
import { useHeapContext } from "../HeapProvider";
import getAppOrigin from "~/helpers/getAppOrigin";

const MILLISECONDS_IN_SECOND = 1000;

export enum UserRoleType {
  admin = "ADMIN",
  cxo = "CXO",
  readonly = "READONLY",
}

type TokenType = {
  idToken: string;
  exp: number;
};

type Auth0ContextType = {
  token: TokenType | null;
  handleLogin: (hash: string) => void;
  login: (redirectTo: string | undefined) => () => void;
  logout: (redirectTo?: string) => void;
  isLoading: () => boolean;
  handleSetToken: (idToken: string, exp: number) => void;
  isVerified: boolean;
  isAuthenticated: () => boolean;
  getRolesFromToken: () => UserRoleType[];
  hasPermission: (roles: UserRoleType[]) => boolean;
};

const initialContext = {
  token: null,
  handleLogin: () => {
    throw new Error("Auth0 context has not been initialized.");
  },
  login: () => () => {
    throw new Error("Auth0 context has not been initialized.");
  },
  logout: () => {
    throw new Error("Auth0 context has not been initialized.");
  },
  isLoading: () => {
    throw new Error("Auth0 context has not been initialized.");
  },
  handleSetToken: () => {
    throw new Error("Auth0 context has not been initialized.");
  },
  isVerified: false,
  isAuthenticated: () => {
    throw new Error("Auth0 context has not been initialized.");
  },
  getRolesFromToken: () => {
    throw new Error("Auth0 context has not been intiialized.");
  },
  hasPermission: () => {
    throw new Error("Auth0 context has not been intiialized.");
  },
};

const appOrigin = getAppOrigin();
const Auth0Context = createContext<Auth0ContextType>(initialContext);

const authClient = new auth0.WebAuth({
  domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
  clientID: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
  redirectUri: `${appOrigin}/portal/login`,
  responseType: "token id_token",
  scope: "openid email profile",
  __tryLocalStorageFirst: true,
});

export const useAuth0Context = () => useContext(Auth0Context);

export const Auth0Provider = (props: { children: ReactNode }) => {
  const [token, setToken] = useState<TokenType | null>(initialContext.token);
  const [roles, setRoles] = useState<UserRoleType[] | undefined>(undefined);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [verified, setVerified] = useState(false);
  const heapCtx = useHeapContext();

  const tokenExpired = (expiry: number) => {
    return expiry < Date.now() / MILLISECONDS_IN_SECOND;
  };

  useEffect(() => {
    const storedToken = getItem("token");

    if (!token && storedToken) {
      const parsedToken = JSON.parse(storedToken);

      if (!tokenExpired(parsedToken.exp)) {
        setToken(parsedToken);

        if (!heapCtx.getIdentity()) {
          const decoded: { email: string } = jwtDecode(parsedToken.idToken);
          heapCtx.checkForProfileId(decoded.email);
        }
      } else {
        setToken(null);
        heapCtx.resetIdentity();
        removeItem("token");
      }
    }
    const storedAccessToken = getItem("accessToken");
    if (!accessToken && storedAccessToken) {
      setAccessToken(storedAccessToken);
    } else {
      //setAccessToken(null);
      //removeItem("accessToken");
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, setToken, setAccessToken, accessToken]);

  const login = (redirectTo?: string | undefined) => () => {
    authClient.authorize({
      redirectUri: `${appOrigin}/portal/login${
        redirectTo ? `?redirectTo=${redirectTo}` : ""
      }`,
      prompt: "login",
    });
  };

  const handleVerified = (accessToken: string) => {
    authClient.client.userInfo(accessToken, (err, profile) => {
      if (err) {
        setVerified(false);
      }

      if (profile && profile.email_verified !== undefined) {
        setVerified(profile.email_verified);
      }
    });
  };

  const handleSetToken = (
    idToken: string,
    exp: number,
    accessToken?: string
  ) => {
    const token = {
      idToken,
      exp,
    };

    setToken(token);
    saveItem("token", JSON.stringify(token));

    if (accessToken) {
      setAccessToken(accessToken);
      saveItem("accessToken", accessToken);
    }

    const decoded: { email: string } = jwtDecode(idToken);

    if (!heapCtx.getIdentity()) {
      heapCtx.checkForProfileId(decoded.email);
    }
  };

  const handleLogin = (hashValue: string) => {
    authClient.parseHash({ hash: hashValue }, function (err, authResult) {
      if (err) {
        const errDesc = err.errorDescription || err.description;
        throw new AppError(`Error logging in: ${errDesc}`);
      }
      if (
        authResult &&
        authResult.idToken &&
        typeof authResult.idToken === "string" &&
        authResult.idTokenPayload
      ) {
        if (window.vgo) {
          const decoded: { email: string } = jwtDecode(authResult.idToken);
          window.vgo("setEmail", decoded.email);
        }

        handleSetToken(
          authResult.idToken,
          authResult.idTokenPayload.exp,
          authResult.accessToken
        );

        if (authResult.accessToken) {
          handleVerified(authResult.accessToken);
        }
      }
    });
  };

  /*
    redirectTo must be registered to Auth0 as valid logout urls
  */
  const logout = (redirectTo = appOrigin) => {
    setRoles(undefined);
    removeItem("token");
    heapCtx.resetIdentity();

    authClient.logout({
      returnTo: redirectTo,
    });
  };

  const isLoading = () => {
    return loading;
  };

  const isAuthenticated = () => {
    return !!token && !tokenExpired(token.exp);
  };

  const getRolesFromToken = () => {
    if (roles !== undefined) {
      return roles;
    }

    const roleUrl = "https://app.almi.com/roles";

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-assertion
    const decoded = jwtDecode(token?.idToken ?? "") as any;

    if (decoded?.[roleUrl] && decoded?.[roleUrl]?.length > 0) {
      //Stops react complaining about setting this variable during a render cycle
      setTimeout(() => setRoles(decoded[roleUrl]), 0);
      return decoded[roleUrl];
    } else {
      setTimeout(() => setRoles([]), 0);
      return [];
    }
  };

  const hasPermission = (allowedRoles: UserRoleType[]) => {
    return (
      allowedRoles.filter((roleName) => {
        return getRolesFromToken().includes(roleName);
      }).length > 0
    );
  };

  const providerValue = {
    token,
    login,
    logout,
    handleLogin,
    isLoading,
    handleSetToken,
    isVerified: verified,
    isAuthenticated,
    getRolesFromToken,
    hasPermission,
  };
  return (
    <Auth0Context.Provider value={providerValue}>
      {props.children}
    </Auth0Context.Provider>
  );
};
