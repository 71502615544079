import React, { useEffect, useState } from "react";
import { Form, FormValues } from "informed";

import styles from "../index.module.scss";

import Modal from "~/components/Modal";
import { Text } from "~/components/Typography";
import GridWrapper from "~/components/GridWrapper";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import SubmitButton from "~/components/SubmitButton";

import {
  ageValidator,
  required,
  yearsDrivingExperienceValidator,
} from "~/helpers/validators";

type Props = {
  onSave: (formValues: FormValues) => void;
  onRemove?: () => void;
  isOpen: boolean;
  closeModal: () => void;
  initialValues?: FormValues;
};
export default function DriverModal(props: Props) {
  const { onSave, isOpen, closeModal, initialValues = {} } = props;
  const [isSavedKey, setIsSavedKey] = useState({
    name: false,
    age: false,
    yearsDriving: false,
  });

  useEffect(() => {
    const saved = ["name", "age", "experience"].reduce((acc, key) => {
      if (initialValues[key]) {
        return { ...acc, [key]: true };
      }
      return { ...acc, [key]: false };
    }, {}) as { name: boolean; age: boolean; yearsDriving: boolean };
    setIsSavedKey(saved);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleOnSave = (values: FormValues) => {
    if (values?.name) {
      setIsSavedKey({ ...isSavedKey, name: true });
    }
    if (values?.age) {
      setIsSavedKey({ ...isSavedKey, age: true });
    }
    onSave(values);
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      shouldOverlayCloseOnClick
      title="Add driver"
      className={styles.ModalWrapper}
      id="driver-details-modal"
    >
      <GridWrapper>
        <Form initialValues={initialValues}>
          {({ formState }) => (
            <div className={styles.ModalContent}>
              <div className={styles.InputWrapper}>
                <Text>Enter driver’s name</Text>
                <TextInput
                  label="Enter driver’s name"
                  hideLabelFromView
                  field="name"
                  placeholder="Name"
                  validate={required("Name is required")}
                  validateOnChange
                  validateOnMount
                  validateOnBlur
                  keepState
                  autoComplete="name"
                />
              </div>
              {isSavedKey.name === true &&
                (formState.values?.name || formState.values?.age) && (
                  <div className={styles.InputWrapper}>
                    <Text>Enter driver’s age</Text>
                    <TextInput
                      label="Enter driver’s age"
                      hideLabelFromView
                      field="age"
                      placeholder="00"
                      type="number"
                      size={3}
                      validate={ageValidator}
                      validateOnChange
                      validateOnMount
                      validateOnBlur
                      keepState
                      inputMode="numeric"
                    />
                  </div>
                )}
              {isSavedKey.age === true &&
                (formState.values?.age || formState.values?.yearsDriving) &&
                !formState.errors?.age && (
                  <div className={styles.InputWrapper}>
                    <Text>
                      How many years of driving experience does this driver
                      have?
                    </Text>
                    <TextInput
                      label="Enter years of driving experience"
                      hideLabelFromView
                      field="yearsDriving"
                      type="number"
                      size={3}
                      placeholder="00"
                      keepState
                      validateOnChange
                      validateOnMount
                      validateOnBlur
                      validate={(value) =>
                        yearsDrivingExperienceValidator(
                          value || "",
                          formState.values?.age as number,
                          "Please tell us about this driver's experience",
                          "This driver can't have more than"
                        )
                      }
                      inputMode="numeric"
                    />
                  </div>
                )}
              <div className={styles.ButtonsRow}>
                <div className={styles.ButtonsWrapper}>
                  <SubmitButton
                    onClick={() => handleOnSave(formState.values)}
                    disabled={
                      !!formState.errors.name ||
                      !!formState.errors.age ||
                      !!formState.errors.yearsDriving
                    }
                  >
                    {isSavedKey.age && isSavedKey.name ? "Save" : "Next"}
                  </SubmitButton>
                  <br />
                  {props.onRemove && (
                    <Button
                      className={styles.RemoveButton}
                      onClick={props.onRemove}
                      text
                      variant="textSmall"
                    >
                      Remove Driver
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
        </Form>
      </GridWrapper>
    </Modal>
  );
}
