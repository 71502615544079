import React from "react";
import { Form } from "informed";
import MultiStepForm from "~/components/MultiStepForm";
import ChecklistProvider from "~/contexts/ChecklistProvider";
import Declaration from "~/screens/Portal/Checklist/Common/Declaration";
import AssistantProvider from "~/contexts/AlmiAssistantProvider";
import HeaderProvider from "~/contexts/HeaderProvider";
import { HelmetProvider } from "react-helmet-async";
import ChecklistStarted from "~/screens/Portal/Checklist/Common/ChecklistStarted";
import Footer from "~/components/Footer";
import ChecklistSubmitted from "~/screens/Portal/Checklist/Common/ChecklistSubmitted";
import { ModalProvider } from "~/components/Modal";
import ChecklistRedirect from "~/screens/Portal/Checklist/Common/ChecklistRedirect";
import AuthorizedRoute from "~/components/AuthorizedRoute";
import HomeProvider from "~/contexts/HomeProvider";
import OnboardProvider from "~/contexts/OnboardProvider";
import UserProvider from "~/contexts/UserProvider";
import { InsuranceType } from "../../types";
import FinalDocumentUpload from "~/screens/Portal/Checklist/Common/FinalDocumentUpload";
import Storeys from "~/screens/Portal/Checklist/Home/Storeys";
import HomeBuilt from "~/screens/Portal/Checklist/Home/HomeBuilt";
import Mortgage from "~/screens/Portal/Checklist/Home/Mortgage";
import SurroundingCommercial from "~/screens/Portal/Checklist/Home/SurroundingCommercial";
import LeftWithNoInhabitant from "~/screens/Portal/Checklist/Home/LeftWithNoInhabitant";
import ReclaimedLand from "~/screens/Portal/Checklist/Home/ReclaimedLand";
import LevelledLand from "~/screens/Portal/Checklist/Home/LevelledLand";
import OtherInsurance from "~/screens/Portal/Checklist/Home/OtherInsurance";
import InsuranceHistory from "~/screens/Portal/Checklist/Home/InsuranceHistory";
import SupportProvider from "~/contexts/AlmiSupportProvider";

const PORTAL_CHECKLIST_STEPS = [
  {
    path: "/checklist/home/:policyId/redirect",
  },
  {
    path: "/checklist/home/:policyId/started",
  },
  {
    path: "/checklist/home/:policyId/storeys",
  },
  {
    path: "/checklist/home/:policyId/home-built",
  },
  {
    path: "/checklist/home/:policyId/mortgage",
  },
  {
    path: "/checklist/home/:policyId/surrounding-commercial",
  },
  {
    path: "/checklist/home/:policyId/left-with-no-inhabitant",
  },
  {
    path: "/checklist/home/:policyId/reclaimed-land",
  },
  {
    path: "/checklist/home/:policyId/levelled-land",
  },
  {
    path: "/checklist/home/:policyId/other-insurance",
  },
  {
    path: "/checklist/home/:policyId/insurance-history",
  },
  {
    path: "/checklist/home/:policyId/documents",
  },
  {
    path: "/checklist/home/:policyId/declaration",
  },
  {
    path: "/checklist/home/:policyId/submitted",
    hideBackButton: true,
  },
  {
    path: "/portal",
  },
];

const INSURANCE_TYPE: InsuranceType = "home";

const PortalChecklistHome = () => {
  return (
    <HomeProvider>
      <OnboardProvider>
        <UserProvider>
          <ModalProvider>
            <AssistantProvider>
              <SupportProvider>
                <HeaderProvider>
                  <HelmetProvider>
                    <ChecklistProvider>
                      <Form>
                        <MultiStepForm steps={PORTAL_CHECKLIST_STEPS}>
                          <AuthorizedRoute passOnProps>
                            <ChecklistRedirect />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <ChecklistStarted insuranceType={INSURANCE_TYPE} />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <Storeys />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <HomeBuilt />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <Mortgage />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <SurroundingCommercial />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <LeftWithNoInhabitant />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <ReclaimedLand />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <LevelledLand />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <OtherInsurance />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <InsuranceHistory />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <FinalDocumentUpload />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <Declaration insuranceType={INSURANCE_TYPE} />
                          </AuthorizedRoute>

                          <AuthorizedRoute passOnProps>
                            <ChecklistSubmitted
                              insuranceType={INSURANCE_TYPE}
                            />
                          </AuthorizedRoute>
                        </MultiStepForm>
                      </Form>

                      <Footer />
                    </ChecklistProvider>
                  </HelmetProvider>
                </HeaderProvider>
              </SupportProvider>
            </AssistantProvider>
          </ModalProvider>
        </UserProvider>
      </OnboardProvider>
    </HomeProvider>
  );
};

export default PortalChecklistHome;
