import { RadioGroup } from "informed";
import React from "react";
import { useHistory } from "react-router-dom";
import LoadingWrapper from "~/components/LoadingWrapper";
import RadioSelector from "~/components/RadioSelector";
import { H3 } from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { OptionPair } from "../../../../../types";
import styles from "./index.module.scss";

type Props = {
  nextPath?: string;
};

const STOREY_OPTIONS: OptionPair[] = [
  {
    title: "One storey",
    value: "1",
  },
  {
    title: "Two storeys",
    value: "2",
  },
  {
    title: "Three storeys",
    value: "3",
  },
  {
    title: "Over three storeys",
    value: ">3",
  },
];

export default function Storeys({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const heapCtx = useHeapContext();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const [saving, setSaving] = React.useState<boolean>(false);

  useMountEffect(() => {
    heapCtx.track(HeapEventName.HOME_STOREYS_SCREEN, {});
    headerCtx.setState({
      totalOfSteps: 6,
      currentStep: 3,
      title: "Property Information",
    });
  });

  const handleSelect = async (storeys: string) => {
    try {
      if (nextPath) {
        setSaving(true);

        await checklistCtx.saveChecklist({
          policyId: checklistCtx.policyId,
          home: {
            ...checklistCtx.home,
            storeys,
          },
          nextPath,
        });

        heapCtx.track(HeapEventName.HOME_STOREYS, {
          "Policy ID": checklistCtx.policyId,
          Storeys: storeys,
        });

        history.push(nextPath);
      }
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <H3>How many storeys is your home?</H3>

          <div className={styles.Content}>
            <RadioGroup field="storeys">
              {STOREY_OPTIONS.map((item, i) => {
                return (
                  <RadioSelector
                    key={`Storeys-RadioSelector-${i}`}
                    field="storeys"
                    value={item.value}
                    checked={checklistCtx.home?.storeys === item.value}
                    onChange={() => handleSelect(item.value)}
                    labelId={`Storeys-RadioSelector-${i}`}
                  >
                    {item.title}
                  </RadioSelector>
                );
              })}
            </RadioGroup>
          </div>
        </>
      )}
    </>
  );
}
