import React from "react";
import { Bold } from "../Typography";
import { AvailableIcons } from "~/components/Icon/index.types";
import styles from "./index.module.scss";
import ButtonIcon from "../ButtonIcon";

export type AdminTableColumn = {
  displayName: string;
  sortData?: {
    sortable?: boolean;
    sortField?: string;
    sortBy?: boolean;
    sortDesc?: boolean;
  };
};

export type AdminTableRow = {
  cells: React.ReactElement[];
  onClickRowValue?: string;
};

type Props = {
  columns: AdminTableColumn[];
  rows: AdminTableRow[];
  onChangeSortBy: (sortBy: string) => void;
  onClickRow?: (value: string) => void;
};

const AdminTable = ({ columns, rows, onChangeSortBy, onClickRow }: Props) => {
  const changeSort = (column: AdminTableColumn) => {
    onChangeSortBy(column?.sortData?.sortField ?? "");
  };

  return (
    <table className={styles.Table}>
      <thead>
        <tr>
          {columns.map((column, index) => {
            return (
              <th key={index}>
                <Bold
                  className={
                    column.sortData?.sortable ? styles.SortableColumnText : ""
                  }
                >
                  {column.displayName}
                </Bold>

                {column.sortData?.sortable && (
                  <ButtonIcon
                    iconName={
                      (!column.sortData?.sortBy
                        ? "Minus"
                        : column.sortData?.sortDesc
                        ? "ArrowUp"
                        : "ArrowDown") as AvailableIcons
                    }
                    onClick={() => {
                      changeSort(column);
                    }}
                  />
                )}
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {rows.map((row, rowIndex) => {
          return (
            <tr
              className={onClickRow ? styles.ClickableRow : ""}
              key={`row-${rowIndex}`}
              onClick={
                onClickRow
                  ? () => {
                      onClickRow(row.onClickRowValue ?? "");
                    }
                  : undefined
              }
            >
              {row.cells.map((cell, cellIndex) => {
                return <td key={`cell-${cellIndex}`}>{cell}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default React.memo(AdminTable);
