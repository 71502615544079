import React from "react";

import Camera, { IMAGE_FORMAT } from "~/components/Camera";
import FileInputCard from "../FileInputCard";
import { wrapFileSelect, getExtension } from "../utils";
import type { FileSelectFunction } from "../utils";

type Props = {
  title: string;
  defaultFacingMode: "environment" | "user";
  onFileSelect: FileSelectFunction;
  initialFilename?: string;
  initialUploaded: boolean;
  initialError?: string;
  preMessage?: string;
  disabledUpload: boolean;
};

const PhotoFileInput = ({
  title,
  defaultFacingMode,
  onFileSelect,
  initialFilename,
  initialUploaded,
  initialError,
  preMessage,
  disabledUpload,
}: Props) => {
  const [filename, setFilename] = React.useState(initialFilename);
  const [uploaded, setUploaded] = React.useState(initialUploaded);
  const [uploadedPercentage, setUploadedPercentage] = React.useState(0);
  const [error, setError] = React.useState(initialError);
  const [openCamera, setOpenCamera] = React.useState(false);

  const overlayPresent =
    title === "National ID back" || title === "National ID front"
      ? true
      : false;

  return (
    <>
      <FileInputCard
        title={title}
        description="Tap to open camera"
        filename={filename}
        error={error}
        onPressCard={() => setOpenCamera(true)}
        onTakePhoto={() => setOpenCamera(true)}
        uploaded={uploaded}
        uploadedPercentage={uploadedPercentage}
        uploadDisabled={disabledUpload}
      />

      {openCamera && (
        <Camera
          title={title}
          overlay={overlayPresent}
          preMessage={preMessage}
          defaultFacingMode={defaultFacingMode}
          onClose={() => setOpenCamera(false)}
          onSave={({ picture, size }) => {
            setOpenCamera(false);
            if (typeof picture === "string") {
              wrapFileSelect(
                onFileSelect,
                {
                  base64Contents: picture,
                  fileName: `${Math.random()}${getExtension(IMAGE_FORMAT)}`,
                  mimeType: IMAGE_FORMAT,
                  size,
                },
                setFilename,
                setUploaded,
                setUploadedPercentage,
                setError
              );
            } else {
              console.log(error);
              setError("Could not save photo. Please try again.");
            }
          }}
        />
      )}
    </>
  );
};

export default PhotoFileInput;
