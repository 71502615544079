import React from "react";
import { useHistory } from "react-router";
import RadioBinary from "~/components/RadioBinary";
import { H3, Text } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { IContentsType, useHomeContext } from "~/contexts/HomeProvider";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import styles from "./index.module.scss";

type Props = {
  nextPath?: string;
};

export default function AdditionalProtection({ nextPath }: Props) {
  const history = useHistory();
  const headerCtx = useHeaderContext();
  const homeCtx = useHomeContext();
  const heapCtx = useHeapContext();

  useMountEffect(() => {
    headerCtx.setState({
      totalOfSteps: 3,
      currentStep: 1,
      title: "Content Details",
    });
  });

  const handleNextClick = (wantsAdditionalProtection: boolean) => {
    try {
      if (wantsAdditionalProtection) {
        homeCtx.setState({
          allRisk: true,
        });

        heapCtx.track(HeapEventName.HOME_ADDITIONAL_PROTECTION, {
          "Additional Protection": true,
        });

        if (nextPath) {
          history.push(nextPath);
        }
      } else {
        const filteredContents: IContentsType[] | undefined = homeCtx.contents
          ? homeCtx.contents.filter((item) => {
              return !item.createdAsAllRisk;
            })
          : undefined;

        if (filteredContents) {
          filteredContents.forEach((item, index) => {
            if (item.isAllRisk && filteredContents) {
              filteredContents[index].isAllRisk = false;
            }
          });
        }

        homeCtx.setState({
          allRisk: false,
          allRiskType: undefined,
          contents: filteredContents,
        });

        heapCtx.track(HeapEventName.HOME_ADDITIONAL_PROTECTION, {
          "Additional Protection": false,
        });

        history.push("/BRB/home/contents-value"); //TODO make switchable to GUY
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <H3>Do you want additional protection for your belongings?</H3>

      <Text className={styles.SubTitle}>
        All risks insurance covers customers for all types of risk, this
        includes accidental damage or lost items, even while outside your home.
      </Text>

      <RadioBinary
        handleClick={handleNextClick}
        field="wantsAdditionalProtection"
      />
    </>
  );
}
