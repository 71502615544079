import React from "react";
import { useHistory } from "react-router";
import { useChecklistContext } from "~/contexts/ChecklistProvider";

type Props = {
  nextPath?: string;
};

export default function ChecklistRedirect({ nextPath }: Props) {
  const checklistCtx = useChecklistContext();
  const history = useHistory();

  React.useEffect(() => {
    if (checklistCtx.nextPath) {
      history.push(checklistCtx.nextPath);
    } else if (nextPath) {
      history.push(nextPath);
    } else {
      history.push("/portal"); //TODO point to 404 page?
    }
  }, [checklistCtx.nextPath, history, nextPath]);

  return <div></div>;
}
