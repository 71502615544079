import React, { ReactNode } from "react";
import classnames from "classnames";
import { Checkbox, FormValue } from "informed";
import styles from "./index.module.scss";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";

import Icon from "~/components/Icon";

type Props = {
  field: string;
  label?: string | ReactNode;
  initialValue?: FormValue;
  disabled?: boolean;
  keepState?: boolean;
  id?: string;
  className?: string;
  labelClassName?: string;
  onChange?: () => void;
};
export default (props: Props) => {
  const {
    id,
    field,
    label,
    initialValue,
    disabled,
    keepState,
    className,
    labelClassName,
    onChange,
  } = props;
  const { error, touched } = useCustomFieldState(field);

  return (
    <div className={classnames(styles.CheckboxWrapper, className)}>
      <label className={styles.Checkbox} htmlFor={field}>
        <Checkbox
          field={field}
          name={field}
          className={styles.Input}
          initialValue={initialValue}
          disabled={disabled}
          keepState={keepState}
          onChange={onChange}
          id={id || field}
        />

        <div className={styles.Checked}>
          <Icon name="CheckSquare" fill="white" />
        </div>
        {label && (
          <span className={labelClassName || styles.Label}>{label}</span>
        )}
      </label>
      {error && touched && <span className={styles.ErrorMessage}>{error}</span>}
    </div>
  );
};
