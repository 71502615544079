import React from "react";
import gql from "graphql-tag";
import { H5, Text, TextSmall } from "~/components/Typography";
import DownloadLink from "~/components/DownloadLink";
import { useLazyQuery } from "@apollo/react-hooks";
import styles from "./index.module.scss";

const GET_DOCUMENT_DOWNLOAD_LINK = gql`
  query adminGetDocumentDownloadLink(
    $documentType: String!
    $context: String
    $policyId: String
    $customerId: String
  ) {
    adminGetDocumentDownloadLink(
      documentType: $documentType
      context: $context
      policyId: $policyId
      customerId: $customerId
    )
  }
`;

type Props = {
  documentType: string;
  title: string;
  subTitle: string;
  context?: string;
  policyId?: string;
  customerId?: string;
  verificationStatus?: string;
};

const AdminDownloadDocument = ({
  documentType,
  title,
  subTitle,
  context,
  policyId,
  customerId,
  verificationStatus,
}: Props) => {
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  const [getDocumentDownloadLink, { data, error }] = useLazyQuery(
    GET_DOCUMENT_DOWNLOAD_LINK,
    {
      fetchPolicy: "no-cache",
    }
  );

  const downloadFile = () => {
    getDocumentDownloadLink({
      variables: {
        documentType,
        ...(context && {
          context,
        }),
        ...(policyId && {
          policyId,
        }),
        ...(customerId && {
          customerId,
        }),
      },
    });
  };

  React.useEffect(() => {
    if (data && data.adminGetDocumentDownloadLink) {
      window.open(data.adminGetDocumentDownloadLink, "_blank");
    }
  }, [data]);

  React.useEffect(() => {
    if (error instanceof Error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  return (
    <div className={styles.DownloadGroup}>
      {errorMessage && <Text className={styles.Error}>{errorMessage}</Text>}

      <H5>{title}</H5>

      <DownloadLink
        name={"Download"}
        onClickLink={() => {
          downloadFile();
        }}
      />

      <Text>{subTitle ? subTitle : "--"}</Text>

      {verificationStatus && (
        <TextSmall
          className={
            verificationStatus === "approved" ? styles.Success : styles.Error
          }
        >{` Verification Status: ${verificationStatus}`}</TextSmall>
      )}
    </div>
  );
};

export default AdminDownloadDocument;
