import { FormValue } from "informed";
import moment from "moment";
import {
  localDateFromUk,
  localDateToday,
  isValidUkDate,
  localDateFromIso,
  todaysDateInAst,
  getCoverageStartMaxDate,
} from "../dates";

export const MIN_ALLOWED_AGE = 20;
export const MAX_ALLOWED_AGE = 70;
const MIN_ALLOWED_DRIVER_LICENSE_AGE = 16;
const MIN_ALLOWED_ISSUE_AGE = MAX_ALLOWED_AGE - MIN_ALLOWED_DRIVER_LICENSE_AGE;
export const MAX_CLAIMS_AGE = 4;
export const MIN_DL_LENGTH = 7;
export const MAX_DL_LENGTH = 20;

// Does not accept empty string input
export const required = (message: string) => (val: FormValue | undefined) => {
  if ((val && (val as string)?.replace(/\s/g, "").length > 0) || val === 0)
    return undefined;

  return message;
};

// Accepts empty string input
export const requiredPassword = (message: string) => (
  val: FormValue | undefined
) => {
  if (val || val === 0) return undefined;

  return message;
};

export const isFullName = (message: string) => (val: FormValue | undefined) => {
  if (
    (val && (val as string).match(/[^A-Za-z'.\-\s,]/g)) ||
    (val && (val as string).match(/^ *$/))
  )
    return "A valid name can only contain letters and valid symbols";

  if (val && (val as string).match(/[\p{L}]+ [\p{L}]+/u)) {
    return undefined;
  } else if (val && !(val as string).match(/[\p{L}]+ [\p{L}]+/u)) {
    return "Please enter your last name as well";
  }

  return message;
};

export const isAddress = (message: string) => (val: FormValue | undefined) => {
  if (val && (val as string).match(/^ *$/)) {
    return message;
  }
  if (val && (val as string).match(/[^A-Za-z0-9'.\-\s,]/g))
    return "A valid address can only contain letters, numbers and valid symbols";
  if (val && (val as string).match(/.{46,}/)) {
    return "Address must be less than or equal to 45 characters long";
  }
  if (val || val === 0) return undefined;
  return message;
};

export const ageValidator = (value?: FormValue) => {
  if (value && value >= MIN_ALLOWED_AGE && value <= MAX_ALLOWED_AGE) {
    return undefined;
  }

  return `You must be between ${MIN_ALLOWED_AGE} and ${MAX_ALLOWED_AGE} years old to get Almi insurance`;
};

export const yearsDrivingExperienceValidator = (
  value: FormValue,
  age: number,
  noDataValidationMessage = "Please tell us about your driving experience",
  tooMuchExperienceValidationPrefix = "You can't have more than",
  tooMuchExperienceValidationSuffix = "years of legal driving experience"
) => {
  if (
    !isNaN(parseInt(value as string, 10)) &&
    age - parseInt(value as string, 10) >= MIN_ALLOWED_DRIVER_LICENSE_AGE
  ) {
    return undefined;
  }
  if (!value) return noDataValidationMessage;
  return `${tooMuchExperienceValidationPrefix} ${
    age - MIN_ALLOWED_DRIVER_LICENSE_AGE
  } ${tooMuchExperienceValidationSuffix}`;
};

// It's unlikely anyone will try to insure a classic car,
// if they do, we show a validation error for anything older than 1886.
// https://en.wikipedia.org/wiki/Benz_Patent-Motorwagen Year of the first production car
// This number allows us to validate a reasonable year, and catch if
// they try to search out of our searchable bounds (1886-present)
// so we can show the Contact Guardian screen.

//eslint-disable-next-line no-magic-numbers
const MAX_CAR_YEAR_VALUE = new Date().getFullYear() + 2;
export const carYear = (value?: FormValue) => {
  if (value && Number(value) <= MAX_CAR_YEAR_VALUE) {
    return undefined;
  }

  return `Please enter a valid vehicle year.`;
};

export function cleanInputDate(value: string) {
  // Remove spaces introduced by formatter
  return value.replace(/\s/g, "");
}

export function validateCoverageStartDate(value?: FormValue) {
  if (typeof value === "string" && value !== "undefined") {
    const ukDateString = cleanInputDate(value);
    const validDateString = isValidUkDate(ukDateString);
    const maxDate = getCoverageStartMaxDate();

    return validDateString &&
      moment(localDateFromUk(ukDateString)).isSameOrAfter(
        localDateFromIso(todaysDateInAst()),
        "day"
      ) &&
      moment(localDateFromUk(ukDateString)).isSameOrBefore(
        localDateFromIso(maxDate)
      )
      ? undefined
      : `Please enter a valid date between today and ${moment(
          localDateFromIso(maxDate)
        ).format("MMM D, YYYY")} (DD/MM/YYYY)`;
  }

  return undefined;
}

export function previousClaimDate(value?: FormValue) {
  if (!value) {
    return `Please enter a valid date within the last 4 years`;
  }
  if (value && typeof value === "string") {
    const ukDateString = cleanInputDate(value);
    const validDateString = isValidUkDate(ukDateString);
    return validDateString &&
      //!TODO: Edge case: this is using the date where the user is, but that could be different to the date in Barbados.
      moment(localDateFromUk(ukDateString)).isSameOrBefore(
        localDateToday(),
        "day"
      ) &&
      moment(localDateFromUk(ukDateString)).isSameOrAfter(
        moment(localDateToday()).subtract(MAX_CLAIMS_AGE, "years")
      )
      ? undefined
      : `Please enter a valid date within the last 4 years (DD/MM/YYYY)`;
  }
  return undefined;
}

export function validateDate(value?: FormValue) {
  if (typeof value === "string") {
    const ukDateString = cleanInputDate(value);
    return isValidUkDate(ukDateString)
      ? undefined
      : "Please enter a valid date (DD/MM/YYYY)";
  }
  return undefined;
}

export function validatePastOrCurrentDate(value?: FormValue) {
  if (typeof value === "string") {
    const ukDateString = cleanInputDate(value);
    if (!isValidUkDate(ukDateString)) {
      return "Please enter a valid date (DD/MM/YYYY)";
    }

    return moment(localDateFromUk(ukDateString)).isSameOrBefore(
      localDateToday(),
      "day"
    )
      ? undefined
      : "Please enter a valid date that is not later than today (DD/MM/YYYY)";
  } else if (typeof value === "undefined") {
    return "Please enter a valid date (DD/MM/YYYY)";
  }

  return undefined;
}

export function validateIssueDateOfLicense(value?: FormValue) {
  if (typeof value === "string") {
    const ukDateString = cleanInputDate(value);

    const minDate = moment(localDateToday()).subtract(
      MIN_ALLOWED_ISSUE_AGE,
      "years"
    );
    if (!moment(localDateFromUk(ukDateString)).isAfter(minDate)) {
      return `Please enter a valid date within the last ${MIN_ALLOWED_ISSUE_AGE} years (DD/MM/YYYY)`;
    } else {
      return validatePastOrCurrentDate(value);
    }
  }
  return undefined;
}
export function validateDateOfBirth(value?: FormValue) {
  const INVALID_DATE_ERROR = "Please enter a valid date";
  const INVALID_AGE_ERROR = `You must be between ${MIN_ALLOWED_AGE} and ${MAX_ALLOWED_AGE} years old to get Almi insurance`;
  if (!value) {
    return INVALID_DATE_ERROR;
  }
  if (value && typeof value === "string") {
    const ukDateString = cleanInputDate(value);
    const validDateString = isValidUkDate(ukDateString);
    const minDob = moment(localDateToday()).subtract(MAX_ALLOWED_AGE, "years");
    const maxDob = moment(localDateToday()).subtract(MIN_ALLOWED_AGE, "years");
    if (!validDateString) {
      return INVALID_DATE_ERROR;
    }
    if (!moment(localDateFromUk(ukDateString)).isBetween(minDob, maxDob)) {
      return INVALID_AGE_ERROR;
    }
  }
  return undefined;
}

export function validateEmail(value?: FormValue) {
  if (typeof value === "string") {
    const regex = /\S+@\S+\.\S+/;
    if (!regex.test(String(value).toLowerCase())) {
      return "E-mail is not valid";
    }
  }
  return undefined;
}

export function validateLength(
  minLength: number,
  maxLength: number,
  fieldName: string
) {
  return function (value?: FormValue) {
    if (!value) return `${fieldName} is required`;
    if (typeof value === "string") {
      if (value.length < minLength)
        return `${fieldName} must be at least ${minLength} characters long.`;
      if (value.length > maxLength)
        return `${fieldName} must be less than ${maxLength + 1} characters.`;
    }
    return undefined;
  };
}

export function validatePassword(value?: FormValue) {
  // At least 8 characters
  // At least 3 of the following:
  //  * Lower case letters (a-z)
  //  * Upper case letters (A-Z)
  //  * Numbers (0-9)
  //  * Special characters (ex. !@#)

  const minLength = 8;
  const maxLength = 128;
  const minCharacterTypes = 3;

  const characterTests = [
    {
      test: (value: string) => /[a-z]/.test(value),
      errorMsg: `Lowercase letter needed`,
    },
    {
      test: (value: string) => /[A-Z]/.test(value),
      errorMsg: `Uppercase letter needed`,
    },
    {
      test: (value: string) => /\d/.test(value),
      errorMsg: `Number needed`,
    },
    {
      test: (value: string) => /[^a-z0-9]/i.test(value),
      errorMsg: `Special character needed`,
    },
  ];

  if (typeof value === "string") {
    if (value.length < minLength) {
      return `Too short`;
    }
    if (value.length > maxLength) {
      return `Too long`;
    }
    const characterTestResults = characterTests.reduce(
      (result, currTest) => {
        const testPassed = currTest.test(value);
        return {
          pass: testPassed ? result.pass + 1 : result.pass,
          // Store the first error only
          error:
            !testPassed && !result.error ? currTest.errorMsg : result.error,
        };
      },
      {
        pass: 0,
        error: "",
      }
    );
    if (characterTestResults.pass < minCharacterTypes) {
      return characterTestResults.error;
    }
    return undefined;
  }
  return "Please enter a valid password.";
}

export function phoneRequired(message: string, diallingCode: string) {
  const webStarMaxLength = 15; //16 minus 1 for the + sign
  // const webStarMinLength = 3;

  const minPhoneLength = 7;
  const maxPhoneLength = webStarMaxLength - diallingCode.length;

  return function (value?: FormValue) {
    if (typeof value === "string") {
      const strippedValue = value.replace(/\s|-|_|\./g, "");

      if (!/^\d+$/.test(strippedValue)) {
        return message;
      }

      if (
        strippedValue.length >= minPhoneLength &&
        strippedValue.length <= maxPhoneLength
      ) {
        return undefined;
      }
    }

    return message;
  };
}

export function validateNumberRange(
  fieldName: string,
  min: number,
  max: number
) {
  return function (value?: FormValue) {
    if (!value) return `${fieldName} is required`;
    if (
      typeof value === "string" &&
      parseInt(value, 10) > min &&
      parseInt(value, 10) < max
    ) {
      return undefined;
    }
    return `${fieldName} must be between ${min} and ${max}`;
  };
}

export function validateNotZero(value?: FormValue) {
  const val = Number((value as string).replace(/[^\d]/g, ""));
  if (!value) return "Please enter a value.";
  if (typeof value === "string" && val === 0) return "Value cannot be 0";
  if (typeof value === "number" && value === 0) return "Value cannot be 0";
  return undefined;
}
