import React from "react";
import classnames from "classnames";
import { H6, H5, TextSmall } from "~/components/Typography";
import Icon from "~/components/Icon";

import styles from "./index.module.scss";

export type DetailData = {
  text: React.ReactNode;
  youPay?: string;
  coveredAmount?: string;
};

type Props = {
  body: DetailData[];
  isCovered?: boolean;
  className?: string;
};

const isDeducatableIndex = 3;

export default function PolicyDetailsCard(props: Props) {
  const { body, isCovered = true, className } = props;

  const classes = classnames(styles.PolicyDetailsCardWrapper, className, {
    [styles.NotCovered]: !isCovered,
  });

  return (
    <div className={classes} role="group" aria-label="coverage details">
      <div className={styles.Heading}>
        <Icon
          width={25}
          height={25}
          name={isCovered ? "Plus" : "Minus"}
          fill="white"
          backgroundColor={isCovered ? "#31AD63" : "#D92525"}
          className={styles.Icon}
        />
        <H5 component="h4">What’s {!isCovered && "Not "}Covered</H5>
      </div>
      {body.map(({ text, coveredAmount, youPay }, index) => (
        <div key={index} className={styles.Body}>
          <div className={styles.Text}>
            {typeof text === "string" ? <TextSmall>{text}</TextSmall> : text}
          </div>
          {coveredAmount && (
            <div className={styles.Info}>
              <H6 className={styles.Label} component="h5">
                Covered Amount
              </H6>
              <TextSmall>{coveredAmount}</TextSmall>
            </div>
          )}
          {youPay && !isCovered && (
            <div className={styles.Info}>
              <H6 className={styles.Label} component="h5">
                You Pay
              </H6>
              <TextSmall>{youPay}</TextSmall>
            </div>
          )}
          {youPay && index === isDeducatableIndex && (
            <>
              <div className={styles.Info}>
                <H6 className={styles.Label} component="h5">
                  You Pay
                </H6>
                <TextSmall>{youPay}</TextSmall>
              </div>
              <div className={styles.Info}>
                <TextSmall className={styles.Deductable}>
                  <TextSmall className={styles.Spacing}>(</TextSmall>
                  *The amount may vary based on factors like occupation, driver
                  age and experience.)
                </TextSmall>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
}
