import React from "react";
import gql from "graphql-tag";
import { ToolProps } from "../..";
import { useFieldState } from "informed";
import { useMutation } from "@apollo/react-hooks";
import cleanNumberInput from "~/helpers/formatters/cleanNumberInput";
import hasErrorCode from "~/helpers/hasErrorCode";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import styles from "./index.module.scss";

enum Field {
  Amount = "AdminManualSinglePayment-Amount",
  Passcode = "AdminManualSinglePayment-Passcode",
}

const REGISTER_PHYSICAL_PAYMENT = gql`
  mutation adminRegisterPhysicalPayment(
    $policyId: String!
    $amount: Float!
    $passcode: String!
  ) {
    adminRegisterPhysicalPayment(
      policyId: $policyId
      amount: $amount
      passcode: $passcode
    )
  }
`;

const AdminRegisterPhysicalPayment = ({ identifier, onClose }: ToolProps) => {
  const amountState = useFieldState(Field.Amount);
  const passcodeState = useFieldState(Field.Passcode);

  const [processing, setProcessing] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  const [registerPhysicalPayment] = useMutation(REGISTER_PHYSICAL_PAYMENT);

  const handleSubmit = async () => {
    setProcessing(true);

    try {
      const response = await registerPhysicalPayment({
        variables: {
          policyId: identifier,
          amount: cleanNumberInput(amountState.value, true),
          passcode: String(passcodeState.value),
        },
      });

      setProcessing(false);
      const data = response?.data?.adminRegisterPhysicalPayment;

      if (data) {
        onClose({
          manuallyClosed: false,
          success: true,
          shouldReload: true,
          message: `The physical payment amount of $${amountState.value} has been added.`,
        });
      } else {
        setErrorMessage("Error: No payment registered.");
      }
    } catch (error) {
      if (error instanceof Error) {
        if (hasErrorCode(error, "NOT_ADMIN")) {
          onClose({
            manuallyClosed: false,
            success: false,
            errorCode: "NOT_ADMIN",
          });
        }

        setErrorMessage(error.message);
      }
      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      {errorMessage && <div className={styles.Error}>{errorMessage}</div>}

      <>
        <TextInput
          type="text"
          label="Register Payment Amount"
          placeholder="0.00"
          inputMode="decimal"
          field={Field.Amount}
        />

        <TextInput
          type="text"
          label="Passcode"
          placeholder="Passcode"
          field={Field.Passcode}
        />

        <Button
          disabled={!amountState.value || !passcodeState.value || processing}
          onClick={handleSubmit}
        >
          {processing ? "Processing..." : "Process"}
        </Button>
      </>
    </div>
  );
};

export default AdminRegisterPhysicalPayment;
