import { useApolloClient } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import HeapJS from "reactjs-heap";

const trackingId = process.env.REACT_APP_HEAP_TRACKING_ID;

if (trackingId) {
  HeapJS.initialize(trackingId);
}

const FETCH_PROFILE_ID = gql`
  query profileIdForEmail($email: String!) {
    profileIdForEmail(email: $email)
  }
`;

type Props = {
  children?: React.ReactNode;
};

export type HeapUserProperties = {
  "Almi ID"?: string;
  "Country of Residence"?: string;
  "Date of Birth"?: string;
  "Driving Experience"?: number;
  Occupation?: string;
  Association?: string;
};

export type HeapEventProperties = {
  // Motor Onboarding
  "Country of Residence"?: string;
  Age?: number;
  Experience?: number;
  "Vehicle Type"?: string;
  "Ownership Type"?: string;
  Year?: number;
  Make?: string;
  Model?: string;
  "Left-hand Drive"?: string;
  "Engine Size"?: string;
  "Engine Modifications"?: string;
  "Vehicle Value"?: number;
  "Coverage Date"?: string;
  "Personal Use"?: string;
  "Number of Additional Drivers"?: number;
  Claims?: boolean;
  Value?: number;
  Date?: string;
  "At Fault"?: string;
  Finalized?: string;
  "Shift Work"?: string;
  Occupation?: string;
  Association?: string;
  "Policy ID"?: string;
  "Plan Type"?: string;
  "Payment Type"?: string;
  "ID/Document Type"?: string;
  "Error Code"?: string;
  "Error Message"?: string;
  // Home Onboarding
  "Home Address"?: string;
  "Building Type"?: string;
  "Construction Type"?: string;
  "Coastal Property"?: string;
  "Replacement Value"?: number;
  "Number of Contents"?: number;
  Content?: string;
  "Purchase Date"?: string;
  "Additional Protection"?: boolean;
  "Risk Type"?: string;
  "Existing Motor Policy"?: boolean;
  // Home Checklist
  Storeys?: string;
  Built?: string;
  "Has Mortgage"?: boolean;
  "Mortgage Lender"?: string;
  "Surrounding Commercial"?: boolean;
  "No Inhabitants for 60 Days"?: boolean;
  "Reclaimed Land"?: boolean;
  "Levelled Land"?: boolean;
  "Other Insurance"?: boolean;
  "Insurance History"?: boolean;
};

export type HeapPersistentEventProperties = {
  "App Version"?: string;
};

export enum HeapEventName {
  // Motor Onboarding
  MOTOR_COUNTRY_OF_RESIDENCE = "Basic Info - Submit - Country of Residence",
  MOTOR_AGE = "Basic Info - Submit - Age",
  MOTOR_DRIVING_EXPERIENCE = "Basic Info - Submit - Driving Experience",
  MOTOR_VEHICLE_TYPE = "Vehicle Info - Submit - Vehicle Type",
  MOTOR_OWNERSHIP_TYPE = "Vehicle Info - Submit - Ownership Type",
  MOTOR_YEAR = "Vehicle Info - Submit - Year",
  MOTOR_MAKE = "Vehicle Info - Submit - Make",
  MOTOR_MODEL = "Vehicle Info - Submit - Model",
  MOTOR_LEFT_HAND_DRIVE = "Vehicle Info - Submit - Left-hand Drive",
  MOTOR_ENGINE_SIZE = "Vehicle Info - Submit - Engine Size",
  MOTOR_ENGINE_MODIFICATIONS = "Vehicle Info - Submit - Engine Modifications",
  MOTOR_VEHICLE_VALUE = "Vehicle Info - Submit - Vehicle Value",
  MOTOR_COVERAGE_DATE = "Coverage Details - Submit - Coverage Date",
  MOTOR_PERSONAL_USE = "Coverage Details - Submit - Personal Use",
  MOTOR_ADDITIONAL_DRIVERS = "Coverage Details - Submit - Additional Drivers",
  MOTOR_DRIVER_DETAILS = "Coverage Details - Submit - Driver Details",
  MOTOR_PAST_CLAIMS = "Coverage Details - Submit - Past Claims",
  MOTOR_CLAIM_DETAILS = "Coverage Details - Submit - Claim Details",
  MOTOR_LAST_ACCIDENT = "Coverage Details - Submit - Last Accident",
  MOTOR_SHIFT_WORK = "Occupation - Submit - Shift Work",
  MOTOR_OCCUPATION = "Occupation - Submit - Occupation",
  MOTOR_ASSOCIATION = "Occupation - Submit - Association",
  MOTOR_EMAIL_ADDRESS = "Motor Quote - Submit - Email Address",
  MOTOR_PLAN_SELECTION = "Motor Quote - Submit - Plan Selection",
  MOTOR_VIEW_STUDENT = "Motor Quote - View - Student",
  MOTOR_SUBMIT_STUDENT = "Occupation - Submit - Student",
  // Home Onboarding
  HOME_OCCUPATION = "Occupation - Submit - Occupation",
  HOME_OWNERSHIP_TYPE = "Home Info - Submit - Ownership Type",
  HOME_ADDRESS = "Home Info - Submit - Home Address",
  HOME_BUILDING_TYPE = "Home Info - Submit - Building Type",
  HOME_CONSTRUCTION_TYPE = "Home Info - Submit - Construction Type",
  HOME_COASTAL_PROPERTY = "Home Info - Submit - Coastal Property",
  HOME_REPLACEMENT_VALUE = "Coverage Details - Submit - Replacement Value",
  HOME_CONTENTS_ITEMS = "Coverage Details - Submit - Contents Item",
  HOME_CONTENTS_ITEMS_DETAILS_ADD = "Coverage Details - Submit - Contents Item Details Add",
  HOME_CONTENTS_ITEMS_DETAILS_REMOVE = "Coverage Details - Submit - Contents Item Details Remove",
  HOME_ADDITIONAL_PROTECTION = "Coverage Details - Submit - Additional Protection",
  HOME_ALL_RISK_ITEMS = "Coverage Details - Submit - All Risk Items",
  HOME_ALL_RISK_TYPE = "Coverage Details - Submit - All Risk Type",
  HOME_CONTENTS_VALUE = "Coverage Details - Submit - Contents Value",
  HOME_COVERAGE_DATE = "Coverage Details - Submit - Coverage Date",
  HOME_EXISTING_MOTOR_POLICY = "Home Quote - Submit - Existing Motor Policy",
  HOME_EMAIL_ADDRESS = "Home Quote - Submit - Email Address",
  HOME_COVERAGE_PLAN = "Home Quote - Submit - Coverage Plan",
  // Onboarding Create Account
  ONBOARDING_CREATE_ACCOUNT = "Create Account - Click - Intro Continue",
  ONBOARDING_NAME = "Create Account - Submit - Name",
  ONBOARDING_PHONE_NUMBER = "Create Account - Submit - Phone Number",
  ONBOARDING_PASSWORD = "Create Account - Submit - Password",
  ONBOARDING_PASSWORD_VISIBILITY = "Create Account - Toggle - Password Visibility",
  ONBOARDING_CREATE_ACCOUNT_SUCCESS = "Create Account - Click - Success Continue",
  ONBOARDING_DOCUMENT_UPLOAD_SCREEN = "Identity - View - Document Upload Screen",
  ONBOARDING_UPLOAD_SELFIE = "Identity - Upload - Selfie",
  ONBOARDING_UPLOAD_DOCUMENT = "Identity - Upload - IDs/Documents",
  ONBOARDING_IDENTITY_SUBMIT = "Identity - Submit",
  ONBOARDING_ID_ERROR_SCREEN = "Identity - View - ID Error Screen",
  ONBOARDING_INFO_REVIEW_CONTINUE = "Identity - Click - Info Review Continue",
  ONBOARDING_PROPOSAL_SUCCESS_SCREEN = "Proposal - View - Proposal Success Screen",
  ONBOARDING_PROPOSAL_SUCCESS_CONTINUE = "Proposal - Click - Proposal Success Continue",
  ONBOARDING_REVIEW_COMPLETE = "Payment - Click - Review Complete",
  ONBOARDING_PAYMENT_INFORMATION = "Payment - Submit - Payment Information",
  ONBOARDING_PAYMENT_ERROR_SCREEN = "Payment - View - Payment Error Screen",
  ONBOARDING_PAYMENT_SUCCESS_CONTINUE = "Payment - Click - Payment Success Continue",
  ONBOARDING_COVERAGE_SUCCESS_CONTINUE = "Payment - Click - Coverage Success Continue",
  // Motor Checklist
  // TODO: Add heap tracking to motor checklist screens
  MOTOR_CHECKLIST_STARTED_SCREEN = "Motor Checklist - View - Checklist Started Screen",
  MOTOR_CHECKLIST_STARTED = "Motor Checklist - Click - Checklist Started",
  MOTOR_DECLARATION_SCREEN = "Motor Checklist - View - Motor Declaration Screen",
  // Home Checklist
  HOME_CHECKLIST_STARTED = "Home Checklist - Click - Checklist Started",
  HOME_CHECKLIST_STARTED_SCREEN = "Home Checklist - View - Checklist Started Screen",
  HOME_STOREYS = "Home Info - Submit - Storeys",
  HOME_STOREYS_SCREEN = "Home Info - View - Storeys Screen",
  HOME_BUILT = "Home Info - Submit- Home Built",
  HOME_BUILT_SCREEN = "Home Info - View - Home Built Screen",
  HOME_MORTGAGE = "Home Info - Submit - Mortgage",
  HOME_MORTGAGE_SCREEN = "Home Info - View - Mortgage Screen",
  HOME_SURROUNDING_COMMERCIAL = "Home Info - Submit - Surrounding Commercial",
  HOME_SURROUNDING_COMMERCIAL_SCREEN = "Home Info - View - Surrounding Commercial Screen",
  HOME_NO_INHABITANTS = "Home Info - Submit - No Inhabitants",
  HOME_NO_INHABITANTS_SCREEN = "Home Info - View - No Inhabitants Screen",
  HOME_RECLAIMED_LAND = "Home Info - Submit - Reclaimed Land",
  HOME_RECLAIMED_LAND_SCREEN = "Home Info - View - Reclaimed Land Screen",
  HOME_LEVELLED_LAND = "Home Info - Submit - Levelled Land",
  HOME_LEVELLED_LAND_SCREEN = "Home Info - View - Levelled Land Screen",
  HOME_OTHER_INSURANCE = "Home Info - Submit - Other Insurance",
  HOME_OTHER_INSURANCE_SCREEN = "Home Info - View - Other Insurance Screen",
  HOME_INSURANCE_HISTORY = "Home Info - Submit - Insurance History",
  HOME_INSURANCE_HISTORY_SCREEN = "Home Info - View - Insurance History Screen",
  HOME_FINAL_DOCUMENT_UPLOAD = "Home Checklist - Submit - Final Document Upload",
  HOME_FINAL_DOCUMENT_UPLOAD_SCREEN = "Home Checklist - View - Final Document Upload Screen",
  HOME_DECLARATION_SCREEN = "Home Checklist - View - Home Declaration Screen",
  // General Checklist
  CHECKLIST_DECLARATION = "Checklist - Click - Checklist Declaration True",
  CHECKLIST_SUBMITTED = "Checklist - Click - Checklist Submitted Continue",
  CHECKLIST_SUBMITTED_SCREEN = "Checklist - View - Checklist Submitted Screen",
}

type HeapContextType = {
  initialized: boolean;
  getIdentity: () => string | undefined;
  checkForProfileId: (email: string) => void;
  identify: (profileId: string) => void;
  track: (event: HeapEventName, properties: HeapEventProperties) => void;
  addUserProperties: (properties: HeapUserProperties) => void;
  resetIdentity: () => void;
};

const defaultError = "Heap context has not been initialized.";
const defaultWarn = "Heap is not initialized.";

const getHeapReference = () => {
  const heapWindow = (window as unknown) as {
    heap: {
      identify: (profileId: string) => void;
      track: (event: string, properties: HeapEventProperties) => void;
      addUserProperties: (properties: HeapUserProperties) => void;
      addEventProperties: (properties: HeapPersistentEventProperties) => void;
      resetIdentity: () => void;
      identity: string | undefined;
    };
  };

  return heapWindow?.heap;
};

const initialState: HeapContextType = {
  initialized: false,
  getIdentity: () => {
    throw new Error(defaultError);
  },
  checkForProfileId: () => {
    throw new Error(defaultError);
  },
  identify: () => {
    throw new Error(defaultError);
  },
  track: () => {
    throw new Error(defaultError);
  },
  addUserProperties: () => {
    throw new Error(defaultError);
  },
  resetIdentity: () => {
    throw new Error(defaultError);
  },
};

const HeapContext = React.createContext(initialState);
export const useHeapContext = () => React.useContext(HeapContext);

export default function HeapProvider({ children }: Props) {
  const apolloClient = useApolloClient();
  const [state, setState] = React.useState<HeapContextType>(initialState);

  useMountEffect(() => {
    const heapReference = getHeapReference();
    if (heapReference) {
      setState({
        ...state,
        initialized: true,
      });
      heapReference.addEventProperties({
        "App Version": process.env.REACT_APP_VERSION,
      });
    } else {
      console.warn("HeapContext useMountEffect: ", defaultWarn);
    }
  });

  const resetIdentity = () => {
    const heapReference = getHeapReference();

    if (heapReference) {
      heapReference.resetIdentity();
    } else {
      console.warn("HeapContext resetIdentity: ", defaultWarn);
    }
  };

  const getIdentity = () => {
    const heapReference = getHeapReference();
    return heapReference ? heapReference.identity : undefined;
  };

  const identify = (profileId: string) => {
    const heapReference = getHeapReference();

    if (heapReference) {
      heapReference.identify(profileId);

      heapReference.addUserProperties({
        "Almi ID": profileId,
      });
    } else {
      console.warn("HeapContext identity: ", defaultWarn);
    }
  };

  const checkForProfileId = async (email: string) => {
    const heapReference = getHeapReference();

    if (heapReference) {
      try {
        const { data } = await apolloClient.query({
          query: FETCH_PROFILE_ID,
          variables: {
            email,
          },
          fetchPolicy: "no-cache",
        });

        if (data && data.profileIdForEmail) {
          identify(data.profileIdForEmail);
        }
      } catch (error) {
        console.warn("HeapContext checkForProfileId: ", error);
      }
    } else {
      console.warn("HeapContext checkForProfileId: ", defaultWarn);
    }
  };

  const track = (event: HeapEventName, properties: HeapEventProperties) => {
    const heapReference = getHeapReference();

    if (heapReference) {
      heapReference.track(event, properties);
    } else {
      console.warn("HeapContext track: ", defaultWarn);
    }
  };

  const addUserProperties = (properties: HeapUserProperties) => {
    const heapReference = getHeapReference();

    if (heapReference) {
      heapReference.addUserProperties(properties);
    } else {
      console.warn("HeapContext addUserProperties: ", defaultWarn);
    }
  };

  return (
    <HeapContext.Provider
      value={{
        ...state,
        checkForProfileId,
        identify,
        getIdentity,
        track,
        addUserProperties,
        resetIdentity,
      }}
    >
      {children}
    </HeapContext.Provider>
  );
}
