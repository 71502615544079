import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import styles from "./index.module.scss";
import Loading from "~/components/Loading";
import AppError from "~/helpers/AppError";
import { useMotorContext } from "~/contexts/MotorProvider";
import { useHomeContext, IHomeDataInput } from "~/contexts/HomeProvider";
import { useOnboardContext } from "~/contexts/OnboardProvider";
import { useUserContext } from "~/contexts/UserProvider";
import createResumeUrl from "~/helpers/createResumeUrl";
import hasErrorCode from "~/helpers/hasErrorCode";
import { useHeapContext } from "~/contexts/HeapProvider";

import { GeneralPolicyType } from "../../types";

const GET_POLICY = gql`
  query($getGeneralPolicyInput: String!) {
    getGeneralPolicy(input: $getGeneralPolicyInput) {
      branch
      policyObject
      typeOfCover
      coverageStart
      holderId
      holder {
        name
        licenseNumber
        webstarId
        occupation
        association
        termsAndConditions
        age
        yearsDriving
        email
      }
      status
      quote {
        typeOfCover
        basePremiumValue
        netPremiumValue
        taxValue
        totalValue
        coverage {
          peril
          coveredValue
          excessValue
          isVariableExcess
        }
        valueByNumberOfInstallments {
          twelve
        }
        expirationDate
      }
      generatedQuotes {
        typeOfCover
        basePremiumValue
        netPremiumValue
        taxValue
        totalValue
        coverage {
          peril
          coveredValue
          excessValue
          isVariableExcess
        }
        valueByNumberOfInstallments {
          twelve
        }
      }
      externalId
      isPaymentRecurring
      previousClaims {
        date
        amount
        atFault
        isFinalized
      }
      createdAt
      motorProposal {
        mainDriver {
          age
          yearsDriving
          occupation
          association
          licenseNumber
        }
        vehicle {
          year
          make
          model
          value
          engineSize
          type
          isSportsCar
          isEngineModified
          isLeftSide
          isVehicleOwned
        }
        isForBusiness
        yearsWithoutClaims
        isShiftWorker
        drivers {
          name
          age
          yearsDriving
        }
        previousClaims {
          date
          amount
          atFault
          isFinalized
        }
      }
      homeProposal {
        property {
          buildingType
          address
          isCommercial
          constructionType
          isFloodRisk
          isCoastal
          isHighDensity
          isGoodState
          value
        }
        contentsValue
        contents {
          name
          value
          purchaseDate
          identificationNumber
        }
        allRiskCoverage
        isAllRiskWorldwide
        isExistingMotorCustomer
      }
    }
  }
`;

const ResumeQuote = () => {
  const motorCtx = useMotorContext();
  const userCtx = useUserContext();
  const onboardCtx = useOnboardContext();
  const heapCtx = useHeapContext();
  const homeCtx = useHomeContext();
  const params: { [key: string]: string | undefined } = useParams();
  const history = useHistory();

  const [pageError, setPageError] = useState<{
    subtitle: string;
    options?: {
      title?: string;
      mainButton: { text: string; onClick: () => void };
    };
  } | null>();

  const { error, data } = useQuery(GET_POLICY, {
    variables: {
      getGeneralPolicyInput: params.quote,
    },
    skip: !params.quote,
  });

  useEffect(() => {
    if (data) {
      const {
        getGeneralPolicy,
      }: { getGeneralPolicy: GeneralPolicyType } = data;

      if (getGeneralPolicy.holderId) {
        heapCtx.identify(getGeneralPolicy.holderId);
      }

      if (getGeneralPolicy.holder && getGeneralPolicy.holder.email) {
        userCtx.setState({
          email: getGeneralPolicy.holder?.email,
          termsAndConditions: getGeneralPolicy.holder.termsAndConditions,
        });
      }

      onboardCtx.setState({
        paymentIsRecurring: getGeneralPolicy.isPaymentRecurring,
      });

      switch (getGeneralPolicy.policyObject) {
        case "auto":
          if (getGeneralPolicy.motorProposal) {
            motorCtx.vehicleInfo.setItem(
              "make",
              getGeneralPolicy.motorProposal.vehicle.make
            );
            motorCtx.vehicleInfo.setItem(
              "model",
              getGeneralPolicy.motorProposal.vehicle.model
            );
            motorCtx.vehicleInfo.setItem(
              "value",
              getGeneralPolicy.motorProposal.vehicle.value
            );
            motorCtx.vehicleInfo.setItem(
              "year",
              getGeneralPolicy.motorProposal.vehicle.year
            );
            motorCtx.vehicleInfo.setItem(
              "type",
              getGeneralPolicy.motorProposal.vehicle.type
            );
            motorCtx.vehicleInfo.setItem(
              "isSportsCar",
              getGeneralPolicy.motorProposal.vehicle.isSportsCar
            );
            motorCtx.vehicleInfo.setItem(
              "isEngineModified",
              getGeneralPolicy.motorProposal.vehicle.isEngineModified
                ? "yes"
                : "no"
            );
            motorCtx.vehicleInfo.setItem(
              "isLeftSide",
              getGeneralPolicy.motorProposal.vehicle.isLeftSide ? "yes" : "no"
            );
            motorCtx.vehicleInfo.setItem(
              "isVehicleOwned",
              getGeneralPolicy.motorProposal.vehicle.isVehicleOwned
                ? "yes"
                : "no"
            );
            motorCtx.vehicleInfo.setItem(
              "engineSize",
              getGeneralPolicy.motorProposal.vehicle.engineSize
            );
            if (getGeneralPolicy.motorProposal.previousClaims) {
              motorCtx.savingsInfo.setItem(
                "previousClaims",
                getGeneralPolicy.motorProposal.previousClaims.map((claim) => {
                  return {
                    amount: claim.amount.toString(),
                    atFault: claim.atFault,
                    date: claim.date,
                    isFinalized: claim.isFinalized,
                  };
                })
              );
            }
            motorCtx.policyInfo.setItem(
              "isForBusiness",
              getGeneralPolicy.motorProposal.isForBusiness ? "yes" : "no"
            );
            motorCtx.policyInfo.setItem(
              "isShiftWorker",
              getGeneralPolicy.motorProposal.isShiftWorker ? "yes" : "no"
            );

            motorCtx.policyInfo.setItem(
              "yearsDriving",
              getGeneralPolicy.motorProposal.mainDriver.yearsDriving
            );
            motorCtx.policyInfo.setItem(
              "age",
              getGeneralPolicy.motorProposal.mainDriver.age
            );
            motorCtx.policyInfo.setItem(
              "yearsWithoutClaims",
              getGeneralPolicy.motorProposal.yearsWithoutClaims
            );
            if (getGeneralPolicy.motorProposal.drivers) {
              motorCtx.policyInfo.setItem(
                "drivers",
                getGeneralPolicy.motorProposal.drivers.map((driver) => {
                  return {
                    name: driver.name,
                    age: driver.age.toString(),
                    yearsDriving: driver.yearsDriving.toString(),
                  };
                })
              );
            }
          }
          if (getGeneralPolicy.holder) {
            getGeneralPolicy.holder.occupation &&
              motorCtx.savingsInfo.setItem(
                "occupation",
                getGeneralPolicy.holder.occupation
              );

            getGeneralPolicy.holder.association &&
              motorCtx.savingsInfo.setItem(
                "association",
                getGeneralPolicy.holder.association
              );
          }

          // policy
          motorCtx.policyInfo.setItem("id", getGeneralPolicy.externalId);

          motorCtx.policyInfo.setItem(
            "coverageStart",
            getGeneralPolicy.coverageStart
          );

          break;
        case "home":
          if (getGeneralPolicy.homeProposal) {
            homeCtx.setState({
              // TODO change to get value from query when Almi decides to support renters.
              isOwner: true,
              address: getGeneralPolicy.homeProposal.property.address,
              buildingType: getGeneralPolicy.homeProposal.property.buildingType,
              commercialActivities:
                getGeneralPolicy.homeProposal.property.isCommercial,
              constructionType: getGeneralPolicy.homeProposal.property
                .constructionType as IHomeDataInput["constructionType"],
              isFloodZone: getGeneralPolicy.homeProposal.property.isFloodRisk,
              isCoastal: getGeneralPolicy.homeProposal.property.isCoastal,
              isHighDensity:
                getGeneralPolicy.homeProposal.property.isHighDensity,
              isGoodState: getGeneralPolicy.homeProposal.property.isGoodState,
              replacementValue: getGeneralPolicy.homeProposal.property.value,
              contentsValue: getGeneralPolicy.homeProposal.contentsValue,
              contents: getGeneralPolicy.homeProposal.contents,
              allRisk: getGeneralPolicy.homeProposal.allRiskCoverage,
              allRiskType: getGeneralPolicy.homeProposal.isAllRiskWorldwide
                ? "worldwide"
                : "local",
              hasExistingMotorPolicy:
                getGeneralPolicy.homeProposal.isExistingMotorCustomer,
              coverageStart: getGeneralPolicy.coverageStart,
              policyId: getGeneralPolicy.externalId,
            });
          }
          break;
      }

      if (
        getGeneralPolicy.quote &&
        new Date() > new Date(getGeneralPolicy.quote.expirationDate)
      ) {
        return setPageError({
          subtitle:
            "Unfortunately you did not finish your quote in time. The good news is, you can always start a new one!",
          options: {
            title: "This quote has expired",
            mainButton: {
              text: "Get a new quote",
              onClick: () => history.push("/"),
            },
          },
        });
      }

      const redirectUrl = createResumeUrl(
        getGeneralPolicy,
        history.location.search
      );
      history.replace(redirectUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (pageError) {
    throw new AppError(pageError.subtitle, pageError.options);
  }

  if (error) {
    if (
      hasErrorCode(error, "LOGIN_REQUIRED") ||
      hasErrorCode(error, "NOT_AUTHORIZED")
    ) {
      history.replace(`/portal?redirectAfterLogin=/resume/${params.quote}`);
    }

    setPageError({
      subtitle: `Unable to resume policy quote. ${
        error.message || "Can't find quote"
      }`,
      options: {
        mainButton: {
          text: "Start a new quote",
          onClick: () => history.push("/"),
        },
      },
    });
  }

  return (
    <div className={styles.LoadingWrapper}>
      <Loading />
    </div>
  );
};

export default ResumeQuote;
