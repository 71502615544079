import React from "react";
import { useHistory } from "react-router-dom";
import LoadingWrapper from "~/components/LoadingWrapper";
import RadioBinary from "~/components/RadioBinary";
import { H3, Text } from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import styles from "./index.module.scss";

type Props = {
  nextPath?: string;
};

export default function VehicleFinanced({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const [saving, setSaving] = React.useState<boolean>(false);

  useMountEffect(() => {
    headerCtx.setState({
      totalOfSteps: 6,
      currentStep: 3,
      title: "Vehicle Financed",
    });
  });

  const handleNextClick = async (isFinanced: boolean) => {
    try {
      if (nextPath) {
        setSaving(true);

        await checklistCtx.saveChecklist({
          policyId: checklistCtx.policyId,
          motor: {
            ...checklistCtx.motor,
            isFinanced,
          },
          nextPath,
        });

        history.push(nextPath);
      }
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <H3>Is your vehicle financed?</H3>

          <Text className={styles.SubTitle}>
            If yes, you will need to upload a copy of your Assignment Letter
            later. You can contact your financial institution to provide you
            with a copy.
          </Text>

          <RadioBinary
            handleClick={handleNextClick}
            field="vehicleIsFinanced"
          ></RadioBinary>
        </>
      )}
    </>
  );
}
