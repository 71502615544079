import React from "react";
import { H3, Text } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useHistory } from "react-router-dom";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import RadioBinary from "~/components/RadioBinary";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import styles from "./index.module.scss";
import LoadingWrapper from "~/components/LoadingWrapper";

type Props = {
  nextPath?: string;
};

export default function ProsecutionsPastFiveYears({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const [saving, setSaving] = React.useState<boolean>(false);

  useMountEffect(() => {
    headerCtx.setState({
      totalOfSteps: 6,
      currentStep: 3,
      title: "Driver's History",
    });
  });

  const handleNextClick = async (wasProsecuted: boolean) => {
    try {
      if (nextPath) {
        setSaving(true);

        await checklistCtx.saveChecklist({
          policyId: checklistCtx.policyId,
          motor: {
            ...checklistCtx.motor,
            prosecutionsPastFiveYears: wasProsecuted,
          },
          nextPath,
        });

        history.push(nextPath);
      }
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <Text className={styles.HideOnMobile}>Thanks!</Text>

          <H3>
            Have you, or anyone driving, been convicted of any motoring
            offences, subjected to any police enquiries or Court Prosecutions in
            the past five years?
          </H3>

          <RadioBinary
            handleClick={handleNextClick}
            field="hasProsecutions"
          ></RadioBinary>
        </>
      )}
    </>
  );
}
