import React from "react";
// eslint-disable-next-line import/no-named-as-default-member
import DocumentUpload, {
  DocumentWrapper,
} from "~/screens/Identification/DocumentUpload";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useUserContext } from "~/contexts/UserProvider";
import { DOCUMENT_TYPES, FILE_TYPES } from "~/helpers/constants";
import { InsuranceType } from "../../../types";

type Props = {
  nextPath?: string;
  insuranceType: InsuranceType;
};

const acceptImages = [
  FILE_TYPES.jpg.accept,
  FILE_TYPES.jpeg.accept,
  FILE_TYPES.png.accept,
].join(", ");

const acceptImagesAndPDFs = [acceptImages, FILE_TYPES.pdf.accept].join(", ");

const validExtensions = [
  FILE_TYPES.jpg.extension,
  FILE_TYPES.jpeg.extension,
  FILE_TYPES.png.extension,
  FILE_TYPES.pdf.extension,
];

const documentWrappersList: DocumentWrapper[] = [
  {
    id: "RequiredDocuments-Selfie",
    name: "Selfie",
    shortDescription: "Tap to add selfie",
    description: "Take a photo of yourself so we can verify your identity",
    documentParts: [
      {
        name: "Face",
        accept: acceptImages,
        context: "face",
        documentType: DOCUMENT_TYPES.face,
        validExtensions,
      },
    ],
    verify: true,
  },
  {
    id: "RequiredDocuments-NationalIDOrPassport",
    name: "National ID or Passport",
    description: "Upload at least one of the following:",
    shortDescription: "Tap to add documents",
    minNumberOfOptionalDocs: 1,
    optionalDocuments: [
      {
        id: "RequiredDocuments-NationalIDOrPassport-NationalID",
        name: "National ID",
        shortDescription: "Tap to add document",
        description: "Take a photo of your National ID card.",
        documentParts: [
          {
            name: "National ID front",
            accept: acceptImages,
            context: "document-front",
            documentType: DOCUMENT_TYPES.idCard,
            validExtensions,
          },
          {
            name: "National ID back",
            accept: acceptImages,
            context: "document-back",
            documentType: DOCUMENT_TYPES.idCard,
            validExtensions,
          },
        ],
        verify: true,
      },
      {
        id: "RequiredDocuments-NationalIDOrPassport-Passport",
        name: "Passport",
        shortDescription: "Tap to add document",
        description: "Upload a photo of your Passport.",
        documentParts: [
          {
            name: "Passport front",
            accept: acceptImages,
            context: "document-front",
            documentType: DOCUMENT_TYPES.passport,
            validExtensions,
          },
        ],
        verify: true,
      },
    ],
  },
  {
    id: "RequiredDocuments-ProofOfAddress",
    name: "Proof of address",
    shortDescription: "Tap to add document",
    description:
      "Upload a photo of a document with your address (eg. a utility bill or bank statement)",
    documentParts: [
      {
        name: "Proof of address",
        accept: acceptImagesAndPDFs,
        documentType: DOCUMENT_TYPES.proofAddress,
        validExtensions,
      },
    ],
  },
];

const RequiredDocuments = ({ nextPath, insuranceType }: Props) => {
  const homeCtx = useHomeContext();
  const userCtx = useUserContext();

  return (
    <DocumentUpload
      documentWrappersList={documentWrappersList}
      policyId={homeCtx.policyId as string}
      email={userCtx.email as string}
      nextPath={nextPath}
      insuranceType={insuranceType}
    />
  );
};

export default RequiredDocuments;
