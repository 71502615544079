import React, { ReactNode, createContext, useContext, useState } from "react";
import { POLICY_STATUS } from "~/helpers/constants";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import * as storage from "~/helpers/storage";
import { PolicyStatus } from "../../types";

export type IContentsType = {
  name: string;
  value: number;
  purchaseDate: string;
  identificationNumber?: string;
  isAllRisk: boolean;
  createdAsAllRisk: boolean;
};

export type IHomeDataInput = {
  id?: string;
  address?: string;
  isOwner?: boolean;
  buildingType?: string;
  isOccupiedByYou?: boolean;
  commercialActivities?: boolean;
  constructionType?: "superior" | "class-a" | "class-b" | "class-c";
  isFloodZone?: boolean;
  isCoastal?: boolean;
  isHighDensity?: boolean;
  isGoodState?: boolean;
  replacementValue?: number;
  claimHistory?: "yes" | "no";
  contentsValue?: number;
  contents?: IContentsType[];
  allRisk?: boolean;
  allRiskType?: "local" | "worldwide";
  hasExistingMotorPolicy?: boolean;
  coverageStart?: string;
  policyId?: string;
  status?: PolicyStatus;
  occupation?: string;
};

export type IHomeContext = {
  setState: (newState?: IHomeDataInput) => void;
  removeStore: () => void;
  getAllRiskItemsTotal: () => number;
  getContentItemsTotal: () => number;
  getCalculatedContentsValue: () => number;
  getTotalValue: () => number;
  hydrateState: () => void;
} & IHomeDataInput;

const keyStore = "homeInfo";

const defaultValue: IHomeDataInput = {
  address: undefined,
  buildingType: undefined,
  isOwner: undefined,
  isOccupiedByYou: undefined,
  commercialActivities: undefined,
  constructionType: undefined,
  isFloodZone: undefined,
  isCoastal: undefined,
  isHighDensity: undefined,
  isGoodState: undefined,
  replacementValue: undefined,
  claimHistory: undefined,
  contentsValue: undefined,
  contents: undefined,
  allRisk: undefined,
  allRiskType: undefined,
  coverageStart: undefined,
  hasExistingMotorPolicy: undefined,
  policyId: undefined,
  status: POLICY_STATUS.quote as PolicyStatus,
  occupation: undefined,
};

const defaultError = "HomeProvider context has not yet been initialized.";
let savedValue = storage.getItem(keyStore) as IHomeDataInput;
savedValue = !savedValue ? defaultValue : savedValue;

const initialState: IHomeContext = {
  ...defaultValue,
  ...savedValue,
  setState() {
    throw new Error(defaultError);
  },
  removeStore() {
    throw new Error(defaultError);
  },
  getAllRiskItemsTotal() {
    throw new Error(defaultError);
  },
  getContentItemsTotal() {
    throw new Error(defaultError);
  },
  getCalculatedContentsValue() {
    throw new Error(defaultError);
  },
  getTotalValue() {
    throw new Error(defaultError);
  },
  hydrateState() {
    throw new Error(defaultError);
  },
};

export const HomeContext = createContext(initialState);
export const useHomeContext = () => useContext(HomeContext);

type Props = {
  children: ReactNode;
};

export default function HomeProvider({ children }: Props) {
  const [state, setState] = useState(initialState);

  const handleNewState = (newValue?: IHomeDataInput) => {
    if (newValue) {
      const newState = { ...state, ...newValue };
      storage.saveItem(keyStore, newState);
      return setState(newState);
    }
  };

  const handleRemoveStore = () => {
    storage.removeItem(keyStore);
    handleNewState(defaultValue);
  };

  const allRiskItemsTotal = () => {
    if (!state.allRisk || !state.contents) {
      return 0;
    }

    return state.contents.reduce((total, item) => {
      return item.isAllRisk ? total + item.value : total;
    }, 0);
  };

  const contentItemsTotal = () => {
    if (!state.contents) {
      return 0;
    }

    return state.contents.reduce((total, item) => {
      return !item.isAllRisk ? total + item.value : total;
    }, 0);
  };

  const calculatedContentsValue = () => {
    if (!state.contentsValue) {
      return 0;
    }
    return state.contentsValue - allRiskItemsTotal();
  };

  const totalValue = () => {
    return (state.replacementValue ?? 0) + (state.contentsValue ?? 0);
  };

  const hydrateState = () => {
    const savedValues = storage.getItem(keyStore);
    setState(savedValues);
  };

  useMountEffect(() => {
    const savedValues = storage.getItem(keyStore);

    if (savedValues) {
      setState(savedValues);
    }
  });

  return (
    <HomeContext.Provider
      value={{
        ...state,
        setState: handleNewState,
        removeStore: handleRemoveStore,
        getAllRiskItemsTotal: allRiskItemsTotal,
        getContentItemsTotal: contentItemsTotal,
        getCalculatedContentsValue: calculatedContentsValue,
        getTotalValue: totalValue,
        hydrateState,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
}
