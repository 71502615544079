import React, { ReactNode } from "react";
import classnames from "classnames";

import styles from "./index.module.scss";

import { H5, TextExtraSmall } from "~/components/Typography";

type Props = {
  title: string;
  description?: string;
  inputId: string;
  children: ReactNode;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  image: ReactNode;
};

export default function TileCardVertical(props: Props) {
  const {
    inputId,
    children,
    image,
    title,
    description,
    checked = false,
    disabled = false,
    className,
  } = props;

  return (
    <label
      htmlFor={inputId}
      className={classnames(styles.TileCardVerticalWrapper, className, {
        [styles.Checked]: checked,
        [styles.Disabled]: disabled,
      })}
    >
      {image && <div className={styles.ImageWrapper}>{image}</div>}

      <div className={styles.Text}>
        {title && <H5 className={styles.Title}>{title}</H5>}
        {description && (
          <TextExtraSmall className={styles.Description}>
            {description}
          </TextExtraSmall>
        )}
      </div>

      {children && <div className={styles.Input}>{children}</div>}
    </label>
  );
}
