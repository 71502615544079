import React from "react";
import { useHistory } from "react-router-dom";
import LoadingWrapper from "~/components/LoadingWrapper";
import RadioBinary from "~/components/RadioBinary";
import { H3 } from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";

type Props = {
  nextPath?: string;
};

export default function ReclaimedLand({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const heapCtx = useHeapContext();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const [saving, setSaving] = React.useState<boolean>(false);

  useMountEffect(() => {
    heapCtx.track(HeapEventName.HOME_RECLAIMED_LAND_SCREEN, {});
    headerCtx.setState({
      totalOfSteps: 6,
      currentStep: 3,
      title: "Property Information",
    });
  });

  const handleNextClick = async (reclaimedLand: boolean) => {
    try {
      if (nextPath) {
        setSaving(true);

        await checklistCtx.saveChecklist({
          policyId: checklistCtx.policyId,
          home: {
            ...checklistCtx.home,
            reclaimedLand,
          },
          nextPath,
        });

        heapCtx.track(HeapEventName.HOME_RECLAIMED_LAND, {
          "Policy ID": checklistCtx.policyId,
          "Reclaimed Land": reclaimedLand,
        });

        history.push(nextPath);
      }
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <H3>Is your home sited on reclaimed land?</H3>

          <RadioBinary
            handleClick={handleNextClick}
            field="reclaimedLand"
          ></RadioBinary>
        </>
      )}
    </>
  );
}
