import { useFormState } from "informed";
import React from "react";
import { useHistory } from "react-router-dom";
import LoadingWrapper from "~/components/LoadingWrapper";
import RadioBinary from "~/components/RadioBinary";
import SubmitButton from "~/components/SubmitButton";
import TextInput from "~/components/TextInput";
import { H3 } from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { required } from "~/helpers/validators";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import styles from "./index.module.scss";

type Props = {
  nextPath?: string;
};

export default function Mortgage({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const heapCtx = useHeapContext();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const [hasMortgage, setHasMortgage] = React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);
  const mortgageLenderField = "mortgageLender";
  const formState = useFormState();

  useMountEffect(() => {
    heapCtx.track(HeapEventName.HOME_MORTGAGE_SCREEN, {});
    headerCtx.setState({
      totalOfSteps: 6,
      currentStep: 3,
      title: "Property Information",
    });
  });

  const handleHasMortgage = async (hasMortgage: boolean) => {
    if (hasMortgage) {
      setHasMortgage(true);
    } else {
      setSaving(true);

      await checklistCtx.saveChecklist({
        policyId: checklistCtx.policyId,
        home: {
          ...checklistCtx.home,
          mortgageLender: undefined,
        },
        nextPath,
      });

      heapCtx.track(HeapEventName.HOME_MORTGAGE, {
        "Policy ID": checklistCtx.policyId,
        "Has Mortgage": hasMortgage,
      });

      if (nextPath) {
        history.push(nextPath);
      }
    }
  };

  const handleMortgageLender = async () => {
    try {
      if (nextPath) {
        setSaving(true);

        heapCtx.track(HeapEventName.HOME_MORTGAGE, {
          "Policy ID": checklistCtx.policyId,
          "Has Mortgage": true,
          "Mortgage Lender":
            String(formState.values[mortgageLenderField]) ?? null,
        });

        await checklistCtx.saveChecklist({
          policyId: checklistCtx.policyId,
          home: {
            ...checklistCtx.home,
            mortgageLender:
              String(formState.values[mortgageLenderField]) ?? null,
          },
          nextPath,
        });

        history.push(nextPath);
      }
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          {!hasMortgage && (
            <>
              <H3>Do you have a mortgage on your home?</H3>

              <RadioBinary
                handleClick={handleHasMortgage}
                field="hasMortgage"
              ></RadioBinary>
            </>
          )}

          {hasMortgage && (
            <div className={styles.Content}>
              <H3>Who is your mortgage lender?</H3>

              <TextInput
                field={mortgageLenderField}
                placeholder="Name of mortgage lender"
                type="text"
                validate={required("Please enter your mortgage lender")}
                validateOnChange
                validateOnBlur
                validateOnMount
                initialValue={
                  checklistCtx.home.mortgageLender
                    ? String(checklistCtx.home.mortgageLender)
                    : ""
                }
              />

              <SubmitButton
                id="Mortgage-SubmitButton"
                className={styles.ContinueButton}
                onClick={() => {
                  handleMortgageLender();
                }}
                disabled={
                  !formState.values[mortgageLenderField] ||
                  (formState.values[mortgageLenderField] as string)?.replace(
                    /\s/g,
                    ""
                  )?.length === 0
                }
              >
                Next
              </SubmitButton>
            </div>
          )}
        </>
      )}
    </>
  );
}
