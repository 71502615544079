import classNames from "classnames";
import { useFormState } from "informed";
import React from "react";
import { useHistory } from "react-router";
import Checkbox from "~/components/Checkbox";
import Icon from "~/components/Icon";
import LoadingWrapper from "~/components/LoadingWrapper";
import SubmitButton from "~/components/SubmitButton";
import TextInput from "~/components/TextInput";
import TileCard from "~/components/TileCard";
import { H3, Text } from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import styles from "./index.module.scss";

type HistoryOption = {
  field: string;
  label: string;
  selected: boolean;
};

type HistoryOptionsContainer = { [index: string]: HistoryOption };

const HISTORY_OPTIONS: HistoryOptionsContainer = {
  DeclinedToInsure: {
    field: "DeclinedToInsure",
    label: "Declined to insure you or any person residing with you",
    selected: false,
  },
  RequiredSpecialTerms: {
    field: "RequiredSpecialTerms",
    label: "Required special terms",
    selected: false,
  },
  CancelledOrRefusedToRenew: {
    field: "CancelledOrRefusedToRenew",
    label: "Cancelled or refused to renew your insurance",
    selected: false,
  },
  IncreasedYourPremium: {
    field: "IncreasedYourPremium",
    label: "Increased your premium",
    selected: false,
  },
};

type Props = {
  nextPath?: string;
};

export default function InsuranceHistory({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const heapCtx = useHeapContext();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const formState = useFormState();
  const insuranceHistoryReasonField = "insuranceHistoryReason";
  const [saving, setSaving] = React.useState<boolean>(false);
  const [historyOptions, setHistoryOptions] = React.useState(HISTORY_OPTIONS);
  const [optionsSelectedCount, setOptionsSelectedCount] = React.useState(0);
  const [hasInsuranceHistory, setHasInsuranceHistory] = React.useState<boolean>(
    false
  );

  useMountEffect(() => {
    heapCtx.track(HeapEventName.HOME_INSURANCE_HISTORY_SCREEN, {});
    headerCtx.setState({
      totalOfSteps: 6,
      currentStep: 3,
      title: "Insurance History",
    });

    if (Array.isArray(checklistCtx?.home?.insuranceHistory)) {
      let selectedCount = 0;

      const newList = Object.keys(historyOptions).reduce((acc, key) => {
        const isSelected = checklistCtx.home?.insuranceHistory?.includes(key);

        if (isSelected) {
          selectedCount++;
        }

        return {
          ...acc,
          [key]: { ...historyOptions[key], selected: isSelected },
        };
      }, {});

      setOptionsSelectedCount(selectedCount);
      setHistoryOptions(newList);
    }
  });

  const onOptionSelected = (value: string) => {
    let selectedCount = 0;

    const newList = Object.keys(historyOptions).reduce((acc, key) => {
      const isSelected = historyOptions[key].selected;

      if (isSelected) {
        selectedCount++;
      }

      if (key === value) {
        if (isSelected) {
          selectedCount--;
        } else {
          selectedCount++;
        }

        return {
          ...acc,
          [key]: { ...historyOptions[key], selected: !isSelected },
        };
      }

      return {
        ...acc,
        [key]: { ...historyOptions[key] },
      };
    }, {});

    setOptionsSelectedCount(selectedCount);
    setHistoryOptions(newList);
  };

  const handleNextClick = async () => {
    try {
      if (nextPath) {
        const selectedOptions = Object.keys(historyOptions).filter(
          (key) => historyOptions[key].selected
        );

        if (selectedOptions.length > 0) {
          setHasInsuranceHistory(true);
        } else {
          setSaving(true);

          await checklistCtx.saveChecklist({
            policyId: checklistCtx.policyId,
            home: {
              ...checklistCtx.home,
              insuranceHistory: selectedOptions.length
                ? selectedOptions
                : ["None"],
            },
            nextPath,
          });

          heapCtx.track(HeapEventName.HOME_INSURANCE_HISTORY, {
            "Policy ID": checklistCtx.policyId,
            "Insurance History": false,
          });

          history.push(nextPath);
        }
      }
    } catch (error) {
      setSaving(false);
      console.warn(error);
    }
  };

  const handleInsuranceHistoryReason = async () => {
    try {
      if (nextPath) {
        setSaving(true);

        await checklistCtx.saveChecklist({
          policyId: checklistCtx.policyId,
          home: {
            ...checklistCtx.home,
            insuranceHistory: Object.keys(historyOptions).filter(
              (key) => historyOptions[key].selected
            ),
            insuranceHistoryReason:
              String(formState.values[insuranceHistoryReasonField]) ?? null,
          },
          nextPath,
        });

        heapCtx.track(HeapEventName.HOME_INSURANCE_HISTORY, {
          "Policy ID": checklistCtx.policyId,
          "Insurance History": true,
        });

        history.push(nextPath);
      }
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          {!hasInsuranceHistory && (
            <>
              <H3>Has any previous insurer:</H3>

              <Text className={styles.SubTitle}>
                Select all that apply. If none of these apply, please skip this
                step.
              </Text>

              <div className={styles.InsuranceHistoryContainer}>
                {Object.keys(historyOptions).map((key, index) => {
                  const option = historyOptions[key];
                  const tileCardId = `InsuranceHistory-TileCard-${index}`;

                  return (
                    <TileCard
                      key={tileCardId}
                      title={option.label}
                      inputId={tileCardId}
                      checked={option.selected}
                      inputElement={
                        <Checkbox
                          id={tileCardId}
                          field="historyOption"
                          className={styles.HideCheckbox}
                          onChange={() => onOptionSelected(key)}
                        />
                      }
                      left={
                        <>
                          <div
                            className={classNames(styles.CheckBoxIcon, {
                              [styles.Checked]: option.selected,
                            })}
                          >
                            {option.selected && (
                              <Icon
                                name="CheckSquare"
                                backgroundColor="#FF6B28"
                              />
                            )}
                          </div>
                        </>
                      }
                      className={styles.TileCard}
                    />
                  );
                })}
              </div>

              <SubmitButton
                id="InsuranceHistory-SubmitButton"
                className={styles.ContinueButton}
                onClick={() => {
                  handleNextClick();
                }}
              >
                {optionsSelectedCount ? "Next" : "Skip"}
              </SubmitButton>
            </>
          )}

          {hasInsuranceHistory && (
            <div className={styles.Content}>
              <Text>
                A previous insurer has{" "}
                {optionsSelectedCount > 1
                  ? "applied special conditions to your policy before."
                  : `${historyOptions[
                      Object.keys(historyOptions).filter(
                        (key) => historyOptions[key].selected
                      )[0]
                    ].label.toLowerCase()}.`}
              </Text>

              <H3 className={styles.SubTitle}>
                Please provide some additional details as to why this happened.
              </H3>

              <TextInput
                field={insuranceHistoryReasonField}
                placeholder="Past insurance details"
                type="text"
                initialValue={
                  checklistCtx.home.insuranceHistoryReason
                    ? String(checklistCtx.home.insuranceHistoryReason)
                    : ""
                }
              />

              <SubmitButton
                id="InsuranceHistory-Reason-SubmitButton"
                className={styles.ContinueButton}
                onClick={() => {
                  handleInsuranceHistoryReason();
                }}
                disabled={!formState.values[insuranceHistoryReasonField]}
              >
                Continue
              </SubmitButton>
            </div>
          )}
        </>
      )}
    </>
  );
}
