import React from "react";
import CheckImage from "~/assets/images/check.png";
import { H4, TextSmall } from "~/components/Typography";
import HomeImage from "~/assets/images/home.png";
import CarImage from "~/assets/images/car-large.png";
import PortalList from "~/components/PortalList";
import { formatCurrency } from "~/helpers/currency";

import styles from "./index.module.scss";
import { PendingPayment } from "../../types";
import { formatIsoDate } from "~/helpers/dates";

type PropsType = {
  annualPremium: number;
  pendingPayments?: PendingPayment[];
  policyObject: "auto" | "home";
};

const PortalPaymentCard = ({
  annualPremium,
  pendingPayments,
  policyObject,
}: PropsType) => {
  const dataList: Array<{ title: string; value: string }> = [];

  if (pendingPayments) {
    pendingPayments.forEach((payment) => {
      dataList.push({
        title: formatIsoDate(payment.date, "MMM dd, yyyy"),
        value: formatCurrency(Number(payment.amount), false),
      });
    });
  }

  return (
    <div
      className={styles.Payment}
      role="group"
      aria-label="payment information"
    >
      <div className={styles.PaymentIcon}>
        <img
          src={CheckImage}
          alt="check mark"
          aria-hidden
          className={styles.CheckImage}
        />
      </div>

      <div className={styles.PaymentHeader}>
        <TextSmall>Annual Premium</TextSmall>
        <H4>{formatCurrency(annualPremium, false)}</H4>
      </div>

      <div className={styles.PaymentDetails}>
        <PortalList data={dataList} />
      </div>

      <div className={styles.PaymentImageContainer}>
        {policyObject === "auto" ? (
          <img
            src={CarImage}
            alt="car in front of palm trees"
            className={styles.PaymentImage}
          />
        ) : undefined}

        {policyObject === "home" ? (
          <img
            src={HomeImage}
            alt="home surrounded by palm trees"
            className={styles.PaymentImage}
          />
        ) : undefined}
      </div>
    </div>
  );
};

export default PortalPaymentCard;
