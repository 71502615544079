import React from "react";
import moment from "moment";
import { parse } from "date-fns";
import { useHistory } from "react-router-dom";

import TextInput from "~/components/TextInput";
import SubmitButton from "~/components/SubmitButton";
import { H3, Bold, TextSmall, Text } from "~/components/Typography";
import VehicleSearchDisplaySmall from "~/components/VehicleSearchDisplaySmall";
import { useMotorContext } from "~/contexts/MotorProvider";
import {
  cleanInputDate,
  validateCoverageStartDate,
} from "~/helpers/validators";

import styles from "./index.module.scss";
import {
  formatIsoDate,
  formatUkDate,
  getCoverageStartMaxDate,
  localDateFromIso,
  todaysDateInAst,
} from "~/helpers/dates";
import { useUpdateAssistant } from "~/helpers/useUpdateAssistant";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import pushWithParams from "~/helpers/pushWithParams";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { InsuranceType } from "../../types";
import { useHomeContext } from "~/contexts/HomeProvider";

type Props = {
  insuranceType: InsuranceType;
  nextPath?: string;
};

export default function CoverageStart({ insuranceType, nextPath }: Props) {
  const history = useHistory();
  const motorCtx = useMotorContext();
  const homeCtx = useHomeContext();
  const { value, error } = useCustomFieldState<string>("coverageStart");
  const heapCtx = useHeapContext();

  //We don't need to re-render this.
  const [maxDate] = React.useState<moment.Moment>(
    moment(localDateFromIso(getCoverageStartMaxDate()))
  );

  const coverageStart =
    insuranceType === "motor"
      ? motorCtx?.policyInfo?.data?.coverageStart
      : homeCtx?.coverageStart;
  const savedValue = coverageStart ? formatIsoDate(coverageStart) : undefined;

  useUpdateAssistant({
    text: (
      <TextSmall>
        Your coverage can start today, or any day until{" "}
        <Bold>{maxDate.format("MMM D, YYYY")}</Bold>.
      </TextSmall>
    ),
  });

  const handleOnClick = () => {
    try {
      if (value && !error && typeof value === "string") {
        const formattedDate = formatUkDate(cleanInputDate(value));

        if (insuranceType === "motor") {
          motorCtx.policyInfo.setItem("coverageStart", formattedDate);

          heapCtx.track(HeapEventName.MOTOR_COVERAGE_DATE, {
            "Coverage Date": formattedDate,
          });
        } else {
          homeCtx.setState({ coverageStart: formattedDate });

          heapCtx.track(HeapEventName.HOME_COVERAGE_DATE, {
            "Coverage Date": formattedDate,
          });
        }
      }

      if (nextPath && !error) {
        pushWithParams(history, nextPath);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const label = "When do you want your coverage to start?";

  return (
    <>
      {insuranceType === "motor" && (
        <VehicleSearchDisplaySmall
          prefix={`Your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()} `}
        />
      )}

      <H3 className={styles.Question} component="h1">
        {label}
      </H3>

      <Text>Please use format DD/MM/YYYY</Text>
      <div className={styles.TextInputWrapper}>
        <TextInput
          label={label}
          hideLabelFromView
          field="coverageStart"
          placeholder="DD / MM / YYYY"
          type="date"
          maintainCursor
          validate={validateCoverageStartDate}
          validateOnMount
          initialValue={value && typeof value === "string" ? value : savedValue}
          validateOnBlur
          validateOnChange
          keepState
          autoFocus
          date={parse(value, "dd/MM/yyyy", new Date())}
          minimumDate={localDateFromIso(todaysDateInAst())}
          maximumDate={maxDate.toDate()}
        />
      </div>

      <div className={styles.ButtonWrapper}>
        <SubmitButton
          id={"CoverageStart-SubmitButton"}
          onClick={handleOnClick}
          disabled={!value || !!error}
          className={styles.ButtonNext}
        >
          Next
        </SubmitButton>
      </div>
    </>
  );
}
