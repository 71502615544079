import React from "react";
import { useHistory } from "react-router";
import RadioBinary from "~/components/RadioBinary";
import { H3 } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";

type Props = {
  nextPath?: string;
};

export default function ExistingMotorPolicy({ nextPath }: Props) {
  const history = useHistory();
  const headerCtx = useHeaderContext();
  const homeCtx = useHomeContext();
  const heapCtx = useHeapContext();

  useMountEffect(() => {
    headerCtx.setState({
      totalOfSteps: 1,
      currentStep: 1,
      title: "Existing Motor Policy",
    });
  });

  const handleNextClick = (hasMotorPolicy: boolean) => {
    try {
      homeCtx.setState({
        hasExistingMotorPolicy: hasMotorPolicy,
      });
      heapCtx.track(HeapEventName.HOME_EXISTING_MOTOR_POLICY, {
        "Existing Motor Policy": hasMotorPolicy,
      });
      if (nextPath) {
        history.push(nextPath);
      }
      //history.push("/BRB/home/contents-value"); //TODO make switchable to GUY
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <H3>Do you have an existing motor policy with us?</H3>
      <RadioBinary handleClick={handleNextClick} field="hasMotorPolicy" />
    </>
  );
}
