import React from "react";
import LoadingWrapper from "~/components/LoadingWrapper";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useHistory, useParams } from "react-router-dom";

import CarImg from "~/assets/images/illustration-motor.png";
import TruckImg from "~/assets/images/illustration-truck.png";
import BikeImg from "~/assets/images/illustration-bike.png";
import HomeImg from "~/assets/images/illustration-home.png";
import { H3, H4, H5, H6, Link, Text } from "~/components/Typography";
import { Select, Option } from "informed";
import { useAuth0Context, UserRoleType } from "~/contexts/Auth0Context";
import { ToolOnCloseProps, ToolProps } from "..";
import AdminCancelPolicy from "../PolicyTools/AdminCancelPolicy";
import AdminCancelRecurringPayment from "../PolicyTools/AdminCancelRecurringPayment";
import AdminRegenerateContract from "../PolicyTools/AdminRegenerateContract";
import AdminRegenerateCoverNote from "../PolicyTools/AdminRegenerateCoverNote";
import AdminUpdatePolicyPremium from "../PolicyTools/AdminUpdatePolicyPremium";
import Modal from "~/components/Modal";
import AdminManualSinglePayment from "../PolicyTools/AdminManualSinglePayment";
import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";
import styles from "./index.module.scss";
import { formatCurrency } from "~/helpers/currency";
import { getTypeOfCoverage } from "~/helpers/getCoverageData";
import { useCustomFieldApi } from "~/helpers/hooks/useCustomFieldApi";
import AdminDownloadDocument from "../AdminDownloadDocument";
import Button from "~/components/Button";
import Status from "~/components/Status";
import { ADMIN_CUSTOMER_DETAILS_URL } from "~/routes/index.constant";
import AdminRegisterPhysicalPayment from "../PolicyTools/AdminRegisterPhysicalPayment";
import AdminMarkFirstPaymentSuccessful from "../PolicyTools/AdminMarkFirstPaymentSuccessful";
import AdminRegisterManualRefund from "../PolicyTools/AdminRegisterManualRefund";
import AdminUpdateWebstarPolicies from "../PolicyTools/AdminUpdateWebstarPolicies";

const GET_POLICY_DETAILS = gql`
  query adminGetPolicyDetails($policyId: String!) {
    adminGetPolicyDetails(policyId: $policyId) {
      policyId
      customerId
      policyStatus
      policyType
      coverageType
      policyProducer
      coverageStartDate
      coverageEndDate
      purchaseDate
      originalInception
      annualPremium
      vehicle {
        year
        make
        model
        value
        engineSize
        type
        isSportsCar
        isEngineModified
        isLeftSide
        isVehicleOwned
        isElectric
        registration
        engineNumber
        chassisNumber
        preciseEngineSize
        windscreenCoverageAmount
      }
      home {
        buildingType
        heightInStories
        age
        address
        isCommercial
        constructionType
        isFloodRisk
        isCoastal
        isHighDensity
        isGoodState
        isConflagurationZone
        value
        contentsValue
        allRiskCoverage
        allRiskValue
        isAllRiskWorldwide
        isExistingMotorCustomer
      }
      scheduledPayments {
        paymentDate
        amount
        status
        lastModified
        paymentRecordId
      }
      finalCardNumbers
      cardHolderName
      notes
    }
  }
`;

type PolicyTool = {
  displayName: string;
  element: React.FC<ToolProps>;
  allowedRoles: UserRoleType[];
};

type ScheduledPayment = {
  paymentDate: Date;
  amount: string;
  status: string;
  lastModified: Date;
  paymentRecordId: string;
};

type PolicyDetails = {
  policyId: string;
  customerId: string;
  policyStatus: string;
  policyType: string;
  coverageType: string;
  policyProducer: string;
  coverageStartDate: Date;
  coverageEndDate: Date;
  originalInception: Date;
  annualPremium: string;
  vehicle: {
    year: number;
    make: string;
    model: string;
    value: string;
    engineSize: string;
    type: string;
    isSportsCar: boolean;
    isEngineModified: boolean;
    isLeftSide: boolean;
    isVehicleOwned: boolean;
    isElectric: boolean;
    registration?: string;
    engineNumber?: string;
    chassisNumber?: string;
    preciseEngineSize?: string;
    windscreenCoverageAmount?: string;
  };
  home: {
    buildingType: string;
    heightInStories?: number;
    age?: number;
    address: string;
    isCommerical: boolean;
    constructionType: string;
    isFloodRisk: boolean;
    isCoastal: boolean;
    isHighDensity: boolean;
    isGoodState: boolean;
    isConflagurationZone?: boolean;
    value: number;
    contentsValue: number;
    allRiskCoverage: boolean;
    allRiskValue?: number;
    isAllRiskWorldwide?: boolean;
    isExistingMotorCustomer?: boolean;
  };
  finalCardNumbers?: string;
  cardHolderName?: string;
  notes?: string;
  scheduledPayments: ScheduledPayment[];
};

type GetPolicyDetails = {
  adminGetPolicyDetails: PolicyDetails;
};

const policyToolsFieldName = "AdminPolicyDetailView-PolicyTools";

const AdminPolicyDetailView = () => {
  //States
  const [policy, setPolicy] = React.useState<PolicyDetails | undefined>(
    undefined
  );

  const [selectedTool, setSelectedTool] = React.useState<
    PolicyTool | undefined
  >(undefined);

  const [successMessage, setSuccessMessage] = React.useState<
    string | undefined
  >(undefined);

  //Contexts
  const auth0Context = useAuth0Context();
  const history = useHistory();

  //Constants
  const policyToolsFieldState = useCustomFieldState(policyToolsFieldName);
  const policyToolsFieldApi = useCustomFieldApi(policyToolsFieldName);

  const policyTools: PolicyTool[] = [
    {
      displayName: "Cancel Policy",
      element: AdminCancelPolicy,
      allowedRoles: [UserRoleType.admin],
    },
    {
      displayName: "Cancel Recurring Payments",
      element: AdminCancelRecurringPayment,
      allowedRoles: [UserRoleType.admin],
    },
    {
      displayName: "Register Physical Payment",
      element: AdminRegisterPhysicalPayment,
      allowedRoles: [UserRoleType.admin],
    },
    {
      displayName: "Register Manual Refund",
      element: AdminRegisterManualRefund,
      allowedRoles: [UserRoleType.admin],
    },
    {
      displayName: "Charge Single Payment",
      element: AdminManualSinglePayment,
      allowedRoles: [UserRoleType.admin, UserRoleType.cxo],
    },
    {
      displayName: "Mark First Payment Successful",
      element: AdminMarkFirstPaymentSuccessful,
      allowedRoles: [UserRoleType.admin],
    },
    {
      displayName: "Regenerate Contract",
      element: AdminRegenerateContract,
      allowedRoles: [UserRoleType.admin, UserRoleType.cxo],
    },
    {
      displayName: "Regenerate Cover Note",
      element: AdminRegenerateCoverNote,
      allowedRoles: [UserRoleType.admin, UserRoleType.cxo],
    },
    {
      displayName: "Update Premium",
      element: AdminUpdatePolicyPremium,
      allowedRoles: [UserRoleType.admin],
    },
    {
      displayName: "Update Webstar",
      element: AdminUpdateWebstarPolicies,
      allowedRoles: [UserRoleType.admin],
    },
  ];

  const params: {
    policyId: string | undefined;
  } = useParams();

  //Queries
  const { loading, data, error, refetch } = useQuery<GetPolicyDetails>(
    GET_POLICY_DETAILS,
    {
      variables: {
        policyId: params.policyId,
      },
      fetchPolicy: "no-cache",
      skip: !params.policyId,
    }
  );

  //Functions
  const handleToolClose = (params: ToolOnCloseProps) => {
    policyToolsFieldApi.setValue("");
    window.scrollTo({ top: 0 });

    if (params.errorCode === "NOT_ADMIN") {
      auth0Context.logout();
    }

    if (params.success && params.message) {
      setSuccessMessage(params.message);
    }

    if (params.shouldReload) {
      refetch();
    }
  };

  const getDateString = (date: Date) => {
    return new Date(date).toLocaleDateString();
  };

  const getPolicyToolComponent = (tool: PolicyTool) => {
    const SelectedTool = tool.element;

    return (
      <SelectedTool
        identifier={params.policyId ?? ""}
        onClose={handleToolClose}
      />
    );
  };

  //Memos
  const policyTypeTitle = React.useMemo(() => {
    if (!policy?.coverageType) {
      return "";
    }

    const coverage = getTypeOfCoverage(policy.coverageType);
    return coverage.title;
  }, [policy]);

  const policyTypeSubTitle = React.useMemo(() => {
    if (!policy?.coverageType) {
      return "";
    }

    const coverage = getTypeOfCoverage(policy.coverageType);
    return coverage.subTitle;
  }, [policy]);

  const policyImageLink = React.useMemo(() => {
    if (policy) {
      if (policy.policyType === "auto") {
        switch (policy.vehicle.type) {
          case "Car":
            return CarImg;
          case "Truck":
            return TruckImg;
          case "Motorcycle":
            return BikeImg;
          default:
            return "";
        }
      } else {
        return HomeImg;
      }
    }

    return "";
  }, [policy]);

  const policyToolsMap = React.useMemo(() => {
    const roles = auth0Context.getRolesFromToken();

    return policyTools.map((tool) => {
      if (
        tool.allowedRoles.filter((roleName) => {
          return roles.includes(roleName);
        }).length > 0
      ) {
        return (
          <Option key={tool.displayName} value={tool.displayName}>
            {tool.displayName}
          </Option>
        );
      } else {
        return <React.Fragment key={tool.displayName}></React.Fragment>;
      }
    });
  }, [auth0Context, policyTools]);

  const paymentScheduleMap = React.useMemo(() => {
    return !policy
      ? []
      : policy.scheduledPayments.map((payment, index) => {
          return (
            <div
              className={styles.ScheduledPayment}
              key={`scheduled-payment-${index}`}
            >
              <H5>{getDateString(payment.paymentDate)}</H5>
              <Text>{formatCurrency(Number(payment.amount), false)}</Text>
              <Text>{payment.status}</Text>
            </div>
          );
        });
  }, [policy]);

  //UseEffects
  React.useEffect(() => {
    setSelectedTool(
      policyTools.find((tool) => {
        return tool.displayName === policyToolsFieldState.value;
      })
    );
    //Including policyTools in the deps causes a looping issue
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyToolsFieldState.value]);

  React.useEffect(() => {
    if (data && data.adminGetPolicyDetails) {
      setPolicy(data.adminGetPolicyDetails);
    }
  }, [data]);

  return (
    <>
      {loading && <LoadingWrapper loading />}

      {error && (
        <div className={styles.Content}>
          <Text className={styles.ErrorMessage}>
            Policy could not be retreived.
          </Text>

          <Button
            className={styles.GoBackButton}
            onClick={() => {
              history.goBack();
            }}
          >
            Go Back
          </Button>
        </div>
      )}

      {policy && (
        <div className={styles.Content}>
          <div className={styles.ScreenHeader}>
            <div className={styles.PolicyIDInfo}>
              <img
                className={styles.PolicyImage}
                src={policyImageLink}
                alt={`Icon Policy`}
                aria-hidden
              />

              <Status status={policy.policyStatus} />

              <H3 className={styles.PolicyType}>
                {policy.policyType === "auto" ? "Motor" : "Home"} Policy{" "}
              </H3>

              <H4 className={styles.PolicyID}>{policy.policyId}</H4>

              <Link to={`${ADMIN_CUSTOMER_DETAILS_URL}/${policy.customerId}`}>
                Customer {policy.customerId}
              </Link>
            </div>

            <div>
              <div className={styles.ToolboxContainer}>
                <H4>Policy Toolbox</H4>

                <Select field={policyToolsFieldName}>
                  <Option value="" disabled>
                    Select Tool
                  </Option>

                  {policyToolsMap}
                </Select>
              </div>

              <div className={styles.PolicyInfoSection}>
                <div className={styles.PolicyInfoSubSection}>
                  <H4>Expiry Date</H4>
                  <Text>{getDateString(policy.coverageEndDate)}</Text>
                </div>

                <div className={styles.PolicyInfoSubSection}>
                  <H4>Annual Premium</H4>

                  <Text>
                    {formatCurrency(Number(policy.annualPremium), false)}
                  </Text>
                </div>
              </div>
            </div>

            {successMessage && (
              <H4 className={styles.SuccessMessage}>{successMessage}</H4>
            )}
          </div>

          <div className={styles.CardsContainer}>
            <div className={styles.DetailsCard}>
              <div className={styles.CardHeader}>
                <H4>{policyTypeSubTitle}</H4>
                <H3>{policyTypeTitle}</H3>
              </div>

              <div>
                <H5>Producer</H5>
                <Text>{policy.policyProducer}</Text>
              </div>

              <div>
                <H5>Coverage Period</H5>
                <Text>{`${getDateString(
                  policy.coverageStartDate
                )} to ${getDateString(policy.coverageEndDate)}`}</Text>
              </div>

              <div>
                <H5>Original Inception</H5>
                <Text>{getDateString(policy.originalInception)}</Text>
              </div>

              {policy.policyType === "auto" && (
                <>
                  <div>
                    <H5>Vehicle Value Insured</H5>

                    <Text>
                      {formatCurrency(Number(policy.vehicle.value), false)}
                    </Text>
                  </div>

                  <div>
                    <H5>Vehicle Make/Model/Year</H5>
                    <Text>{`${policy.vehicle.make} / ${policy.vehicle.model} / ${policy.vehicle.year}`}</Text>
                  </div>
                </>
              )}

              {policy.policyType === "home" && (
                <>
                  <div>
                    <H5>Address</H5>
                    <Text>{policy.home.address}</Text>
                  </div>

                  <div>
                    <H5>Building Type</H5>
                    <Text>{policy.home.buildingType}</Text>
                  </div>

                  <div>
                    <H5>Construction Type</H5>
                    <Text>{policy.home.constructionType}</Text>
                  </div>

                  <div>
                    <H5>Home Value</H5>
                    <Text>{formatCurrency(policy.home.value, false)}</Text>
                  </div>

                  {!!policy.home.contentsValue && (
                    <div>
                      <H5>Contents Value</H5>

                      <Text>
                        {formatCurrency(policy.home.contentsValue, false)}
                      </Text>
                    </div>
                  )}

                  {!!policy.home.allRiskValue && (
                    <>
                      <div>
                        <H5>All Risk Value</H5>
                        <Text>
                          {formatCurrency(policy.home.allRiskValue, false)}
                        </Text>
                      </div>

                      <div>
                        <H5>Is All Risk Worldwide?</H5>

                        <Text>
                          {policy.home.isAllRiskWorldwide ? "Yes" : "No"}
                        </Text>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            <div className={styles.DocumentsCard}>
              <div className={styles.CardHeader}>
                <H5>Documentation</H5>
                <H3>Policy Documents</H3>
              </div>

              {policy.policyType === "auto" && (
                <>
                  <AdminDownloadDocument
                    policyId={params.policyId ?? ""}
                    documentType="SIGNED_PROPOSAL"
                    title="Signed Proposal"
                    subTitle="Your signed proposal form"
                  />

                  <AdminDownloadDocument
                    policyId={params.policyId ?? ""}
                    documentType="POLICY_SCHEDULE"
                    title="Policy Schedule"
                    subTitle="Specific terms of coverage"
                  />

                  <AdminDownloadDocument
                    policyId={params.policyId ?? ""}
                    documentType="COVER_NOTE"
                    title="Cover Note"
                    subTitle="Your proof of coverage"
                  />
                </>
              )}

              {policy.policyType === "home" && <H6>No Documents Available</H6>}
            </div>

            <div className={styles.ScheduledPaymentsCard}>
              <div className={styles.CardHeader}>
                <H5>Scheduled Payments</H5>
                <H3>Payments Breakdown</H3>
              </div>

              <div className={styles.ScheduledPaymentsBreakdown}>
                {paymentScheduleMap}
              </div>
            </div>

            <div className={styles.PaymentMethodsCard}>
              <div className={styles.CardHeader}>
                <H5>Payment Methods</H5>
                <H3>Card Details</H3>
              </div>

              <div>
                <H5>Card Number</H5>

                <Text>
                  ・・・・ ・・・・ ・・・・ {policy.finalCardNumbers}
                </Text>
              </div>
            </div>
          </div>

          <Modal
            title={selectedTool?.displayName}
            isOpen={selectedTool !== undefined}
            shouldOverlayCloseOnClick
            onClose={() => {
              policyToolsFieldApi.setValue("");
            }}
            id="policy-tool-modal"
          >
            {selectedTool !== undefined && getPolicyToolComponent(selectedTool)}
          </Modal>
        </div>
      )}
    </>
  );
};

export default AdminPolicyDetailView;
