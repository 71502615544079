import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { H3, Text, Bold, TextSmall } from "~/components/Typography";
import SupportContactCard from "~/components/SupportContactCard";

import { useAssistantContext } from "~/contexts/AlmiAssistantProvider";

import { formatCurrency } from "~/helpers/currency";
import {
  MINIMUM_HOME_REPLACEMENT_VALUE,
  MAXIMUM_HOME_REPLACEMENT_VALUE,
} from "~/helpers/constants";

import styles from "./index.module.scss";
import GridWrapper from "~/components/GridWrapper";

import OnboardingLayout from "~/components/Layouts/OnboardingLayout";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";

const NoAvailableCoverage = () => {
  const params = useLocation();
  const history = useHistory();
  const { updateAssistant } = useAssistantContext();

  const defaultPageInfo = {
    title: <H3>We are focusing Almi on a specific set of insurance.</H3>,
    text: (
      <Text>
        Don&apos;t worry! Our friends at <Bold>Guardian General</Bold> are happy
        to help get you insured.
      </Text>
    ),
    supportCard: (
      <Text>Contact Guardian to apply for insurance coverage today.</Text>
    ),
  };

  const [pageInfo, setPageInfo] = React.useState<{
    title: React.ReactNode;
    text?: React.ReactNode;
    supportCard?: React.ReactNode;
  }>(defaultPageInfo);

  useMountEffect(() => {
    const query = new URLSearchParams(params.search);
    let assistantText = undefined;

    switch (query.get("message")) {
      case "vehicle-year":
        assistantText = (
          <TextSmall>
            The world&apos;s first production car was manufactured in 1886 by an
            inventor named Karl Benz.
          </TextSmall>
        );
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              Almi is focused on insuring a particular set of vehicles. If your
              vehicle is older than 1985, we can&apos;t insure you right now.
            </H3>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for motor coverage.</Text>
          ),
        });
        break;
      case "vehicle-purpose":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              Almi is focused on insuring personal vehicles. If this vehicle is
              used for business purposes, we can&apos;t insure you right now.
            </H3>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for motor coverage.</Text>
          ),
        });
        break;
      case "too-many-claims":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              Almi is focused on a particular set of customers. If you have more
              than two claims in the last 4 years, we can&apos;t insure you
              right now.
            </H3>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for motor coverage.</Text>
          ),
        });
        break;
      case "motorcycle":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              Almi is focused on insuring a particular type of vehicle. If this
              vehicle is a motorcycle, we can&apos;t insure you right now.
            </H3>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for motor coverage.</Text>
          ),
        });
        break;
      case "vehicle-value":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              Almi is focused on insuring a particular set of vehicles. If your
              vehicle is less than $10,000 or over $250,000, we can&apos;t
              insure you right now.
            </H3>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for motor coverage.</Text>
          ),
        });
        break;
      case "own-vehicle":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              Almi is focused on insuring personal vehicles. If this vehicle
              isn&apos;t yours, we can&apos;t insure you right now.
            </H3>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for motor coverage.</Text>
          ),
        });
        break;
      case "invalid-claims":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              Almi is focused on a particular set of customers. If you were at
              fault, still have open claims, or have a claim value $10,000 and
              over we can&apos;t insure you right now.
            </H3>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for motor coverage.</Text>
          ),
        });
        break;
      case "under-age":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              Almi is focused on a particular set of customers. If you are not
              between the ages of 20 and 70, we can&apos;t insure you right now.
            </H3>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for motor coverage.</Text>
          ),
        });
        break;
      case "modifications":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              Almi is focused on insuring standard vehicles. If this vehicle is
              modified or you have plans to modify, we can&apos;t insure you
              right now.
            </H3>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for motor coverage.</Text>
          ),
        });
        break;
      case "experience":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              Almi is focused on insuring customers with at least 2 years of
              driving experience. If you do not have at least 2 years of driving
              experience, we can&apos;t insure you right now.
            </H3>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for motor coverage.</Text>
          ),
        });
        break;
      case "home-past-claims":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              We are focusing Almi on a specific set of insurance. Right now, we
              can’t provide any coverage plans if you have submitted a claim in
              the last 3 years.
            </H3>
          ),
          text: (
            <Text>
              We’ve partnered with <Bold>Guardian Group</Bold> to provide
              insurance to customers who don’t meet our current criteria.
            </Text>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for home coverage.</Text>
          ),
        });
        break;
      case "home-occupation-rent":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              We are focusing Almi on a specific set of insurance. Right now, we
              can’t provide any coverage plans for home renters.
            </H3>
          ),
          text: (
            <Text>
              We’ve partnered with <Bold>Guardian Group</Bold> to provide
              insurance to customers who don’t meet our current criteria.
            </Text>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for home coverage.</Text>
          ),
        });
        break;
      case "home-occupancy-not-you":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              Almi is focused on insuring personal properties. If your home is
              not occupied by you, your family or domestic employees, we can’t
              insure you right now.
            </H3>
          ),
          text: (
            <Text>
              We’ve partnered with <Bold>Guardian Group</Bold> to provide
              insurance to customers who don’t meet our current criteria.
            </Text>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for home coverage.</Text>
          ),
        });
        break;
      case "home-commercial-activity":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              We are focusing Almi on a specific set of insurance. Right now, we
              can’t provide any coverage plans for homes with commercial
              activities.
            </H3>
          ),
          text: (
            <Text>
              We’ve partnered with <Bold>Guardian Group</Bold> to provide
              insurance to customers who don’t meet our current criteria.
            </Text>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for home coverage.</Text>
          ),
        });
        break;
      case "home-property-construction":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              Almi is focused on a particlar set of properties. If this property
              has mixed or wooden construction, we can&apos;t insure you right
              now.
            </H3>
          ),
          text: (
            <Text>
              We’ve partnered with <Bold>Guardian Group</Bold> to provide
              insurance to customers who don’t meet our current criteria.
            </Text>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for home coverage.</Text>
          ),
        });
        break;
      case "home-flood-risk":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              We are focusing Almi on a specific set of insurance. Right now, we
              can’t provide any coverage plans for homes in land slippage or
              flooding areas.
            </H3>
          ),
          text: (
            <Text>
              We’ve partnered with <Bold>Guardian Group</Bold> to provide
              insurance to customers who don’t meet our current criteria.
            </Text>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for home coverage.</Text>
          ),
        });
        break;
      case "home-property-density":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              We are focusing Almi on a specific set of insurance. Right now, we
              can’t provide any coverage plans for homes in a high density area.
            </H3>
          ),
          text: (
            <Text>
              We’ve partnered with <Bold>Guardian Group</Bold> to provide
              insurance to customers who don’t meet our current criteria.
            </Text>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for home coverage.</Text>
          ),
        });
        break;
      case "home-good-state":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              Almi is focused on a particular set of properties. If this
              property is not in a good state of repair, we can&apos;t insure
              you right now.
            </H3>
          ),
          text: (
            <Text>
              We’ve partnered with <Bold>Guardian Group</Bold> to provide
              insurance to customers who don’t meet our current criteria.
            </Text>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for home coverage.</Text>
          ),
        });
        break;
      case "low-replacement-value":
        setPageInfo({
          ...defaultPageInfo,
          title: (
            <H3>
              Almi is focused on a particular set of customers. If your property
              value is less than{" "}
              {formatCurrency(MINIMUM_HOME_REPLACEMENT_VALUE, true)} or over{" "}
              {formatCurrency(MAXIMUM_HOME_REPLACEMENT_VALUE, true)}, we
              can&apos;t insure you right now.
            </H3>
          ),
          text: (
            <Text>
              We’ve partnered with <Bold>Guardian Group</Bold> to provide
              insurance to customers who don’t meet our current criteria.
            </Text>
          ),
          supportCard: (
            <Text>Contact Guardian to apply for home coverage.</Text>
          ),
        });
        break;
      default:
        setPageInfo({
          ...defaultPageInfo,
        });
        break;
    }
    updateAssistant({
      text: assistantText,
      isOpen: !!assistantText,
    });
  });

  return (
    <OnboardingLayout onPrevClick={history.goBack}>
      <GridWrapper>
        <div className={styles.Content}>
          <div className={styles.Message}>{pageInfo.title}</div>
          {pageInfo.text}
        </div>

        <div className={styles.CTAs}>
          <SupportContactCard contact="+246 430 4600" title="Call Guardian">
            {pageInfo.supportCard}
          </SupportContactCard>
        </div>
      </GridWrapper>
    </OnboardingLayout>
  );
};

export default NoAvailableCoverage;
