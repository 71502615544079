import React from "react";
import { FormValue, Radio, RadioGroup } from "informed";
import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";

import { useHomeContext } from "~/contexts/HomeProvider";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";

import HomeImage from "~/assets/images/illustration-home.png";
import FlatImg from "~/assets/images/illustration-flat.png";

import { H3 } from "~/components/Typography";
import TileCard from "~/components/TileCard";

import { useCustomFieldState } from "~/helpers/hooks/useCustomFieldState";

const TYPE_BUILDING = [
  {
    title: "Single Family Home",
    text: "A detached, stand-alone house.",
    type: "private",
    img: HomeImage,
  },
  {
    title: "Block / Flats / Townhouse",
    text: "A home that shares one or more walls with another home.",
    type: "condominium",
    img: FlatImg,
  },
];

export default function BuildingType({ nextPath }: { nextPath?: string }) {
  const history = useHistory();
  const homeCtx = useHomeContext();
  const heapCtx = useHeapContext();
  const fieldName = "buildingType";
  const { value } = useCustomFieldState(fieldName);

  const handleOnNext = (value: FormValue) => {
    heapCtx.track(HeapEventName.HOME_BUILDING_TYPE, {
      "Building Type": value.toString(),
    });
    homeCtx.setState({ buildingType: value as string });
    if (nextPath) history.push(nextPath);
  };

  return (
    <fieldset>
      <legend>
        <H3 component="h1">What type of building is your home?</H3>
      </legend>
      <div className={styles.BuildingTypeContent}>
        <RadioGroup field={fieldName} keepState>
          {TYPE_BUILDING.map(({ text, title, img, type }, index) => (
            <TileCard
              key={title}
              title={title}
              text={text}
              className={styles.Tile}
              inputId={`item-${index}`}
              checked={value === title}
              inputElement={
                <Radio
                  id={`item-${index}`}
                  field={`${fieldName}-${index}`}
                  value={title}
                  className={styles.HideRadio}
                  checked={value === title}
                  name={fieldName}
                  onClick={() => handleOnNext(type)}
                />
              }
              left={
                <img
                  src={img}
                  alt={`Icon ${title}`}
                  aria-hidden
                  className={styles.TitleIcon}
                />
              }
            />
          ))}
        </RadioGroup>
      </div>
    </fieldset>
  );
}
