import React from "react";
import { ApolloClient, NormalizedCacheObject } from "apollo-boost";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "@apollo/react-hooks";
import { setContext } from "apollo-link-context";
import Loading from "~/components/Loading";
import { getItem } from "~/helpers/storage";

const uri = process.env.REACT_APP_WITH_NGROK
  ? process.env.REACT_APP_API_URL_NGROK
  : process.env.REACT_APP_ENV === "local" && !process.env.REACT_APP_API_URL
  ? `${window.location.protocol}//${window.location.hostname}:8080`
  : process.env.REACT_APP_API_URL;

// eslint-disable-next-line no-alert
const httpLink = createHttpLink({
  uri: `${uri}/graphql`,
  //TODO: we need to bring this back when we implement authentication
  // credentials: "include",
});

const authLink = setContext((_, { headers }) => {
  const localStorageToken = getItem("token");
  let parsedToken;
  if (localStorageToken) {
    parsedToken = JSON.parse(localStorageToken);
  }

  return {
    headers: {
      ...headers,
      authorization: parsedToken ? `${parsedToken.idToken}` : "",
    },
  };
});

let client: ApolloClient<NormalizedCacheObject> | undefined;

type PropsType = {
  children: React.ReactNode;
};

export const ApolloManager = (props: PropsType) => {
  client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });

  if (!client) {
    return <Loading />;
  }

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
