import { FormValue } from "informed";
import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import LoadingWrapper from "~/components/LoadingWrapper";
import SearchList from "~/components/SearchList";
import { Bold, H3, Text } from "~/components/Typography";
import VehicleSearchDisplaySmall from "~/components/VehicleSearchDisplaySmall";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useMotorContext } from "~/contexts/MotorProvider";
import pushWithParams from "~/helpers/pushWithParams";
import { InsuranceType } from "../../types";
import { gql } from "apollo-boost";

import styles from "./style.module.scss";

const UPDATE_MOTOR_OCCUPATION = gql`
  mutation updateMotorOccupation($policyId: String!, $occupation: String!) {
    updateMotorOccupation(policyId: $policyId, occupation: $occupation)
  }
`;
const UPDATE_HOME_OCCUPATION = gql`
  mutation updateHomeOccupation($policyId: String!, $occupation: String!) {
    updateHomeOccupation(policyId: $policyId, occupation: $occupation)
  }
`;

type Props = {
  nextPath?: string;
  insuranceType: InsuranceType;
};

const Occupation = ({ nextPath, insuranceType }: Props) => {
  const motorCtx = useMotorContext();
  const homeCtx = useHomeContext();
  const history = useHistory();
  const heapCtx = useHeapContext();
  const [showLoading, setShowLoading] = React.useState<boolean>(false);
  const [updateOccupation] = useMutation(
    insuranceType === "motor" ? UPDATE_MOTOR_OCCUPATION : UPDATE_HOME_OCCUPATION
  );
  const occupations = [
    "Other",
    ...(insuranceType === "home" ? ["Student"] : []),
    "Attorney-at-Law",
    "Accountant",
    "Administrative Assistant",
    "Architect",
    "Banker",
    "Bartender",
    "Chief Executive Officer",
    "Civil Servant",
    "Clergy",
    "Consultant",
    "Dentist",
    "Driver",
    "Economist",
    "Educator",
    "Engineer",
    "Entertainer or DJ",
    "Insurance Executive",
    "Journalist",
    "Librarian",
    "Management and Business Consultant",
    "Manager",
    "Medical Practitioner",
    "Nurse",
    "Pilot",
    "Police Officer",
    "Professor",
    "Psychologist",
    "QEH Staff",
    "Retired",
    "Salesperson",
    "Self Employed",
    "Senior Manager",
    "Social Worker",
    "Soldier",
    "Systems Administrator",
    "Teacher",
    "University Lecturer",
    "Veterinarian",
    "Waiter or Waitress",
    "Unemployed",
  ];

  const handleChange = async (value: FormValue) => {
    if (insuranceType === "motor") {
      await motorCtx.savingsInfo.setItem("occupation", value as string);
    } else {
      await homeCtx.setState({ occupation: value as string });
    }

    heapCtx.addUserProperties({
      Occupation: value as string,
    });

    heapCtx.track(
      insuranceType === "motor"
        ? HeapEventName.MOTOR_OCCUPATION
        : HeapEventName.HOME_OCCUPATION,
      {
        Occupation: value as string,
      }
    );

    setShowLoading(true);

    try {
      await updateOccupation({
        variables: {
          policyId:
            insuranceType === "motor"
              ? motorCtx.policyInfo.data.id
              : homeCtx.policyId,
          occupation: value as string,
        },
      });
      setShowLoading(false);
    } catch (error) {
      setShowLoading(false);
      throw error;
    }

    if (nextPath) {
      pushWithParams(history, nextPath);
    }
  };

  if (showLoading) {
    return (
      <div className={styles.LoadingWrapper}>
        <LoadingWrapper loading={showLoading} />
      </div>
    );
  }

  return (
    <fieldset>
      <legend>
        {insuranceType === "motor" && (
          <VehicleSearchDisplaySmall
            prefix={`Your ${motorCtx?.vehicleInfo?.data?.type?.toLowerCase()} `}
          />
        )}

        <H3 fontWeight="bold" component="h1">
          What is your occupation? Choose the best match.
        </H3>

        <Text className={styles.SubHeader}>
          If unsure, please choose&nbsp;
          <Bold>&apos;Other&apos;</Bold>
        </Text>
      </legend>

      <SearchList
        field="occupation"
        list={occupations}
        onChange={handleChange}
        keepState
        classNameGrid={styles.GridList}
        excludeFromFilter={["Other"]}
        noResultsCopy="Try to type something new, or select Other if you cannot find
        your occupation."
      />
    </fieldset>
  );
};

export default Occupation;
