import React from "react";
import { useField, FieldProps, FormValue, FormValues } from "informed";
import { TextSmall } from "~/components/Typography";
import Button from "~/components/Button";
import styles from "./index.module.scss";

interface ITextListProps extends FieldProps<FormValue<string[]>, FormValues> {
  addValueButtonText: string;
  label?: string;
}

const TextListInput = (props: ITextListProps) => {
  const { placeholder, label, addValueButtonText } = props;
  const { fieldState, fieldApi } = useField(props);
  const { value } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const spread = typeof value === "object" ? value : [];
  const [addValue, setAddValue] = React.useState(false);

  // Used for the last empty input box
  const addValueFunc = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== "") {
      setValue([...spread, e.target.value]);
      setAddValue(false);
    }
  };

  // Used for modifying and deleting exisiting input boxes
  const updateValue = (index: number) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value === "") {
      // Remove element at index
      setValue([...spread.slice(0, index), ...spread.slice(index + 1)]);
    } else {
      // Replace element at index
      setValue([
        ...spread.slice(0, index),
        e.target.value,
        ...spread.slice(index + 1),
      ]);
    }
  };

  const inputs = (
    <div className={styles.TextListInputWrapper}>
      {
        // The last item in the array is the empty input field required to add a new element.
        [...spread, ...(addValue ? [""] : [])].map((text, idx) => (
          <input
            className={styles.TextInput}
            key={idx}
            value={text}
            onChange={
              addValue && idx === spread.length
                ? addValueFunc
                : updateValue(idx)
            }
            type="text"
            placeholder={placeholder}
            onBlur={() => setTouched(true)}
          />
        ))
      }
      <Button
        disabled={addValue}
        size="small"
        variant="selector"
        onClick={() => {
          setAddValue(true);
        }}
      >
        {addValueButtonText}
      </Button>
    </div>
  );

  return label ? (
    <label>
      {label && <TextSmall className={styles.InputLabel}>{label}</TextSmall>}
      {inputs}
    </label>
  ) : (
    inputs
  );
};

export default TextListInput;
