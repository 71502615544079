import { FormValue } from "informed";
import React from "react";
import { useHistory } from "react-router-dom";
import LoadingWrapper from "~/components/LoadingWrapper";
import RadioBinary from "~/components/RadioBinary";
import { H3 } from "~/components/Typography";
//import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { NO_COVERAGE_URL } from "~/routes/index.constant";

type Props = {
  nextPath?: string;
};

export default function GoodState({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const history = useHistory();
  const homeCtx = useHomeContext();

  //const checklistCtx = useChecklistContext();
  const [saving, setSaving] = React.useState<boolean>(false);

  useMountEffect(() => {
    headerCtx.setState({
      totalOfSteps: 6,
      currentStep: 3,
      title: "Property Information",
    });
  });

  const handleNextClick = (value: FormValue) => {
    if (!value) {
      homeCtx.setState({ isGoodState: false });
      history.push(`${NO_COVERAGE_URL}?message=home-good-state`);
      return;
    }
    try {
      if (nextPath) {
        homeCtx.setState({ isGoodState: true });
        setSaving(true);
        history.push(nextPath);
      }
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          <H3>
            Is your home in a good state of repair and will it be so maintained?
          </H3>

          <RadioBinary
            handleClick={handleNextClick}
            field="isInGoodState"
          ></RadioBinary>
        </>
      )}
    </>
  );
}
