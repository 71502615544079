import { useEffect } from "react";
import { useUserContext } from "~/contexts/UserProvider";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const LOG_ERRORS = gql`
  query sendErrorLog($input: ErrorLog!) {
    sendErrorLog(input: $input) {
      type
      success
    }
  }
`;

// Functional component that logs the Unexpected Error to Azure
export const CallErrorLog = (props: { stackTrace: string }) => {
  const user = useUserContext();

  const sendErrorLog = useQuery(LOG_ERRORS, {
    variables: {
      input: {
        type: "Unexpected UI Error",
        message: "User encountered an unexpected error",
        stackTrace: props.stackTrace,
        userData: {
          email: user?.email ?? null,
          phoneNumber: user?.phoneNumber ?? null,
        },
      },
    },
    fetchPolicy: "no-cache",
  });

  const { data = {} } = sendErrorLog;

  useEffect(() => {
    if (data && data?.sendErrorLog) {
      console.log("Error log response: ", data.sendErrorLog);
    }
  }, [data]);

  return null;
};
