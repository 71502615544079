import React from "react";
import gql from "graphql-tag";
import { useFieldState } from "informed";
import { useMutation } from "@apollo/react-hooks";
import styles from "./index.module.scss";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import hasErrorCode from "~/helpers/hasErrorCode";
import { ToolProps } from "../..";

enum Field {
  Passcode = "AdminWipeDataForEmail-Passcode",
}

const WIPE_DATA_FOR_EMAIL = gql`
  mutation adminWipeDataForEmail($email: String!, $passcode: String!) {
    adminWipeDataForEmail(email: $email, passcode: $passcode) {
      webstarId
      externalId
      activeCampaignId
      auth0Deleted
    }
  }
`;

const AdminWipeDataForEmail = ({ identifier, onClose }: ToolProps) => {
  const passcodeState = useFieldState(Field.Passcode);

  const [processing, setProcessing] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  const [wipeDataForEmail] = useMutation(WIPE_DATA_FOR_EMAIL);

  const handleSubmit = async () => {
    setProcessing(true);

    try {
      const response = await wipeDataForEmail({
        variables: {
          email: identifier,
          passcode: String(passcodeState.value),
        },
      });

      const data = response?.data?.adminWipeDataForEmail;
      setProcessing(false);

      onClose({
        manuallyClosed: false,
        success: true,
        shouldReload: true,
        message: `Successful! Webstar ID: ${
          data?.webstarId ? data.webstarId : "Undefined"
        }. External ID: ${
          data?.externalId ? data.externalId : "Undefined"
        }. Active Campaign ID: ${
          data?.activeCampaignId ? data.activeCampaignId : "Undefined"
        }. Auth0 User Deleted: ${data?.auth0Deleted ? "Yes" : "No"}.`,
      });
    } catch (error) {
      if (error instanceof Error) {
        if (hasErrorCode(error, "NOT_ADMIN")) {
          onClose({
            manuallyClosed: false,
            success: false,
            errorCode: "NOT_ADMIN",
          });
        }

        setErrorMessage(error.message);
      }

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      {errorMessage && <div className={styles.Error}>{errorMessage}</div>}

      <>
        <TextInput
          type="text"
          label="Passcode"
          placeholder="Passcode"
          field={Field.Passcode}
        />

        <Button
          disabled={!passcodeState.value || processing}
          onClick={handleSubmit}
        >
          {processing ? "Processing..." : "Process"}
        </Button>
      </>
    </div>
  );
};

export default AdminWipeDataForEmail;
