import { useFormState } from "informed";
import React from "react";
import { useHistory } from "react-router-dom";
import LoadingWrapper from "~/components/LoadingWrapper";
import RadioBinary from "~/components/RadioBinary";
import SubmitButton from "~/components/SubmitButton";
import TextInput from "~/components/TextInput";
import { H3, Text } from "~/components/Typography";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import styles from "./index.module.scss";

type Props = {
  nextPath?: string;
};

export default function OtherInsurance({ nextPath }: Props) {
  const headerCtx = useHeaderContext();
  const heapCtx = useHeapContext();
  const history = useHistory();
  const checklistCtx = useChecklistContext();
  const [hasOtherInsurance, setHasOtherInsurance] = React.useState<boolean>(
    false
  );
  const [saving, setSaving] = React.useState<boolean>(false);
  const otherInsuranceDetailsField = "otherInsuranceDetails";
  const formState = useFormState();

  useMountEffect(() => {
    heapCtx.track(HeapEventName.HOME_OTHER_INSURANCE_SCREEN, {});
    headerCtx.setState({
      totalOfSteps: 6,
      currentStep: 3,
      title: "Property Information",
    });
  });

  const handleHasOtherInsurance = async (hasOtherInsurance: boolean) => {
    if (hasOtherInsurance) {
      setHasOtherInsurance(true);
    } else {
      setSaving(true);

      await checklistCtx.saveChecklist({
        policyId: checklistCtx.policyId,
        home: {
          ...checklistCtx.home,
          otherInsurance: undefined,
        },
        nextPath,
      });

      heapCtx.track(HeapEventName.HOME_OTHER_INSURANCE, {
        "Policy ID": checklistCtx.policyId,
        "Other Insurance": false,
      });

      if (nextPath) {
        history.push(nextPath);
      }
    }
  };

  const handleOtherInsuranceDetails = async () => {
    try {
      if (nextPath) {
        setSaving(true);

        heapCtx.track(HeapEventName.HOME_OTHER_INSURANCE, {
          "Policy ID": checklistCtx.policyId,
          "Other Insurance": true,
        });

        await checklistCtx.saveChecklist({
          policyId: checklistCtx.policyId,
          home: {
            ...checklistCtx.home,
            otherInsurance:
              String(formState.values[otherInsuranceDetailsField]) ?? null,
          },
          nextPath,
        });

        history.push(nextPath);
      }
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };

  return (
    <>
      <LoadingWrapper loading={saving} />

      {!saving && (
        <>
          {!hasOtherInsurance && (
            <>
              <H3>Do you have other insurance(s) on this property?</H3>

              <RadioBinary
                handleClick={handleHasOtherInsurance}
                field="hasOtherInsurance"
              ></RadioBinary>
            </>
          )}

          {hasOtherInsurance && (
            <div className={styles.Content}>
              <H3>Please provide some details about your other insurance.</H3>

              <Text>
                Let us know who you have coverage with, and what type of
                coverage it is.
              </Text>

              <TextInput
                field={otherInsuranceDetailsField}
                placeholder="Other insurance details"
                type="text"
                initialValue={
                  checklistCtx.home.otherInsurance
                    ? String(checklistCtx.home.otherInsurance)
                    : ""
                }
              />

              <SubmitButton
                id="OtherInsurance-SubmitButton"
                className={styles.ContinueButton}
                onClick={() => {
                  handleOtherInsuranceDetails();
                }}
                disabled={!formState.values[otherInsuranceDetailsField]}
              >
                Next
              </SubmitButton>
            </div>
          )}
        </>
      )}
    </>
  );
}
