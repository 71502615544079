import { Form } from "informed";
import React from "react";
import { Route, Switch } from "react-router-dom";
import AssistantProvider from "~/contexts/AlmiAssistantProvider";
import {
  ADMIN_CUSTOMER_DETAILS_URL,
  ADMIN_DASHBOARD_URL,
  ADMIN_POLICY_DETAILS_URL,
} from "~/routes/index.constant";
import AdminCustomerDetailView from "./AdminCustomerDetailView";
import AdminCustomerListView from "./AdminCustomerListView";
import AdminNewVehicle from "./AdminNewVehicle";
import AdminPolicyDetailView from "./AdminPolicyDetailView";
import AdminPolicyListView from "./AdminPolicyListView";
import AdminSearch from "./AdminSearch";

export type ToolOnCloseProps = {
  manuallyClosed: boolean;
  success: boolean;
  shouldReload?: boolean;
  message?: string;
  errorCode?: string;
};

export type ToolProps = {
  identifier: string;
  onClose: (params: ToolOnCloseProps) => void;
};

const AdminDashboard = () => {
  return (
    <AssistantProvider>
      <Form>
        <Switch>
          <Route exact path={ADMIN_DASHBOARD_URL} component={AdminSearch} />

          <Route
            exact
            path={`${ADMIN_DASHBOARD_URL}/policies`}
            component={AdminPolicyListView}
          />

          <Route
            exact
            path={`${ADMIN_POLICY_DETAILS_URL}/:policyId`}
            component={AdminPolicyDetailView}
          />

          <Route
            exact
            path={`${ADMIN_DASHBOARD_URL}/customers`}
            component={AdminCustomerListView}
          />

          <Route
            exact
            path={`${ADMIN_CUSTOMER_DETAILS_URL}/:customerId`}
            component={AdminCustomerDetailView}
          />

          <Route
            exact
            path={`${ADMIN_DASHBOARD_URL}/add-vehicle`}
            component={AdminNewVehicle}
          />
        </Switch>
      </Form>
    </AssistantProvider>
  );
};

export default AdminDashboard;
