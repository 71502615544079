import React from "react";
import { useHistory } from "react-router-dom";
import { useFormApi } from "informed";
import { useMotorContext } from "~/contexts/MotorProvider";
import { useOnboardContext } from "~/contexts/OnboardProvider";
import styles from "./index.module.scss";
import { H3, TextSmall, Bold, Text } from "~/components/Typography";
import SubmitButton from "~/components/SubmitButton";
import NoteWithList from "~/components/NoteWithList";
import pushWithParams from "~/helpers/pushWithParams";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import { addIsoDate, formatIsoDate, todaysDateInAst } from "~/helpers/dates";
import { getYear } from "date-fns";

const ROADWORTHY_CERT_YEARS = 10;

type Props = {
  nextPath?: string;
};

export default function CoverageNote({ nextPath }: Props) {
  const history = useHistory();
  const motorCtx = useMotorContext();
  const onboardCtx = useOnboardContext();
  const heapCtx = useHeapContext();
  const form = useFormApi();
  const daysFromNow = 7;
  const documentDueDate = formatIsoDate(
    addIsoDate(todaysDateInAst(), { days: daysFromNow }),
    "MMM dd, yyyy"
  );

  const documentList = [
    "Your Motor Purchase Letter",
    "Your Motor Registration Card",
    "Your Vehicle valuation",
    "Your Assignment Letter (if your vehicle is financed)",
  ];

  if ((motorCtx.policyInfo.data.yearsWithoutClaims || 0) >= 1) {
    documentList.push("Your No Claims Discount Letter");
  }

  if (
    (motorCtx.vehicleInfo.data.year || 0) + ROADWORTHY_CERT_YEARS <=
    getYear(new Date())
  ) {
    documentList.push("Your Roadworthy Certificate Letter");
  }

  const handleNext = () => {
    heapCtx.track(HeapEventName.ONBOARDING_COVERAGE_SUCCESS_CONTINUE, {
      "Policy ID": motorCtx.policyInfo.data.id,
    });

    motorCtx.vehicleInfo.removeStore();
    motorCtx.savingsInfo.removeStore();
    motorCtx.policyInfo.removeStore();
    onboardCtx.removeStore();
    form.reset();

    if (nextPath) {
      pushWithParams(history, nextPath);
    } else {
      pushWithParams(history, "/portal");
    }
  };

  return (
    <div className={styles.Content}>
      <H3 className={styles.Title} component="h1">
        Congratulations! Your temporary Cover Note is ready for download!
      </H3>

      <TextSmall>
        You will find all of your policy documents under{" "}
        <Bold>Policy Details</Bold> in the Portal. Please print and keep a copy
        of your Cover Note in your vehicle.
      </TextSmall>

      <TextSmall className={styles.CoveredPeriod}>
        <Bold>Your cover note is valid only for 30 days.</Bold>
      </TextSmall>

      <NoteWithList
        mainText={
          <Text>
            <Bold>Important next steps</Bold>
            <br />
            To complete your coverage plan please upload the following documents
            by {documentDueDate} ({daysFromNow} days from now) :
          </Text>
        }
        list={documentList}
        className={styles.Note}
      />

      <SubmitButton
        id="CoverageNote-SubmitButton"
        onClick={handleNext}
        className={styles.BtnNext}
      >
        Continue to Portal
      </SubmitButton>
    </div>
  );
}
