import React from "react";
import Checkbox from "~/components/Checkbox";
import TileCard from "~/components/TileCard";
import { H3 } from "~/components/Typography";
import { formatCurrency } from "~/helpers/currency";
import styles from "./index.module.scss";
import classnames from "classnames";
import Icon from "~/components/Icon";
import Button from "~/components/Button";
import SubmitButton from "~/components/SubmitButton";
import { useHistory } from "react-router";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import SelectableCard from "~/components/SelectableCard";
import { IContentsType, useHomeContext } from "~/contexts/HomeProvider";
import { useHeapContext, HeapEventName } from "~/contexts/HeapProvider";
import pushWithParams from "~/helpers/pushWithParams";
import Modal from "~/components/Modal";
import ContentItemModal from "../../../components/ContentItemModal";

type Props = {
  nextPath?: string;
};

export default function AllRiskItems({ nextPath }: Props) {
  const history = useHistory();
  const headerCtx = useHeaderContext();
  const homeCtx = useHomeContext();
  const heapCtx = useHeapContext();
  const [openModal, setOpenModal] = React.useState(false);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [selectedItemIndex, setSelectedItemIndex] = React.useState<number>();
  const [hasContentItems, setHasContentItems] = React.useState<boolean>(false);

  useMountEffect(() => {
    headerCtx.setState({
      totalOfSteps: 3,
      currentStep: 1,
      title: "Content Details",
    });

    return () => {
      if (openModal) {
        setOpenModal(false);
      }
    };
  });

  React.useEffect(() => {
    setDisabled(homeCtx.getAllRiskItemsTotal() <= 0);

    setHasContentItems(
      homeCtx.contents !== undefined &&
        homeCtx.contents.some((item) => {
          return !item.createdAsAllRisk;
        })
    );
  }, [homeCtx, homeCtx.contents]);

  const handleCheckboxClick = (index: number) => {
    const contents: IContentsType[] = homeCtx.contents ?? [];

    if (contents[index]) {
      contents[index].isAllRisk = !contents[index].isAllRisk;
    }

    homeCtx.setState({
      contents: [...contents],
    });
  };

  const onNextClick = () => {
    heapCtx.track(HeapEventName.HOME_ALL_RISK_ITEMS, {
      "Number of Contents": Array.isArray(homeCtx.contents)
        ? homeCtx.contents.length
        : 0,
    });
    if (nextPath) {
      pushWithParams(history, nextPath);
    }
  };

  const handleOpenAddItem = () => {
    setSelectedItemIndex(undefined);
    setOpenModal(true);
  };

  const handleOpenEditItem = (index: number) => {
    setSelectedItemIndex(index);
    setOpenModal(true);
  };

  const handleModalSave = (savedItem: IContentsType) => {
    const contents = homeCtx.contents ?? [];

    if (selectedItemIndex !== undefined && contents[selectedItemIndex]) {
      contents[selectedItemIndex] = savedItem;
    } else {
      contents.push(savedItem);
    }

    homeCtx.setState({
      contents: [...contents],
    });

    heapCtx.track(HeapEventName.HOME_CONTENTS_ITEMS_DETAILS_ADD, {
      Content: savedItem?.name,
      Value: savedItem?.value,
      "Purchase Date": savedItem?.purchaseDate,
    });

    setOpenModal(false);
    setSelectedItemIndex(undefined);
  };

  const handleModalDelete = () => {
    const contents = homeCtx.contents ?? [];

    if (selectedItemIndex !== undefined && contents[selectedItemIndex]) {
      heapCtx.track(HeapEventName.HOME_CONTENTS_ITEMS_DETAILS_REMOVE, {
        Content: contents[selectedItemIndex]?.name,
        Value: contents[selectedItemIndex]?.value,
        "Purchase Date": contents[selectedItemIndex]?.purchaseDate,
      });
      contents.splice(selectedItemIndex, 1);
    }

    homeCtx.setState({
      contents: [...contents],
    });

    setOpenModal(false);
    setSelectedItemIndex(undefined);
  };

  return (
    <>
      <H3>Select the items you want protected by all risks insurance.</H3>

      {hasContentItems && (
        <>
          <div className={styles.ItemContainer}>
            {homeCtx.contents?.map((item, index) => {
              return !item.createdAsAllRisk ? (
                <div
                  className={styles.ItemWrapper}
                  key={`AllRiskItems-ItemWrapper-1-${index}`}
                >
                  <TileCard
                    title={item.name}
                    text={formatCurrency(item.value, false)}
                    checked={item.isAllRisk}
                    inputId={`AllRiskItems-TileCard-${index}`}
                    inputElement={
                      <Checkbox
                        id={`AllRiskItems-TileCard-${index}`}
                        field="selectedItem"
                        className={styles.HideCheckbox}
                        onChange={() => {
                          handleCheckboxClick(index);
                        }}
                      />
                    }
                    left={
                      <div
                        className={classnames(styles.CheckBoxIcon, {
                          [styles.Checked]: item.isAllRisk,
                        })}
                      >
                        {item.isAllRisk && (
                          <Icon name="CheckSquare" backgroundColor="#FF6B28" />
                        )}
                      </div>
                    }
                    right={
                      item.isAllRisk ? (
                        <Button
                          variant="tertiary"
                          size="small"
                          onClick={() => {
                            handleOpenEditItem(index);
                          }}
                        >
                          Edit
                        </Button>
                      ) : undefined
                    }
                  />
                </div>
              ) : undefined;
            })}
          </div>

          <hr className={styles.Divider} />
        </>
      )}

      <div className={styles.ItemContainer}>
        {homeCtx.contents?.map((item, index) => {
          return item.createdAsAllRisk ? (
            <div
              className={styles.ItemWrapper}
              key={`AllRiskItems-ItemWrapper-2-${index}`}
            >
              <TileCard
                title={item.name}
                text={formatCurrency(item.value, false)}
                checked={item.isAllRisk}
                inputId={`AllRiskItems-TileCard-${index}`}
                inputElement={undefined}
                left={
                  <Icon
                    name="CircleCheck"
                    backgroundColor="#FF6B28"
                    fill="white"
                    className={styles.CircleCheck}
                  />
                }
                right={
                  <Button
                    variant="tertiary"
                    size="small"
                    onClick={() => {
                      handleOpenEditItem(index);
                    }}
                  >
                    Edit
                  </Button>
                }
              />
            </div>
          ) : undefined;
        })}

        <div className={styles.ItemWrapper}>
          <SelectableCard
            key="AllRiskItems-TileCard-Add"
            className={styles.AddButton}
            title="Add new item"
            description="Tap to add"
            selected={false}
            onClick={handleOpenAddItem}
          />
        </div>
      </div>

      <Modal
        isOpen={openModal}
        title={selectedItemIndex !== undefined ? "Edit Item" : "Add Item"}
        shouldOverlayCloseOnClick
        onClose={() => setOpenModal(false)}
      >
        <ContentItemModal
          originalContentItem={
            selectedItemIndex !== undefined
              ? homeCtx.contents?.[selectedItemIndex]
              : undefined
          }
          handleSave={handleModalSave}
          handleDelete={handleModalDelete}
          isAllRisk
        />
      </Modal>

      <div className={styles.ButtonWrapper}>
        <SubmitButton
          id="AllRiskItems-SubmitButton"
          className={styles.ContinueButton}
          onClick={onNextClick}
          disabled={disabled}
        >
          Next
        </SubmitButton>
      </div>
    </>
  );
}
