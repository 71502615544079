import React from "react";
import LoadingWrapper from "~/components/LoadingWrapper";
import { H3, H4, H5, H6, Text } from "~/components/Typography";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Button from "~/components/Button";
import { useHistory, useParams } from "react-router-dom";
import Modal from "~/components/Modal";
import { ToolOnCloseProps, ToolProps } from "..";
import AdminWipeDataForEmail from "../ConsumerTools/AdminWipeDataForEmail";
import { useAuth0Context, UserRoleType } from "~/contexts/Auth0Context";
import styles from "./index.module.scss";
import AdminChangePassword from "../ConsumerTools/AdminChangePassword";
import AdminCustomerPoliciesTable from "../AdminCustomerPoliciesTable";
import AdminDownloadDocument from "../AdminDownloadDocument";
import { VERIFICATION_STATUS } from "~/helpers/constants";
import AdminApproveDocuments from "../ConsumerTools/AdminApproveDocuments";

type CustomerTool = {
  displayName: string;
  element: React.FC<ToolProps>;
};

type CustomerDetails = {
  userId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  address: string;
  email: string;
  phoneNumber?: string;
  utmSource?: string;
  utmMedium?: string;
  utmCampaignName?: string;
  utmContent?: string;
  documents: Array<{
    documentType: string;
    context?: string;
    verificationStatus?: string;
  }>;
};

type GetCustomerDetails = {
  adminGetCustomerDetails: CustomerDetails;
};

const GET_CUSTOMER_DETAILS = gql`
  query adminGetCustomerDetails($email: String, $customerId: String) {
    adminGetCustomerDetails(email: $email, customerId: $customerId) {
      userId
      firstName
      lastName
      dateOfBirth
      address
      email
      phoneNumber
      utmSource
      utmMedium
      utmCampaignName
      utmContent
      documents {
        documentType
        context
        verificationStatus
      }
    }
  }
`;

const AdminCustomerDetailView = () => {
  const [customer, setCustomer] = React.useState<CustomerDetails | undefined>(
    undefined
  );

  const [selectedTool, setSelectedTool] = React.useState<
    CustomerTool | undefined
  >(undefined);

  const [successMessage, setSuccessMessage] = React.useState<
    string | undefined
  >(undefined);

  const auth0Context = useAuth0Context();
  const history = useHistory();

  const params: {
    customerId: string | undefined;
  } = useParams();

  const customerTools: CustomerTool[] = [
    {
      displayName: "Change Password",
      element: AdminChangePassword,
    },
    {
      displayName: "Wipe Data For Email",
      element: AdminWipeDataForEmail,
    },
    {
      displayName: "Approve Documents",
      element: AdminApproveDocuments,
    },
  ];

  const onClickChangePassword = () => {
    setSelectedTool(
      customerTools.find((tool) => {
        return tool.displayName === "Change Password";
      })
    );
  };

  const manuallyApproveDocuments = () => {
    setSelectedTool(
      customerTools.find((tool) => {
        return tool.displayName === "Approve Documents";
      })
    );
  };

  const { error, data, loading, refetch } = useQuery<GetCustomerDetails>(
    GET_CUSTOMER_DETAILS,
    {
      variables: {
        customerId: params.customerId,
      },
      fetchPolicy: "no-cache",
      skip: !params.customerId,
    }
  );

  const openWipeUserTool = () => {
    setSelectedTool(
      customerTools.find((tool) => {
        return tool.displayName === "Wipe Data For Email";
      })
    );
  };

  React.useEffect(() => {
    if (data) {
      setCustomer(data.adminGetCustomerDetails);
    }
  }, [data]);

  const handleToolClose = (params: ToolOnCloseProps) => {
    if (params.errorCode === "NOT_ADMIN") {
      auth0Context.logout();
    }

    if (params.success && params.message) {
      setSuccessMessage(params.message);
    }

    if (params.shouldReload) {
      refetch();
    }

    setSelectedTool(undefined);
  };

  const getCustomerToolComponent = (tool: CustomerTool) => {
    const SelectedTool = tool.element;

    return (
      <SelectedTool
        identifier={
          (tool.displayName === "Wipe Data For Email"
            ? customer?.email
            : params.customerId) ?? ""
        }
        onClose={handleToolClose}
      />
    );
  };

  return (
    <>
      {loading && <LoadingWrapper loading />}

      {error && (
        <div className={styles.Content}>
          <Text className={styles.ErrorMessage}>
            Customer could not be retreived.
          </Text>

          <Button
            className={styles.GoBackButton}
            onClick={() => {
              history.goBack();
            }}
          >
            Go Back
          </Button>
        </div>
      )}

      {customer && (
        <>
          <H3>Customer Details</H3>
          <H4>{customer.userId}</H4>

          {successMessage && (
            <div className={styles.SuccessMessage}>{successMessage}</div>
          )}

          <div className={styles.CardsContainer}>
            <div className={styles.DetailsCard}>
              <div className={styles.DetailsSection}>
                <div>
                  <H6>Personal Information</H6>
                  <H3>Profile Details</H3>
                </div>

                <div>
                  <H5>Name</H5>

                  <Text>
                    {customer?.firstName || customer?.lastName
                      ? `${customer?.firstName} ${customer?.lastName}`
                      : "N/A"}
                  </Text>
                </div>

                <div>
                  <H5>Date of Birth</H5>

                  <Text>
                    {customer?.dateOfBirth
                      ? new Date(customer.dateOfBirth).toLocaleDateString()
                      : "N/A"}
                  </Text>
                </div>

                <div>
                  <H5>Address</H5>
                  <Text>{customer?.address ?? "N/A"}</Text>
                </div>

                <div>
                  <H5>Email</H5>
                  <Text>{customer?.email ?? "N/A"}</Text>
                </div>

                <div>
                  <H5>Password</H5>
                  <Button text variant="text" onClick={onClickChangePassword}>
                    Change Password
                  </Button>
                </div>

                <div>
                  <H5>Phone Number</H5>
                  <Text>{customer?.phoneNumber ?? "N/A"}</Text>
                </div>

                {!customer?.utmSource &&
                !customer?.utmMedium &&
                !customer?.utmCampaignName &&
                !customer?.utmContent ? (
                  <H5>No UTM Data Available</H5>
                ) : (
                  <>
                    <div>
                      <H5>UTM Source</H5>
                      <Text>{customer?.utmSource ?? "N/A"}</Text>
                    </div>

                    <div>
                      <H5>UTM Medium</H5>
                      <Text>{customer?.utmMedium ?? "N/A"}</Text>
                    </div>

                    <div>
                      <H5>UTM Campaign Name</H5>
                      <Text>{customer?.utmCampaignName ?? "N/A"}</Text>
                    </div>

                    <div>
                      <H5>UTM Content</H5>
                      <Text>{customer?.utmContent ?? "N/A"}</Text>
                    </div>
                  </>
                )}
              </div>

              {auth0Context.hasPermission([UserRoleType.admin]) && (
                <Button
                  className={styles.WipeUserButton}
                  onClick={openWipeUserTool}
                  variant="secondary"
                  size="xsmall"
                >
                  Wipe User
                </Button>
              )}
            </div>

            <div className={styles.DocumentsCard}>
              <div className={styles.DocumentsHeader}>
                <H5>Documentation</H5>
                <H3>Customer Documents</H3>
              </div>

              {auth0Context.hasPermission([
                UserRoleType.admin,
                UserRoleType.cxo,
              ]) &&
                customer?.documents.some((document) => {
                  return (
                    document.verificationStatus &&
                    document.verificationStatus !== VERIFICATION_STATUS.approved
                  );
                }) && (
                  <Button
                    className={styles.DocumentsApproveButton}
                    onClick={manuallyApproveDocuments}
                    size="xsmall"
                  >
                    Approve All Documents
                  </Button>
                )}

              {customer?.documents.map((document, index) => {
                return (
                  <AdminDownloadDocument
                    key={`AdminDownloadDocument-${index}`}
                    customerId={customer?.userId}
                    title={document.documentType}
                    subTitle={document.context ?? ""}
                    documentType={document.documentType}
                    context={document.context}
                    verificationStatus={document.verificationStatus}
                  />
                );
              })}
            </div>
          </div>

          <H3>Customer Policy Summary</H3>

          <div className={styles.PoliciesSection}>
            <AdminCustomerPoliciesTable customerId={params.customerId} />
          </div>

          <Modal
            title={selectedTool?.displayName}
            isOpen={selectedTool !== undefined}
            shouldOverlayCloseOnClick
            onClose={() => setSelectedTool(undefined)}
            id="customer-tool-modal"
          >
            {selectedTool !== undefined &&
              getCustomerToolComponent(selectedTool)}
          </Modal>
        </>
      )}
    </>
  );
};

export default AdminCustomerDetailView;
