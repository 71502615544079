import { useFieldState } from "informed";
import React from "react";
import Button from "~/components/Button";
import TextInput from "~/components/TextInput";
import cleanNumberInput from "~/helpers/formatters/cleanNumberInput";
import styles from "./index.module.scss";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import hasErrorCode from "~/helpers/hasErrorCode";
import { ToolProps } from "../..";
import Checkbox from "~/components/Checkbox";
import { useAuth0Context, UserRoleType } from "~/contexts/Auth0Context";

enum Field {
  Amount = "AdminManualSinglePayment-Amount",
  PayLateOnly = "AdminManualSinglePayment-PayLateOnly",
  Passcode = "AdminManualSinglePayment-Passcode",
}

const MANUAL_SINGLE_PAYMENT = gql`
  mutation adminManualSinglePayment(
    $policyId: String!
    $amount: Float!
    $passcode: String!
    $payLateOnly: Boolean!
  ) {
    adminManualSinglePayment(
      policyId: $policyId
      amount: $amount
      passcode: $passcode
      payLateOnly: $payLateOnly
    ) {
      singleChargeAmount
    }
  }
`;

const AdminManualSinglePayment = ({ identifier, onClose }: ToolProps) => {
  const amountState = useFieldState(Field.Amount);
  const payLateOnlyState = useFieldState(Field.PayLateOnly);
  const passcodeState = useFieldState(Field.Passcode);
  const auth0Context = useAuth0Context();

  const hasAdminPermission = React.useMemo(() => {
    return auth0Context.hasPermission([UserRoleType.admin]);
  }, [auth0Context]);

  const [processing, setProcessing] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  const [manualSinglePayment] = useMutation(MANUAL_SINGLE_PAYMENT);

  const handleSubmit = async () => {
    setProcessing(true);

    try {
      const response = await manualSinglePayment({
        variables: {
          policyId: identifier,
          amount: cleanNumberInput(amountState.value, true),
          payLateOnly: !!payLateOnlyState.value,
          passcode: String(passcodeState.value),
        },
      });

      setProcessing(false);
      const data = response?.data?.adminManualSinglePayment;

      if (data.singleChargeAmount) {
        onClose({
          manuallyClosed: false,
          success: true,
          shouldReload: true,
          message: `User has been charged the amount of $${data.singleChargeAmount}.`,
        });
      } else {
        setErrorMessage("Error: No charge created.");
      }
    } catch (error) {
      if (error instanceof Error) {
        if (hasErrorCode(error, "NOT_ADMIN")) {
          onClose({
            manuallyClosed: false,
            success: false,
            errorCode: "NOT_ADMIN",
          });
        }

        setErrorMessage(error.message);
      }

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      {errorMessage && <div className={styles.Error}>{errorMessage}</div>}

      <>
        <TextInput
          type="text"
          label="Single Payment Amount"
          placeholder="0.00"
          inputMode="decimal"
          field={Field.Amount}
        />

        <Checkbox
          className={styles.SmallMarginTop}
          field={Field.PayLateOnly}
          label="Pay Late Only"
          initialValue
          disabled={!hasAdminPermission}
        />

        <TextInput
          type="text"
          label="Passcode"
          placeholder="Passcode"
          field={Field.Passcode}
        />

        <Button
          disabled={!amountState.value || !passcodeState.value || processing}
          onClick={handleSubmit}
        >
          {processing ? "Processing..." : "Process"}
        </Button>
      </>
    </div>
  );
};

export default AdminManualSinglePayment;
