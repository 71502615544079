import React from "react";
import gql from "graphql-tag";
import { useFieldState } from "informed";
import { useMutation } from "@apollo/react-hooks";
import hasErrorCode from "~/helpers/hasErrorCode";
import styles from "./index.module.scss";
import TextInput from "~/components/TextInput";
import Button from "~/components/Button";
import { ToolProps } from "../..";

enum Field {
  Passcode = "AdminApproveDocuments-Passcode",
}

const APPROVE_DOCUMENTS = gql`
  mutation adminApproveDocuments($customerId: String!, $passcode: String!) {
    adminApproveDocuments(customerId: $customerId, passcode: $passcode)
  }
`;

const AdminApproveDocuments = ({ identifier, onClose }: ToolProps) => {
  const passcodeState = useFieldState(Field.Passcode);

  const [processing, setProcessing] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  const [approveDocuments] = useMutation(APPROVE_DOCUMENTS);

  const handleSubmit = async () => {
    setProcessing(true);

    try {
      const response = await approveDocuments({
        variables: {
          customerId: identifier,
          passcode: String(passcodeState.value),
        },
      });

      const data = response?.data?.adminApproveDocuments;

      if (!data) {
        throw new Error("Failed to approve documents.");
      }

      setProcessing(false);

      onClose({
        manuallyClosed: false,
        success: true,
        shouldReload: true,
        message: "Documents successfully approved.",
      });
    } catch (error) {
      if (error instanceof Error) {
        if (hasErrorCode(error, "NOT_ADMIN")) {
          onClose({
            manuallyClosed: false,
            success: false,
            errorCode: "NOT_ADMIN",
          });
        }

        setErrorMessage(error.message);
      }

      setProcessing(false);
    }
  };

  return (
    <div className={styles.Content}>
      {errorMessage && <div className={styles.Error}>{errorMessage}</div>}

      <>
        <TextInput
          type="text"
          label="Passcode"
          placeholder="Passcode"
          field={Field.Passcode}
        />

        <Button
          disabled={!passcodeState.value || processing}
          onClick={handleSubmit}
        >
          {processing ? "Processing..." : "Process"}
        </Button>
      </>
    </div>
  );
};

export default AdminApproveDocuments;
