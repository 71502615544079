import React from "react";
import SubmitButton from "~/components/SubmitButton";
import { H3, H5, Text, TextSmall } from "~/components/Typography";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { formatCurrency } from "~/helpers/currency";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";
import classnames from "classnames";
import styles from "./index.module.scss";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useHistory } from "react-router";

type Props = {
  nextPath?: string;
  countryName: string;
};

export default function InsuranceReview({ nextPath, countryName }: Props) {
  const history = useHistory();
  const headerCtx = useHeaderContext();
  const homeCtx = useHomeContext();

  const [homeValue, setHomeValue] = React.useState<number>(0);
  const [homeContentValue, setHomeContentValue] = React.useState<number>(0);
  const [allRiskValue, setAllRiskValue] = React.useState<number>(0);
  const [totalValue, setTotalValue] = React.useState<number>(0);

  useMountEffect(() => {
    headerCtx.setState({
      currentStep: 1,
      totalOfSteps: 3,
      title: "Content Details",
    });
  });

  React.useEffect(() => {
    setAllRiskValue(homeCtx.getAllRiskItemsTotal());
    setHomeValue(homeCtx.replacementValue ?? 0);
    setHomeContentValue(homeCtx.contentsValue ?? 0);
    setTotalValue(homeCtx.getTotalValue());
  }, [
    homeCtx,
    homeCtx.replacementValue,
    homeCtx.contentsValue,
    homeCtx.contents,
    homeCtx.allRisk,
  ]);

  const onNextClick = () => {
    if (nextPath) {
      history.push(nextPath);
    }
  };

  return (
    <>
      <H3>Ok, let&apos;s review your home insurance.</H3>

      <Text className={styles.SubTitle}>
        Your insurance will cover the total value of your home and its contents.
      </Text>

      <div className={styles.Content}>
        {homeValue ? (
          <div className={classnames(styles.ContentRow, styles.UseMinHeight)}>
            <H5>Home value</H5>
            <H5>{formatCurrency(homeValue, false)}</H5>
            <TextSmall>{homeCtx.address}</TextSmall>
          </div>
        ) : undefined}

        {homeContentValue ? (
          <div className={classnames(styles.ContentRow, styles.UseMinHeight)}>
            <H5>Total contents value</H5>
            <H5>{formatCurrency(homeContentValue, false)}</H5>

            {homeCtx.allRisk ? (
              <>
                <TextSmall>Home contents value</TextSmall>

                <TextSmall>
                  {formatCurrency(homeContentValue - allRiskValue, false)}
                </TextSmall>

                <TextSmall>
                  {homeCtx.allRiskType === "worldwide"
                    ? "Worldwide"
                    : countryName}{" "}
                  all risk value
                </TextSmall>

                <TextSmall>{formatCurrency(allRiskValue, false)}</TextSmall>
              </>
            ) : undefined}
          </div>
        ) : undefined}

        <hr className={styles.Divider} />

        <div className={styles.ContentRow}>
          <H5>Total value</H5>
          <H3>{formatCurrency(totalValue, false, true, "BBD")}</H3>
        </div>
      </div>

      <div className={styles.ButtonWrapper}>
        <SubmitButton id="InsuranceReview-SubmitButton" onClick={onNextClick}>
          Next
        </SubmitButton>
      </div>
    </>
  );
}
